import { isEqual } from "lodash";
import { useRef } from "react";

export default function useCachedValue<Value>(value: Value) {
  const prevValue = useRef<Value>(value)
  
  if (!isEqual(prevValue.current, value))
    prevValue.current = value

  return prevValue.current
}
