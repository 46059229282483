import React, { FC, ReactElement, useContext } from "react";
import { calculateLayoutItemRect, filterItemChildren, isFlowLayoutItem } from "../../utils";
import { Item, ItemContext } from "../Item";
import { GroupableCore } from "../GroupableCore";
import { MoveableCore } from "../MoveableCore";
import { ItemLayout, PrivateLayoutItem } from "../../types";
import { ResizableCore } from "../ResizableCore";
import Grid from "../..";

export interface Props {
  children: Record<string, ReactElement>
  
  item: PrivateLayoutItem
  itemIndex: number

  ItemLayout: ItemLayout
}

export const ItemController: FC<Props> = ({
  children,
  
  item,
  itemIndex,

  ItemLayout,
}) => {
  const grid = useContext(Grid.Context)

  return grid && (
    <ItemContext.Provider value={{
      ...item,

      index: itemIndex,

      Layout: ItemLayout,
    }}>
      <ResizableCore active={item.resizable}>
        <MoveableCore active={item.moveable}>
          <GroupableCore active={item.groupable}>
            <Item
              indexes={item.indexes}

              visible={item.visible ?? true}

              drag={item.drag}

              {...calculateLayoutItemRect(item, grid)}

              z={isFlowLayoutItem(item) ? 2 : 1}

              ItemLayout={ItemLayout}
            >
              {filterItemChildren(children, item.indexes)}
            </Item>
          </GroupableCore>
        </MoveableCore>
      </ResizableCore>
    </ItemContext.Provider>
  )
}
