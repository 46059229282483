import { MutableRefObject, RefObject, useEffect, useRef } from "react";

type NodeRef = RefObject<HTMLElement> | MutableRefObject<HTMLElement>

export default function useClickAwayListener(
	ref: NodeRef,
	onClickAway: () => void,
) {
	const callback = useRef(onClickAway)

	useEffect(() => {
		callback.current = onClickAway
	}, [onClickAway])

	useEffect(() => {
		function clickAwayHandler({ target }) {
			if (target instanceof Node && ref.current)
				if (!ref.current.contains(target) || ref.current === target)
					callback.current()
		}

		document.addEventListener("mousedown", clickAwayHandler)

		return () => {
			document.removeEventListener("mousedown", clickAwayHandler)
		}
	}, [])
}