import { ReactElement, forwardRef, cloneElement, useMemo, useContext } from 'react';
import useContextedFunction, { ContextedFunction } from '../../hooks/useContextedFunction';
import useEvent from '../../hooks/useEvent';
import { mergeValues } from '../../utils/object';
import { createStackableContext } from '../../utils/react';
import Grid from '../Grid';
import { ItemContext } from '../Grid/components/Item';

export interface Props {
  children: ReactElement

  indexes: string[]
}

export const GridDragAreaContext = createStackableContext<Grid.DragAreaContextOptions | null>(null, mergeValues)

interface MouseHandlerContext {
  indexes: string[]

  item: Grid.LayoutItemContextOptions | null

  events: Grid.DragAreaContextOptions | null
}

const mouseDownHandler: ContextedFunction<MouseHandlerContext, [MouseEvent], void> = function (event) {
  event.preventDefault()
  event.stopPropagation()

  if (!this.events || !this.events.onDragStart)
    return

  const onDragStartEvents = Array.isArray(this.events.onDragStart) ? this.events.onDragStart : [this.events.onDragStart]

  onDragStartEvents.forEach((onDragStart) => {
    onDragStart(event.clientX, event.clientY, this.indexes)
  })
}

const mouseUpHandler: ContextedFunction<MouseHandlerContext, [MouseEvent], void> = function (event) {
  if (!this.item || !this.item.drag || !this.events || !this.events.onDragStop)
    return

  const onDragStopEvents = Array.isArray(this.events.onDragStop) ? this.events.onDragStop : [this.events.onDragStop]

  onDragStopEvents.forEach((onDragStop) => {
    onDragStop(event.clientX + this.item!.drag!.cursorOffsetX, event.clientY + this.item!.drag!.cursorOffsetY)
  })
}

export const GridDragArea = forwardRef<HTMLElement, Props>(({
  children,

  indexes,
}, ref) => {
  const item = useContext(ItemContext)
  const events = useContext(GridDragAreaContext)

  const mouseHandlerContext: MouseHandlerContext = {
    indexes,

    item,

    events,
  }

  const [contextedMouseDownHandler, setMouseDownHandlerContext] = useContextedFunction(mouseDownHandler, mouseHandlerContext)
  const [contextedMouseUpHandler, setMouseUpHandlerContext] = useContextedFunction(mouseUpHandler, mouseHandlerContext)

  setMouseDownHandlerContext(mouseHandlerContext)
  setMouseUpHandlerContext(mouseHandlerContext)

  useEvent(document, "mouseup", contextedMouseUpHandler)

  return useMemo(
    () => cloneElement(children, {
      ref,
      onMouseDown: contextedMouseDownHandler
    }),
    [children]
  )
})
