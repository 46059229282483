import { extend } from "lodash";
import React, {
	HTMLAttributes,
	PropsWithChildren,
	useLayoutEffect,
} from "react";
import useChanged from "../../../../hooks/useChanged";
import usePosition from "../../../../hooks/usePosition";
import useRender from "../../../../hooks/useRender";
import useSize from "../../../../hooks/useSize";
import { withController } from "../../../../utils/react";
import InternalController from "./Controller";
import useRefWithSetter from "../../../../hooks/useRefWithSetter";

const trackers: Record<string, InternalController> = {};

const Tracker = extend(
	withController<Tracker.Props, InternalController>(
		({
			children,

			id,

			controller,

			...props
		}) => {
			const render = useRender();

			const [rootRef, rootRefSetter] =
				useRefWithSetter<HTMLDivElement | null>(null);
			const root = rootRef.current;

			const size = useSize(root);
			const position = usePosition(root);

			useChanged(
				() => {
					controller.emit("size", size);
				},
				size,
				true,
			);

			useChanged(
				() => {
					controller.emit("move", position);
				},
				position,
				true,
			);

			controller.setContext({
				rootRef,
			});

			Tracker.set(id, controller);

			useLayoutEffect(() => {
				render(true);
			}, [render]);

			return (
				<div ref={rootRefSetter} {...props}>
					{children}
				</div>
			);
		},
		InternalController,
	),
	{
		Controller: InternalController,

		set(id: string, container: InternalController) {
			trackers[id] = container;
		},

		get(id: string): InternalController | undefined {
			return trackers[id];
		},
	},
);

declare namespace Tracker {
	type Controller = InternalController;

	interface Props extends PropsWithChildren, HTMLAttributes<HTMLDivElement> {
		id: string;
	}
}

export default Tracker;
