/* eslint-disable no-shadow */

import { extend, isNumber, isUndefined } from "lodash";
import React, { Dispatch, forwardRef, useMemo, useRef } from "react";
import { Table as TableBase, TableProps } from "rsuite";
import {
	Column,
	ColumnGroupProps,
	ColumnProps,
	HeaderCell,
	HeaderCellProps,
	RowDataType,
	RowKeyType,
} from "rsuite-table";
import { TableInstance } from "rsuite/esm/Table";
import InternalContext from "./Context";
import InternalCell from "./components/Cell";
import useRender from "../../hooks/useRender";
import FillHeight from "./components/FillHeight";
import useRefSize from "../../hooks/useRefSize";

const ROW_HEIGHT = 46;

const Table = extend(
	forwardRef<Table.Ref, Table.Props>(
		(
			{
				doubleClickDebounce = 200,
				disableDoubleClickDebounce = false,

				onRowClick,
				onRowDoubleClick,

				fillHeight = false,

				style,
				className,

				...props
			},
			ref,
		) => {
			const render = useRender();

			const { ref: fillHeightRef, size: fillHeightSize } = useRefSize();

			const rowHeightsRef = useRef<(number | undefined)[][]>([]);

			const content = useMemo(
				() => (
					<InternalContext.Provider
						value={{
							doubleClickDebounce,
							disableDoubleClickDebounce,
							rowHeights: rowHeightsRef.current,

							render,

							onRowClick,
							onRowDoubleClick,
						}}
					>
						<TableBase
							ref={ref}
							style={fillHeight ? undefined : style}
							className={fillHeight ? undefined : className}
							height={
								fillHeight ? fillHeightSize.height : undefined
							}
							rowHeight={(rowData) => {
								if (isUndefined(rowData)) return ROW_HEIGHT;

								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								const index = props.data!.indexOf(rowData);

								const rowHeights = rowHeightsRef.current[index];

								if (!rowHeights || !rowHeights.length)
									return ROW_HEIGHT;

								return rowHeights.reduce<number>(
									(accumulator, columnHeight) => {
										if (isNumber(columnHeight))
											return Math.max(
												accumulator,
												columnHeight,
											);

										return accumulator;
									},
									0,
								);
							}}
							{...props}
						/>
					</InternalContext.Provider>
				),
				[
					className,
					disableDoubleClickDebounce,
					doubleClickDebounce,
					fillHeight,
					fillHeightSize.height,
					onRowClick,
					onRowDoubleClick,
					props,
					ref,
					render,
					style,
				],
			);

			return fillHeight ? (
				<FillHeight
					ref={fillHeightRef}
					style={style}
					className={className}
				>
					{content}
				</FillHeight>
			) : (
				content
			);
		},
	),
	{
		Context: InternalContext,
		Column,
		ColumnGroup: TableBase.ColumnGroup,
		HeaderCell,
		Cell: InternalCell,
	},
);

declare namespace Table {
	type Ref = TableInstance<any, any>;

	interface Props extends Omit<TableProps<any, any>, "children"> {
		children?: React.ReactNode | React.ReactNode[];

		doubleClickDebounce?: number;
		disableDoubleClickDebounce?: boolean;

		onRowClick?: Dispatch<any>;
		onRowDoubleClick?: Dispatch<any>;
	}

	namespace Context {
		type Value = InternalContext.Value;
	}

	namespace Column {
		type Props = ColumnProps<RowDataType>;
	}

	namespace ColumnGroup {
		type Props = ColumnGroupProps;
	}

	namespace Cell {
		type PropsBase = InternalCell.PropsBase;
		type Props = InternalCell.Props;
	}

	namespace HeaderCell {
		type Props = HeaderCellProps<RowDataType, RowKeyType>;
	}
}

export default Table;
