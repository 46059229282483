import styled from "styled-components"

const ArrowContainer = styled.div`
    display: flex;

    align-items: center;

    svg {
        fill: #12152A;
    }
`

export default ArrowContainer
