import React, { useCallback } from 'react';
import styled from 'styled-components';
import theme from '../../styles/theme';
import { inputify } from '../../utils/react';
import Row from '../Row';
import { StyledLabel } from '../StyledLabel';

interface RootProps {
	active: boolean
	disabled: boolean
}

const Root = styled.div<RootProps>`
  box-sizing: border-box;

  width: 36px;
  height: 20px;

  padding: 2px;

  border-radius: 10px;

  background-color: ${({ active, disabled }) =>
		disabled
			? theme.colors.disabled_filled
			: active
				? theme.colors.accent
				: theme.colors.disabled_text
	};

  cursor: pointer;
  user-select: none;

  transition: background-color 0.2s ease;
`;

interface CircleProps {
	active: boolean
}

const Circle = styled.div<CircleProps>`
  width: 16px;
  height: 16px;

  border-radius: 50%;

  background-color: white;

  ${({ active }) => active ? "margin-left: 16px;" : ""}

  transition: margin-left 0.2s ease;
`;

export interface Props {
	displayDisabled?: boolean
	label?: string
	labelPosition?: 'left' | 'right'
}

export const ToggleButton = inputify<Props, boolean>(({
	value = false,
	disabled,
	displayDisabled = false,
	onChange,
	onEnter,
	onLeave,
	onFocus,
	onBlur,
	label,
	labelPosition = 'right',
}) => {
	const onClick = useCallback(() => disabled ? undefined : onChange(!value), [onChange, value, disabled]);

	const toggle = (
		<Root
			active={value}
			disabled={disabled || displayDisabled}
			onMouseEnter={onEnter}
			onMouseLeave={onLeave}
			onFocus={onFocus}
			onBlur={onBlur}
			onClick={onClick}
		>
			<Circle active={value} />
		</Root>
	)

	const styledLabel = label && (
		<StyledLabel active={value}>
			{label}
		</StyledLabel>
	)

	return (
		<Row
			style={{
				width: 'max-content',
			}}
			gaps="8px*"
			justify="center"
			align="center"
		>
			{labelPosition === 'right'
				? <>{toggle}{styledLabel}</>
				: <>{styledLabel}{toggle}</>
			}
		</Row>
	)
})
