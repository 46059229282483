/* eslint-disable func-names */
import _ from "lodash";

import { useMemo } from "react";
import AnyFunction from "../types/AnyFunction";
import { debounce } from "../utils/functions";
import useChanged from "./useChanged";

export default function useDebounce<Handler extends AnyFunction>(
	handler: Handler,
	time = 300,
) {
	const debounced = useMemo(() => debounce(handler, time), [handler, time]);

	useChanged((prevDebounced) => {
		prevDebounced?.cancel();
	}, debounced);

	return debounced;
}
