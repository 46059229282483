import { extend } from "lodash"
import React, { createContext, Dispatch, useContext } from "react"
import theme from "../../../../styles/theme"
import Icon from "../../../Icon"
import Root from "./components/Root"

const PageSelector = extend(
    ({
        type = "index",
        index,
    }: PageSelector.Props) => {
        const context = useContext(PageSelector.Context)
    
        return (
            <Root
                active={context?.value === index}
                disabled={
                    type === "custom" ||
                    (type === "prev" && (context?.value === 0  || (context?.pages ?? 0) === 0)) ||
                    (type === "next" && (context?.value === (context?.pages ?? 0) - 1 || (context?.pages ?? 0) === 0))
                }

                onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()

                    if (type === "prev")
                        context?.onChange((context?.value ?? 0) - 1)
                    else if (type === "next")
                        context?.onChange((context?.value ?? 0) + 1)
                    else if (type === "index")
                        context?.onChange(index ?? 0)
                }}
            >
                {
                    type === "prev" ? <Icon id="left-arrow" size={[18, 18]} colors={[theme.colors.secondary]} /> :
                    type === "next" ? <Icon id="right-arrow" size={[18, 18]} colors={[theme.colors.secondary]} /> :
                    type === "custom" ? "..." :
                    type === "index" ? (index ?? 0) + 1 :
                    null
                }
            </Root>
        )
    },
    {
        Context: createContext<PageSelector.Context | null>(null),
    }
)

declare namespace PageSelector {
    export interface Props {
        type?: "prev" | "next" | "custom" | "index"
        index?: number
    }

    export interface Context {
        value: number
        pages: number

        onChange: Dispatch<number>
    }
}

export default PageSelector
