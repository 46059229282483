/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-restricted-syntax */
/* eslint-disable func-names */

import React, { Dispatch, useEffect, useMemo, useRef, useState } from "react";
import Leaflet, { DrawMap, FeatureGroup as LeafletFeatureGroup } from "leaflet";
import "leaflet-draw";
import { FeatureGroup, useMapEvent } from "react-leaflet";
import { useLeafletContext } from "@react-leaflet/core";
import { CallbackContext, PolygonList } from "./type";
import { coordinatesToLatLng } from "./utils";
import InternalController from "./Controller";
import onCreated from "./functions/onCreated";
import onDrawVertex from "./functions/onDrawVertex";
import onEditVertex from "./functions/onEditVertex";
import useInternal from "../../../../hooks/useInternal";
import useRender from "../../../../hooks/useRender";
import { withController } from "../../../../utils/react";
import useContextedFunction from "../../../../hooks/useContextedFunction";
import Key from "../../../../types/Key";

const PolygonEditor = withController<PolygonEditor.Props, InternalController>(
	({
		controller,

		value = [],
		selected,
		editing,

		onChange,
		onChangeSelected,
		onChangeEditing,
	}) => {
		const leaflet = useLeafletContext();

		const [internalPolygons, setInternalPolygons] =
			useInternal<PolygonList>(value, { deep: true });
		const [internalSelected, setInternalSelected] = useInternal<
			Key | undefined
		>(selected);
		const [internalEditing, setInternalEditing] = useInternal<boolean>(
			editing ?? false,
		);
		const [nextFocus, setNextFocus] = useState<Key[]>([]);
		const editingObjects = useRef<Leaflet.Draw.Polygon[]>([]);
		const layerIds = useRef<number[]>([]);

		const featureGroupRef = useRef<LeafletFeatureGroup | null>(null);
		const featureGroup = featureGroupRef.current;

		const initializingRef = useRef<boolean>(false);

		const render = useRender();

		controller.setContext({
			selected: internalSelected,

			setInternalSelected,
			setInternalEditing,
			setNextFocus,

			onChangeSelected,
			onChangeEditing,
		});

		useMapEvent("dblclick", (event) => {
			if (event.originalEvent.target !== event.target._container) return;

			controller.select(undefined);
		});

		useEffect(() => {
			onChange?.(internalPolygons);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [internalPolygons]);

		useEffect(() => {
			if (!featureGroup) return;

			initializingRef.current = true;

			for (const editingObject of editingObjects.current)
				if (editingObject.enabled()) editingObject.removeHooks?.();

			leaflet.map.eachLayer((layer) => {
				if (layerIds.current.includes(Leaflet.stamp(layer)))
					leaflet.map.removeLayer(layer);
			});

			featureGroup.eachLayer((layer) => {
				if (layerIds.current.includes(Leaflet.stamp(layer)))
					featureGroup.removeLayer(layer);
			});

			editingObjects.current = [];
			layerIds.current = [];

			for (let index = 0; index < internalPolygons.length; index++) {
				const polygon = internalPolygons[index];

				const editable =
					typeof polygon.editable === "undefined" || polygon.editable;

				if (
					editable &&
					!polygon.disabled &&
					polygon.creating &&
					internalSelected === polygon.id &&
					internalEditing
				) {
					const object = new Leaflet.Draw.Polygon(
						leaflet.map as DrawMap,
						{
							allowIntersection: false,

							shapeOptions: {
								color: "red",
							},
						},
					);

					object.enable();

					for (const polygonVertex of polygon.vertices)
						object.addVertex(coordinatesToLatLng(polygonVertex));

					editingObjects.current.push(object);
				} else {
					const layer = new Leaflet.Polygon(polygon.vertices);

					if (polygon.color) layer.setStyle({ color: polygon.color });

					if (polygon.name)
						layer.bindTooltip(polygon.name, {
							sticky: true,
						});

					featureGroup.addLayer(layer);

					if (editable && !polygon.disabled)
						layer.on("dblclick", (event) => {
							event.originalEvent.stopPropagation();
							event.originalEvent.preventDefault();

							controller.edit(polygon.id);
						});

					if (internalSelected === polygon.id && internalEditing) {
						layer.setStyle({
							color: "red",
						});
						(layer as any).editing.enable();
					}

					if (typeof polygon.editable === "boolean")
						layer
							.getElement()
							?.classList.add(
								`${
									polygon.editable ? "" : "not-"
								}editable-polygon`,
							);

					if (polygon.disabled)
						layer.getElement()?.classList.add("disabled-polygon");

					layerIds.current.push(Leaflet.stamp(layer));
				}
			}

			initializingRef.current = false;
		}, [
			featureGroup,
			internalPolygons,
			internalSelected,
			internalEditing,
			leaflet.map,
			controller,
		]);

		const mapSize = leaflet.map.getSize();

		useEffect(() => {
			if (
				!leaflet.map ||
				mapSize.x === 0 ||
				mapSize.y === 0 ||
				!nextFocus.length
			)
				return;

			const selectedPolygons = internalPolygons.filter((polygon) =>
				nextFocus.includes(polygon.id),
			);

			if (!selectedPolygons.length) return;

			const bounds = new Leaflet.LatLngBounds(
				selectedPolygons[0].vertices,
			);

			for (const selectedPolygon of selectedPolygons) {
				bounds.extend(selectedPolygon.vertices);
			}

			leaflet.map.fitBounds(bounds, { padding: [40, 40] });
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [leaflet.map, mapSize.x, mapSize.y, nextFocus]);

		useMapEvent("resize", () => {
			render();
		});

		const callbackContext: CallbackContext = {
			initializingRef,
			setInternalPolygons,
			selected: internalSelected!,
		};

		const [contextedOnCreated, setOnCreatedContext] = useContextedFunction(
			onCreated,
			callbackContext,
		);
		const [contextedOnDrawVertex, setOnDrawVertexContext] =
			useContextedFunction(onDrawVertex, callbackContext);
		const [contextedOnEditVertex, setOnEditVertexContext] =
			useContextedFunction(onEditVertex, callbackContext);

		setOnCreatedContext(callbackContext);
		setOnDrawVertexContext(callbackContext);
		setOnEditVertexContext(callbackContext);

		useEffect(() => {
			leaflet.map.on(Leaflet.Draw.Event.CREATED, contextedOnCreated);
			leaflet.map.on(
				Leaflet.Draw.Event.DRAWVERTEX,
				contextedOnDrawVertex,
			);
			leaflet.map.on(
				Leaflet.Draw.Event.EDITVERTEX,
				contextedOnEditVertex,
			);

			return () => {
				leaflet.map.off(Leaflet.Draw.Event.CREATED, contextedOnCreated);
				leaflet.map.off(
					Leaflet.Draw.Event.DRAWVERTEX,
					contextedOnDrawVertex,
				);
				leaflet.map.off(
					Leaflet.Draw.Event.EDITVERTEX,
					contextedOnEditVertex,
				);
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [leaflet]);

		const result = useMemo(
			() => (
				<FeatureGroup
					ref={(featureGroup) => {
						featureGroupRef.current = featureGroup;

						render();
					}}
				/>
			),
			[render],
		);

		return result;
	},
	InternalController,
);

declare namespace PolygonEditor {
	type Value = PolygonList;

	interface Props {
		value?: PolygonList;
		selected?: Key;
		editing?: boolean;

		onChange?: Dispatch<PolygonList>;
		onChangeSelected?: Dispatch<Key | undefined>;
		onChangeEditing?: Dispatch<boolean>;
	}

	type Controller = InternalController;

	namespace Controller {
		type Context = InternalController.Context;
	}
}

export default PolygonEditor;
