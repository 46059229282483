import React, { DOMAttributes, forwardRef, PropsWithChildren } from "react";
import { styled } from "@storybook/theming";

import { headers } from "../../styles/typographyStyle";

export interface TextProps extends PropsWithChildren {
	variant: any;
}

const HeaderBase = styled.p<{ variant: keyof typeof headers }>(
	{ marginBottom: 20, fontFamily: "Lato" },
	(props) => headers[props.variant],
);

export interface Props
	extends PropsWithChildren,
		TextProps,
		DOMAttributes<HTMLElement> {}

export const Header = forwardRef<HTMLElement, Props>(
	({ children, variant, ...props }, ref) => (
		<HeaderBase {...props} variant={variant}>
			<>{children}</>
		</HeaderBase>
	),
);

HeaderBase.defaultProps = {
	variant: "h1",
};

export default Text;
