import React, { FC, useMemo } from "react";
import useInternal from "../../hooks/useInternal";
import { ClickAwayListener } from "../ClickAwayListener";
import Float from "../Float";

declare namespace Popover {
	export interface Props extends Float.Props {
		open?: boolean;

		onClose?: () => void;
	}
}

const Popover: FC<Popover.Props> = ({
	children,

	open,
	onClose,

	...props
}) => {
	const [internalOpen, setInternalOpen] = useInternal(open ?? false, {
		when: () => typeof open === "undefined",
	});

	const content = useMemo(
		() => (
			<ClickAwayListener
				onClickAway={() => {
					if (!internalOpen) return;

					setInternalOpen(false);
					onClose?.();
				}}
			>
				{children}
			</ClickAwayListener>
		),
		[children, internalOpen, setInternalOpen, onClose],
	);

	return <>{open && <Float {...props}>{content}</Float>}</>;
};

export default Popover;
