import React, { FC, useContext, useMemo } from "react";
import MultiSelect from "../..";
import Select from "../../../Select";

const Selected: FC<Selected.Props> = (props) => {
	const context = useContext(MultiSelect.Context);

	const label = useMemo(
		() =>
			context?.all && context?.areAllOptionsSelected
				? "Все"
				: context?.value
						?.map(
							(selected) =>
								context.options?.find(
									(option) => option.key === selected,
								)?.label,
						)
						.join(", "),
		[context?.options, context?.value],
	);

	return (
		<Select.Text {...props} selected={(context?.value?.length ?? 0) > 0}>
			{label || context?.placeholder}
		</Select.Text>
	);
};

declare namespace Selected {
	type Props = Select.SelectedComponentBase.Props;
}

export default Selected;
