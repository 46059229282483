import React, { forwardRef } from "react";
import Color from "color";
import { useSnackbar, SnackbarContent, SnackbarKey, SnackbarMessage } from "notistack";
import theme from "../../styles/theme";
import Row from "../Row";
import { Root } from "./components/Root";
import { Indicator } from "./components/Indicator";
import { Icon } from "./components/Icon";
import { Message } from "./components/Message";
import { Close } from "./components/Close";

export type Type = "success" | "failure" | "warning" | "info"

export interface Props {
  id: SnackbarKey
  type: Type
  message?: SnackbarMessage
}

const styles = {
  success: {
    color: theme.colors.success,
    backgroundColor: Color(theme.colors.success).mix(Color("white"), 0.9).hex(),
    alphaColor: Color(theme.colors.success).alpha(0.1).hexa(),
  },

  failure: {
    color: theme.colors.failure,
    backgroundColor: Color(theme.colors.failure).mix(Color("white"), 0.9).hex(),
    alphaColor: Color(theme.colors.failure).alpha(0.1).hexa(),
  },

  warning: {
    color: theme.colors.warning,
    backgroundColor: Color(theme.colors.warning).mix(Color("white"), 0.9).hex(),
    alphaColor: Color(theme.colors.warning).alpha(0.1).hexa(),
  },

  info: {
    color: theme.colors.info,
    backgroundColor: Color(theme.colors.info).mix(Color("white"), 0.9).hex(),
    alphaColor: Color(theme.colors.info).alpha(0.1).hexa(),
  },
}

export const Snackbar = forwardRef<HTMLDivElement, Props>(({
  id,
  type,
  message = "",
}, ref) => {
  const style = styles[type];

  const { closeSnackbar } = useSnackbar();

  return (
    <SnackbarContent ref={ref}>
      <Root color={style.backgroundColor}>
        <Row
          align="center"
          sizes="auto* 1fr auto"
          gaps="16px*2 24px"
        >
          <Indicator color={style.color} />
          <Icon type={type} color={style.color} />
          <Message>
            {message}
          </Message>
          <Close onClick={() => closeSnackbar(id)} />
        </Row>
      </Root>
    </SnackbarContent>
  );
});
