import styled from "styled-components";

export interface Props {
  color: string
}

export const Root = styled.div<Props>`
  min-width: min-content;
  min-height: 86px;

  max-width: 510px;

  padding-right: 18px;

  border-radius: 5px;

  background-color: ${({ color }) => color};
  
  box-shadow: 0px 4px 14px rgba(33, 51, 63, 0.15);

  & > * {
    min-height: inherit;
  }
`;