import { useCallback, useEffect, useMemo, useRef } from "react";

interface Timer {
	timeout?: NodeJS.Timeout;
	tick: () => void;
	callback: () => void;
}

export default function useHolder(
	delayMS: number,
	intervalMS: number,
	callback: () => void,
) {
	const timer = useRef<Timer>({
		tick() {
			timer.current.callback();

			clearTimeout(timer.current.timeout);
			timer.current.timeout = setTimeout(timer.current.tick, intervalMS);
		},
		callback,
	});

	useEffect(() => {
		timer.current.callback = callback;
	}, [callback]);

	const hold = useCallback(() => {
		callback();

		clearTimeout(timer.current.timeout);
		timer.current.timeout = setTimeout(timer.current.tick, delayMS);
	}, [callback, delayMS]);

	const release = useCallback(() => {
		clearTimeout(timer.current.timeout);
	}, []);

	const holder = useMemo(
		() => ({
			events: {
				onMouseDown: (event: React.MouseEvent) => {
					event.preventDefault();
					event.stopPropagation();

					hold();
				},
				onMouseUp: (event: React.MouseEvent) => {
					event.preventDefault();
					event.stopPropagation();

					release();
				},
				onMouseOut: (event: React.MouseEvent) => {
					event.preventDefault();
					event.stopPropagation();

					release();
				},

				onTouchStart: hold,
				onTouchEnd: release,
				onTouchCancel: release,
			},
		}),
		[hold, release],
	);

	// Release on unmount
	useEffect(
		() => () => {
			clearTimeout(timer.current.timeout);
		},
		[],
	);

	return holder;
}
