import { PropsWithChildren } from "react";
import styled from "styled-components";
import theme from "../../../../styles/theme";

export interface Props extends PropsWithChildren { }

export const Message = styled.div<Props>`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: ${theme.colors.primary};
`;
