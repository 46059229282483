import styled from "styled-components";
import { DataType } from "csstype";
import SizeLiteral from "../../../../types/SizeLiteral";

const Root = styled.svg<Root.Props>`
	${({ colors }) =>
		colors
			.map((color, index) => `--icon-color-${index + 1}: ${color};`)
			.join("\n")}
`;

namespace Root {
	export interface Props extends SizeLiteral {
		colors: DataType.Color[];
	}
}

export default Root;
