import styled from "styled-components";

const Root = styled.div.attrs<Root.Props, Root.Props>(({ visible, x, y }) => ({
	style: {
		left: `${x}px`,
		top: `${y}px`,
	},

	visible,
	x,
	y,
}))`
	position: absolute;

	visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
	pointer-events: ${({ visible }) => (visible ? "all" : "none")};
`;

declare namespace Root {
	interface Props {
		visible: boolean;
		x: number;
		y: number;
	}
}

export default Root;
