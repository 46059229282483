import React, { FunctionComponent } from 'react'
import theme from "../../styles/theme";
import styled from 'styled-components';
import { Button } from '../Button';

interface StyledMenuItem {
    active: boolean
}

const MenuItem = styled(Button) <StyledMenuItem>`
	width: 210px;
	height: 40px;
	justify-content: flex-start;
	padding-left: 7px;
	color: ${props => props.active
        ? theme.colors.primary
        : '#21333FB2'
    };
	background-color: ${props => props.active
        ? theme.colors.button_secondary_hover
        : 'white'
    };

	font-family: ${theme.font.def};
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;

    &:hover {
        color: ${theme.colors.accent};
        background-color: #21333F1A;
    }
`

export type MenuProps = {
    users: {
        id: string;
        name?: string;
        avatarUrl?: string | any;
        active: boolean
    }[];
}

export const Menu: FunctionComponent<MenuProps> = ({
    users = [
        { id: 'loading', avatarUrl: null, name: 'loading', active: false },
        { id: 'loading2', avatarUrl: null, name: 'loading', active: false },
        { id: 'loading3', avatarUrl: null, name: 'loading', active: false },
    ],
}) => {

    return (
        <div>
            {users.map(({ id, name, avatarUrl, active }) => (
                <div key={id} >
                    <MenuItem
                        active={active}
                        text={name}
                        icon={avatarUrl}
                        onClick={() => { }}
                    />
                </div>
            ))}

        </div>
    )
}