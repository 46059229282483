import styled from "styled-components"
import Select from "../.."
import { InputBorders, classes as inputBordersClasses } from "../../../InputBorders"

const OverlayBorders = styled(InputBorders)<OverlayBorders.Props>`
    position: absolute;

    left: 0;

    ${
        ({ direction }) =>
            direction === "down"
                ? `
                    top: 100%;
                `
                : `
                    top: 0;
                    transform: translateY(-100%);
                `
    }

    width: 100%;

    .${inputBordersClasses.borders} {
        ${
            ({ direction }) =>
                direction === "down"
                    ? `
                        border-top: none;
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                    `
                    : `
                        border-bottom: none;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    `
        }
    }
`

declare namespace OverlayBorders {
    export interface Props {
        direction: Select.Direction
    }
}

export default OverlayBorders
