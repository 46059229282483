import React, { useMemo } from "react";
import { inputify } from "../../utils/react";
import { InputBorder } from "../InputBorder";
import TimeInput from "../TimeInput";
import DatePicker from "../DatePicker";
import { InputGroup } from "../InputGroup";

declare namespace DateAndTimePicker {
	export type Value = Date | null | undefined;

	export interface Props
		extends Omit<DatePicker.Props & TimeInput.Props, "autoFocus"> {
		autoFocus?: boolean | "date" | "time";
	}
}

const DateAndTimePicker = inputify<
	DateAndTimePicker.Props,
	DateAndTimePicker.Value
>(
	({
		value,
		onChange,

		autoFocus,

		minDate,
		maxDate,

		locale,

		step,
		ref,
	}) => {
		const autoDateFocus = useMemo(
			() => autoFocus === "date" || autoFocus === true,
			[autoFocus],
		);
		const autoTimeFocus = useMemo(() => autoFocus === "time", [autoFocus]);

		return (
			<InputGroup>
				<DatePicker
					ref={ref}
					autoFocus={autoDateFocus}
					value={value}
					onChange={onChange}
					minDate={minDate}
					maxDate={maxDate}
					locale={locale}
				/>
				<InputBorder />
				<TimeInput
					autoFocus={autoTimeFocus}
					value={value}
					onChange={onChange}
					step={step}
				/>
			</InputGroup>
		);
	},
);

export default DateAndTimePicker;
