import React, { ComponentType, useContext } from "react"
import styled from "styled-components"
import { mergeValues } from "../../../../../../utils/object"
import { createStackableContext } from "../../../../../../utils/react"
import Overlayer from "../../../../../Overlayer"
import { ItemLayout, PrivateLayoutItem } from "../../../../types"
import useContextedFunction, { ContextedFunction } from '../../../../../../hooks/useContextedFunction';
import useEvent from "../../../../../../hooks/useEvent"
import { ItemContext } from "../../../Item"
import SingleOrMany from "../../../../../../types/SignleOrMany"
import { emit } from "../../../../../../utils/events"

interface ResizeAreaProps {
  thickness: number
  drowning: number
}

const TopLeftArea = styled.div<ResizeAreaProps>`
  position: absolute;

  top: ${({ thickness, drowning }) => -thickness + drowning}px;
  left: ${({ thickness, drowning }) => -thickness + drowning}px;

  width: ${({ thickness }) => thickness}px;
  height: ${({ thickness }) => thickness}px;

  cursor: nwse-resize;
`

const TopArea = styled.div<ResizeAreaProps>`
  position: absolute;

  top: ${({ thickness, drowning }) => -thickness + drowning}px;
  left: ${({ drowning }) => drowning}px;

  width: calc(100% - ${({ drowning }) => 2 * drowning}px);
  height: ${({ thickness }) => thickness}px;

  cursor: ns-resize;
`

const TopRightArea = styled.div<ResizeAreaProps>`
  position: absolute;

  top: ${({ thickness, drowning }) => -thickness + drowning}px;
  left: calc(100% - ${({ drowning }) => drowning}px);

  width: ${({ thickness }) => thickness}px;
  height: ${({ thickness }) => thickness}px;

  cursor: nesw-resize;
`

const RightArea = styled.div<ResizeAreaProps>`
  position: absolute;

  top: ${({ drowning }) => drowning}px;
  left: calc(100% - ${({ drowning }) => drowning}px);

  width: ${({ thickness }) => thickness}px;
  height: calc(100% - ${({ drowning }) => 2 * drowning}px);

  cursor: ew-resize;
`

const BottomRightArea = styled.div<ResizeAreaProps>`
  position: absolute;

  top: calc(100% - ${({ drowning }) => drowning}px);
  left: calc(100% - ${({ drowning }) => drowning}px);

  width: ${({ thickness }) => thickness}px;
  height: ${({ thickness }) => thickness}px;

  cursor: nwse-resize;
`

const BottomArea = styled.div<ResizeAreaProps>`
  position: absolute;

  top: calc(100% - ${({ drowning }) => drowning}px);
  left: ${({ drowning }) => drowning}px;

  width: calc(100% - ${({ drowning }) => 2 * drowning}px);
  height: ${({ thickness }) => thickness}px;

  cursor: ns-resize;
`

const BottomLeftArea = styled.div<ResizeAreaProps>`
  position: absolute;

  top: calc(100% - ${({ drowning }) => drowning}px);
  left: ${({ thickness, drowning }) => -thickness + drowning}px;

  width: ${({ thickness }) => thickness}px;
  height: ${({ thickness }) => thickness}px;

  cursor: nesw-resize;
`

const LeftArea = styled.div<ResizeAreaProps>`
  position: absolute;

  top: ${({ drowning }) => drowning}px;
  left: ${({ thickness, drowning }) => -thickness + drowning}px;

  width: ${({ thickness }) => thickness}px;
  height: calc(100% - ${({ drowning }) => 2 * drowning}px);

  cursor: ew-resize;
`

interface ResizeSide {
  Area: ComponentType<ResizeAreaProps & JSX.IntrinsicElements["div"]>
}

const sides: Record<ResizeType, ResizeSide> = {
  "top-left": {
    Area: TopLeftArea,
  },

  "top": {
    Area: TopArea,
  },

  "top-right": {
    Area: TopRightArea,
  },

  "right": {
    Area: RightArea,
  },

  "bottom-right": {
    Area: BottomRightArea,
  },

  "bottom": {
    Area: BottomArea,
  },

  "bottom-left": {
    Area: BottomLeftArea,
  },

  "left": {
    Area: LeftArea,
  }
}

export type ResizeType = "top-left" | "top" | "top-right" | "right" | "bottom-right" | "bottom" | "bottom-left" | "left"

export type ResizeStartEvent = (cursorX: number, cursorY: number, type: ResizeType) => void
export type ResizeEvent = (cursorX: number, cursorY: number) => void
export type ResizeStopEvent = (cursorX: number, cursorY: number) => void

export interface ResizeItemLayoutContextOptions {
  onResizeStart?: SingleOrMany<ResizeStartEvent>
  onResizeStop?: SingleOrMany<ResizeStopEvent>
}

export const ResizeItemLayoutContext = createStackableContext<ResizeItemLayoutContextOptions | null>(null, mergeValues)

interface MouseDownHandlerContext {
  type: ResizeType

  onResizeStart?: ResizeStartEvent | ResizeStartEvent[]
}

const mouseDownHandler: ContextedFunction<MouseDownHandlerContext | null, [React.MouseEvent<HTMLElement>], void> = function (event) {
  if (!this)
    return

  event.stopPropagation()
  event.preventDefault()

  emit(this.onResizeStart, event.clientX, event.clientY, this!.type)
}

interface MouseUpHandlerContext {
  item: PrivateLayoutItem

  onResizeStop?: ResizeStopEvent | ResizeStopEvent[]
}

const mouseUpHandler: ContextedFunction<MouseUpHandlerContext | null, [MouseEvent], void> = function (event) {
  if (!this || !this.item || !this.item.resize)
    return

  emit(this.onResizeStop, event.clientX, event.clientY)
}

export const ResizeItemLayout: ItemLayout = ({ children }) => {
  const item = useContext(ItemContext)
  const events = useContext(ResizeItemLayoutContext)

  const thickness = 10
  const drowning = 5

  const [contextedMouseUpHandler, setMouseUpHandlerContext] = useContextedFunction(mouseUpHandler, null as MouseUpHandlerContext | null)

  setMouseUpHandlerContext({
    item,

    onResizeStop: events?.onResizeStop,
  } as MouseUpHandlerContext)

  useEvent(document, "mouseup", contextedMouseUpHandler)

  return (
    <Overlayer
      overlay={
        Object.entries(sides).map(([type, data]) => {
          const [contextedMouseDownHandler, setMouseDownHandlerContext] =
            useContextedFunction(mouseDownHandler, null as MouseDownHandlerContext | null)

          setMouseDownHandlerContext({
            type: type as ResizeType,

            onResizeStart: events?.onResizeStart,
          })

          return (
            <data.Area
              thickness={thickness}
              drowning={drowning}

              onMouseDown={contextedMouseDownHandler}
            />
          )
        })
      }
    >
      {React.isValidElement(children) ? children : null}
    </Overlayer>
  )
}
