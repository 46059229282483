import { MutableRefObject } from "react"
import { ControllerBase } from "../../utils/react"

export interface Context {
  manualFocusRef: MutableRefObject<boolean>
  onFocus: () => void
}

export default class Controller extends ControllerBase<Context> {
  focus() {
    if (!this.context)
      return

    this.context.manualFocusRef.current = true
    this.context.onFocus()
  }
}
