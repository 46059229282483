import styled from "styled-components";

const Root = styled.div`
	width: 100%;
	height: 100%;

	overflow: hidden;
`;

export default Root;
