import styled from "styled-components";
import theme from "../../styles/theme";

const HR = styled.div`
	width: 100%;
	height: 1px;
	background-color: ${theme.colors.primary};
	opacity: 0.15;
`;

export default HR;
