import { Dispatch, Key, SetStateAction } from "react";
import { ControllerBase } from "../../../../utils/react";

class Controller extends ControllerBase<Controller.Context> {
	public select(id?: Key) {
		if (!this.context) return;

		const { context } = this;
		const { setInternalSelected, onChangeSelected } = context;

		setInternalSelected(id);
		onChangeSelected?.(id);

		context.selected = id;

		if (typeof id === "undefined") this.edit(false);
	}

	public edit(enabled: boolean): void;

	public edit(id?: Key): void;

	public edit(value?: Key | boolean) {
		if (!this.context) return;

		const { context } = this;
		const { setInternalEditing, onChangeEditing } = context;

		if (typeof value === "boolean") {
			setInternalEditing(value);
			onChangeEditing?.(value);
		} else {
			this.select(value);
			this.edit(true);
		}
	}

	public focus(ids: Key | Key[]) {
		if (!Array.isArray(ids)) ids = [ids];

		this.context?.setNextFocus(ids);
	}
}

declare namespace Controller {
	interface Context {
		selected?: Key;

		setInternalSelected: Dispatch<SetStateAction<Key | undefined>>;
		setInternalEditing: Dispatch<SetStateAction<boolean>>;
		setNextFocus: Dispatch<Key[]>;

		onChangeSelected?: Dispatch<Key | undefined>;
		onChangeEditing?: Dispatch<boolean>;
	}
}

export default Controller;
