import React from "react";

export default (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1908_12491)">
      <path d="M12.9887 11.9931L16.5233 8.45853C16.6424 8.3194 16.7047 8.14043 16.6976 7.9574C16.6905 7.77436 16.6146 7.60073 16.4851 7.47121C16.3556 7.34168 16.182 7.26581 15.9989 7.25874C15.8159 7.25167 15.6369 7.31393 15.4978 7.43307L11.9633 10.9313L8.40689 7.37489C8.26776 7.25574 8.0888 7.19348 7.90576 7.20055C7.72273 7.20762 7.5491 7.2835 7.41957 7.41303C7.29005 7.54255 7.21417 7.71618 7.2071 7.89921C7.20003 8.08225 7.26229 8.26122 7.38144 8.40034L10.9305 11.9931L7.45416 15.4258C7.37803 15.491 7.3162 15.5712 7.27255 15.6615C7.22889 15.7517 7.20436 15.85 7.20049 15.9501C7.19662 16.0503 7.2135 16.1502 7.25006 16.2435C7.28663 16.3368 7.34209 16.4216 7.41296 16.4925C7.48384 16.5633 7.5686 16.6188 7.66193 16.6554C7.75526 16.6919 7.85514 16.7088 7.95529 16.7049C8.05545 16.7011 8.15373 16.6765 8.24396 16.6329C8.33419 16.5892 8.41442 16.5274 8.47962 16.4513L11.9487 12.9822L15.396 16.4294C15.5351 16.5486 15.7141 16.6108 15.8971 16.6038C16.0801 16.5967 16.2538 16.5208 16.3833 16.3913C16.5128 16.2618 16.5887 16.0881 16.5958 15.9051C16.6028 15.7221 16.5406 15.5431 16.4214 15.404L12.9887 11.9931Z" fill="#FF4B55" />
      <path d="M12.0001 23.2C9.7849 23.2 7.6195 22.5431 5.77767 21.3124C3.93584 20.0818 2.5003 18.3326 1.6526 16.286C0.804902 14.2395 0.583104 11.9876 1.01526 9.81498C1.44741 7.64239 2.51411 5.64674 4.08046 4.0804C5.6468 2.51405 7.64245 1.44735 9.81504 1.0152C11.9876 0.583043 14.2396 0.804841 16.2861 1.65254C18.3326 2.50024 20.0818 3.93577 21.3125 5.7776C22.5432 7.61943 23.2 9.78484 23.2 12C23.2 14.9704 22.0201 17.8192 19.9196 19.9196C17.8192 22.02 14.9705 23.2 12.0001 23.2ZM12.0001 2.19999C10.0618 2.19999 8.16707 2.77475 6.55546 3.85159C4.94386 4.92843 3.68777 6.45898 2.94603 8.24969C2.2043 10.0404 2.01022 12.0109 2.38836 13.9119C2.76649 15.8129 3.69985 17.5591 5.07041 18.9296C6.44096 20.3002 8.18715 21.2335 10.0882 21.6117C11.9892 21.9898 13.9596 21.7957 15.7503 21.054C17.5411 20.3123 19.0716 19.0562 20.1485 17.4446C21.2253 15.833 21.8 13.9382 21.8 12C21.8 9.40087 20.7676 6.9082 18.9297 5.07034C17.0918 3.23249 14.5992 2.19999 12.0001 2.19999Z" fill="#FF4B55" />
    </g>
    <defs>
      <clipPath id="clip0_1908_12491">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
