import styled from "styled-components";
import theme from "../../../../../../styles/theme";
import Row from "../../../../../Row";

const Root = styled(Row)`
	padding: 6px 8px 0;

	background-color: ${theme.colors.button_secondary};

	overflow: hidden;
`;

export default Root;
