import React, { FC, forwardRef, MutableRefObject, RefObject, useEffect, useRef } from "react";
import Overlayer from "../Overlayer";

type NodeRef = RefObject<HTMLElement> | MutableRefObject<HTMLElement>
interface Listener {
	nodeRef: NodeRef
	handler?: () => void
}

const stack: Listener[] = []

document.addEventListener("mousedown", ({ target }) => {
	const topListener = stack.at(-1)

	if (!topListener)
		return

	if (target instanceof Node && topListener.nodeRef.current)
		if (!topListener.nodeRef.current.contains(target) || topListener.nodeRef.current === target)
			topListener.handler?.()
})

export interface Props extends Overlayer.Props {
	overlay?: React.ReactNode | React.ReactNode[]
	onClickAway?: () => void
}

export const OverlayerWithClickAwayListener: FC<Props> = forwardRef<HTMLDivElement, Props>(({
	overlay,
	onClickAway,
	children,
	...props
}, ref) => {
	const overlayRef: MutableRefObject<HTMLDivElement | null> = useRef(null)
	const listener = useRef<Listener>({
		nodeRef: overlayRef,
		handler: onClickAway,
	})

	useEffect(() => {
		stack.push(listener.current)

		return () => {
			const index = stack.findIndex(l => l === listener.current)

			if (index !== -1)
				stack.splice(index, 1)
		}
	}, [])

	useEffect(() => {
		listener.current.handler = onClickAway
	}, [onClickAway])

	return (
		<Overlayer
			{...props}
			ref={ref}
			overlay={overlay && (
				<div ref={overlayRef}>
					{overlay}
				</div>
			)}
		>
			{children}
		</Overlayer>
	)
})