import { findLast } from "lodash";
import React, { useContext } from "react";
import { FC, ReactElement } from "react";
import Grid from "../..";
import { GridDragAreaContext } from "../../../GridDragArea";
import { findItemById, groupItems } from "../../utils";
import { GroupAreaHoverCore } from "../GroupAreaHoverCore";
import { ItemContext } from "../Item";

export interface GroupableCoreGridContextState {

}

export interface Props {
  children?: ReactElement

  active: boolean
}

export const GroupableCore: FC<Props> = ({
  children,

  active,
}) => {
  const grid = useContext(Grid.Context)
  const item = useContext(ItemContext)

  return grid && item && active
    ? (
      <GridDragAreaContext.Provider value={{
        onDragStop: () => {
          const lastNotDraggingHoveredItemId = findLast(grid.state.hoveredItems, (itemId) => !findItemById(grid.layout, itemId)?.drag)

          if (lastNotDraggingHoveredItemId)
            grid.setLayout(groupItems(grid.layout, grid.layoutItems, lastNotDraggingHoveredItemId, item.id))
        }
      }}>
        <GroupAreaHoverCore>
          {children}
        </GroupAreaHoverCore>
      </GridDragAreaContext.Provider>
    )
    : children ?? null
}
