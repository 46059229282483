import React, { useMemo, useState } from "react"
import styled from "styled-components"
import { inputify } from "../../utils/react"
import _ from "lodash"
import { TimeClock, TimeView } from "@mui/x-date-pickers"
import moment from "moment"
import Column from "../Column"
import Row from "../Row"
import theme from "../../styles/theme"

const StyledTimeClock = styled(TimeClock)`
	width: min-content !important;
	height: min-content !important;

	& .MuiClock-root {
		margin: 0 !important;
	}
` as typeof TimeClock

interface StyledButtonProps {
	fontSize?: string;
	fontWeight?: string | number;
	padding?: boolean
}

const StyledButton = styled.div<StyledButtonProps>`
	cursor: pointer;
	user-select: none;

	font-family: ${theme.font.n};
	font-size: ${props => props.fontSize || '16px'};
    font-weight: ${props => props.fontWeight ?? 'inherit'};

	text-align: center;
	margin-block: .1em;
	margin-inline: 2px;

	${props => props.padding && `min-width: 50px;`}
	${props => props.padding
		? `padding: 8px;`
		: `padding-inline: 2px;`
	}

	&:hover {
		background-color: ${theme.colors.button_secondary_hover};
	}
`

const TimeSelect = inputify<TimeSelect.Props, TimeSelect.Value>(
	({
		value,
		onChange,
		onSubmit,
		ampm = false,
		views = ['hours', 'minutes'],
		...props
	}) => {
		const date = useMemo(() => moment(value), [value])
		const [view, setView] = useState<TimeView>('hours')

		return (
			<Column
				gaps="16px*"
				style={{
					width: 'fit-content',
					backgroundColor: 'white',
					padding: '16px',
					boxShadow: `rgba(0, 0, 0, 0.3) 0px 0px 8px 2px`,
				}}
			>
				<StyledTimeClock
					value={date}
					onChange={momentDate => {
						if (momentDate)
							onChange(momentDate.toDate())
					}}

					ampm={ampm}
					views={views}
					view={view}
					onViewChange={setView}
				/>

				<Row justify="space-between" align="end">
					<Row style={{ fontSize: '32px' }}>
						<StyledButton fontSize="32px" onClick={() => setView('hours')}>
							{(value?.getHours() ?? 0).toString().padStart(2, '0')}
						</StyledButton>
						:
						<StyledButton fontSize="32px" onClick={() => setView('minutes')}>
							{(value?.getMinutes() ?? 0).toString().padStart(2, '0')}
						</StyledButton>
					</Row>

					<StyledButton
						padding
						fontWeight={600}
						onClick={() => onSubmit(value)}
					>
						OK
					</StyledButton>
				</Row>
			</Column>
		);
	},
);

namespace TimeSelect {
	export type Value = Date | null

	export interface Props {
		ampm?: boolean
		views?: TimeView[]
		onSubmit: (value?: Value) => void
	}
}

export default TimeSelect;
