import Size from "../types/Size";
import SizeLiteral from "../types/SizeLiteral";

export function isNumeric(value: string) {
  return !isNaN(value as any) && !isNaN(parseFloat(value))
}

export function unifySize(size: Size): SizeLiteral {
  if (Array.isArray(size))
    return {
      width: size[0],
      height: size[0],
    }
  else if (typeof size === "object") {
    return size
  }

  return {
    width: size,
    height: size,
  }
}
