/* eslint-disable import/no-unresolved */
/* eslint-disable func-names */
import { isNumber, isUndefined, noop } from "lodash";
import React, {
	MouseEventHandler,
	memo,
	useCallback,
	useContext,
	useLayoutEffect,
	useRef,
} from "react";
import { Cell as CellBase } from "rsuite-table";
import { PropsOf } from "@emotion/react";
import Table from "../..";
import useContextedFunction, {
	ContextedFunction,
} from "../../../../hooks/useContextedFunction";
import useDebounce from "../../../../hooks/useDebounce";
import useRefSize from "../../../../hooks/useRefSize";
import ContentWrapper from "./components/ContentWrapper";

interface OnRowClickContext {
	original: Table.Props["onRowClick"];
}

const onRowClick: ContextedFunction<OnRowClickContext, [any], void> = function (
	data,
) {
	return this.original?.(data);
};

const Cell = memo(({ autoHeight = false, ...props }: Cell.Props) => {
	const table = useContext(Table.Context);

	const contentWrapperParentRef = useRef<HTMLElement | null>(null);
	const { size, ref: contentWrapperRef } = useRefSize();

	const onRowClickContext: OnRowClickContext = {
		original: table?.onRowClick,
	};

	const [contextedOnRowClick, setOnRowClickContext] = useContextedFunction(
		onRowClick,
		onRowClickContext,
	);

	setOnRowClickContext(onRowClickContext);

	const debouncedOnRowClick = useDebounce(
		contextedOnRowClick,
		table?.doubleClickDebounce ?? 200,
	);

	const onClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			const disableDoubleClickDebounce =
				table?.disableDoubleClickDebounce === true;

			if (!disableDoubleClickDebounce && debouncedOnRowClick.pending()) {
				props.onDoubleClick?.(event);

				debouncedOnRowClick.cancel();

				table?.onRowDoubleClick?.(props.rowData);

				return;
			}

			props.onClick?.(event);

			if (!disableDoubleClickDebounce && table?.onRowDoubleClick)
				debouncedOnRowClick(props.rowData);
			else contextedOnRowClick(props.rowData);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			contextedOnRowClick,
			debouncedOnRowClick,
			props.onClick,
			props.onDoubleClick,
			props.rowData,
			table,
		],
	);

	const onDoubleClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			props.onDoubleClick?.(event);

			table?.onRowDoubleClick?.(props.rowData);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[debouncedOnRowClick, props.onDoubleClick, props.rowData, table],
	);

	const getRef = useCallback(
		(element: HTMLDivElement | null) => {
			contentWrapperParentRef.current = element?.parentElement ?? null;
			contentWrapperRef(element);
		},
		[contentWrapperRef],
	);

	let rowHeight = 0;

	if (autoHeight) {
		let paddings = 0;

		if (contentWrapperParentRef.current) {
			const computedStyle = window.getComputedStyle(
				contentWrapperParentRef.current,
			);

			const paddingTopString =
				computedStyle.getPropertyValue("padding-top");
			const paddingBottomString =
				computedStyle.getPropertyValue("padding-bottom");

			const paddingTop = paddingTopString
				? parseInt(paddingTopString, 10)
				: 0;
			const paddingBottom = paddingBottomString
				? parseInt(paddingBottomString, 10)
				: 0;

			paddings = paddingTop + paddingBottom;
		}

		const height = paddings + size.height;

		if (
			table &&
			isNumber(props.rowIndex) &&
			isNumber(props["aria-colindex"])
		) {
			table.rowHeights[props.rowIndex] =
				table.rowHeights[props.rowIndex] ?? [];

			table.rowHeights[props.rowIndex][props["aria-colindex"] - 1] =
				height;
		}

		if (table && isNumber(props.rowIndex)) {
			rowHeight =
				table.rowHeights[props.rowIndex]?.reduce<number>(
					(accumulator, columnHeight) => {
						if (isNumber(columnHeight))
							return Math.max(accumulator, columnHeight);

						return accumulator;
					},
					0,
				) ?? 0;
		}
	}

	useLayoutEffect(() => {
		table?.render();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowHeight]);

	return (
		<CellBase
			{...props}
			onClick={onClick}
			onDoubleClick={
				table?.disableDoubleClickDebounce ? onDoubleClick : noop
			}
		>
			{(rowData: any, rowIndex?: number) => {
				let content: React.ReactNode | undefined;

				if (typeof props.children === "function")
					content = props.children(rowData, rowIndex);
				else content = props.children;

				if (!content && !isUndefined(props.dataKey))
					content = rowData[props.dataKey];

				return autoHeight ? (
					<ContentWrapper ref={getRef}>{content}</ContentWrapper>
				) : (
					content
				);
			}}
		</CellBase>
	);
});

declare namespace Cell {
	export interface PropsBase {
		onClick?: MouseEventHandler<HTMLElement>;
		onDoubleClick?: MouseEventHandler<HTMLElement>;

		autoHeight?: boolean;
	}

	export type Props = PropsBase & PropsOf<typeof CellBase>;
}

export default Cell;
