import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import styled from "styled-components";
import { uniqueId } from "lodash";
import { inputify, setRefValue } from "../../utils/react";
import Flex from "../Flex";
import { ControlProps, InputBorders } from "../InputBorders";
import Calendar from "../Calendar";
import Row from "../Row";
import Icon from "../Icon";
import theme from "../../styles/theme";
import Float from "../Float";
import Popover from "../Popover";

function pad(number: number | string) {
	return String(number).padStart(2, "0");
}

function dateToString(date?: Date) {
	return date
		? `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
				date.getDate(),
		  )}`
		: "";
}

// function formatText(text: string) {
// 	return text
// 		.trim()
// 		.replace(/^(\d[\.,])/, '0$1')
// 		.replace(/(^\d\d[\.,])(\d[\.,])/, '$10$2')
// 		.replace(/^((?:\d\d[\.,])?\d\d)(\d+)$/, `$1.$2`)
// 		.replace(/.*?(\d\d[\.,]\d\d[\.,]\d{1,4}).*/, '$1')
// 		.replace(/,/g, '.')
// }

function parseDate(text: string) {
	const dateMatch = text.match(/(\d\d?)\.(\d\d?)\.(\d{1,4})?/);

	if (!dateMatch) return undefined;

	const [, stringDate, stringMonth, stringYear] = dateMatch;

	const date = parseInt(stringDate, 10);
	const month = parseInt(stringMonth, 10) - 1;
	const year =
		// eslint-disable-next-line no-nested-ternary
		typeof stringYear === "string"
			? stringYear.length < 4
				? parseInt(stringYear, 10) + 2000
				: parseInt(stringYear, 10)
			: new Date().getFullYear();

	return new Date(year, month, date);
}

const StyledInput = styled.input`
	width: 82px;
	min-width: 82px;
	height: 18px;
	border: none;
	outline: none;

	&[type="date"]::-webkit-calendar-picker-indicator {
		display: none;
	}
`;

declare namespace DatePicker {
	export type Value = Date | null | undefined;

	export interface Props
		extends React.RefAttributes<HTMLInputElement>,
			ControlProps {
		autoFocus?: boolean;
		minDate?: Date;
		maxDate?: Date;
		placeholder?: string | Date | boolean;

		locale?: Calendar.Props["locale"];

		floatContainerId?: string;
		iconColor?: string;
	}
}

const DatePicker = inputify<DatePicker.Props, DatePicker.Value>(
	({
		value,
		onChange,

		autoFocus,
		minDate,
		maxDate,
		placeholder,

		locale,

		floatContainerId = "root",
		iconColor,
		ref,
		...props
	}) => {
		const date = useMemo(
			() => (value ? new Date(value) : undefined),
			[value],
		);
		const floatTrackerId = useMemo(
			() => `datePicker-calendar-${uniqueId()}`,
			[],
		);

		const innerRef = useRef<HTMLInputElement>();
		const [focus, setFocus] = useState(false);
		const [text, setText] = useState(date ? dateToString(date) : "");
		const [showPicker, setShowPicker] = useState(false);

		const clampAndChange = useCallback(
			(payload: Date | undefined) => {
				if (!payload) {
					onChange(payload);
					return;
				}

				const currentDate = new Date();
				const currentYear = currentDate.getFullYear();
				currentDate.setFullYear(currentYear + 100);

				let newDate = new Date(payload);

				if (newDate.valueOf() > currentDate.valueOf()) return;

				if (minDate && newDate.valueOf() < minDate.valueOf())
					newDate = new Date(minDate);

				if (maxDate && newDate.valueOf() > maxDate.valueOf())
					newDate = new Date(maxDate);

				onChange(newDate);
			},
			[minDate, maxDate, onChange],
		);

		useEffect(() => {
			if (!focus) setText(dateToString(date));
		}, [date, focus]);

		useEffect(() => {
			if (autoFocus) innerRef.current?.focus();
		}, [autoFocus]);

		const popover = useMemo(
			() => (
				<Popover
					containerId={floatContainerId}
					trackerId={floatTrackerId}
					open={showPicker}
					offset={({ container, tracker, float }) => {
						if (!container || !tracker || !float)
							return { x: 0, y: 0 };

						const containerPos = container.getPosition();
						const containerSize = container.getSize();

						const trackerPos = tracker.getPosition();
						const trackerSize = tracker.getSize();

						const floatSize = float.getSize();

						const yGap = 5;
						const maxX = trackerPos.x + Number(floatSize.width);
						const maxY = trackerPos.y + Number(floatSize.height);

						let yOffset =
							Number(containerSize.height) < maxY
								? -yGap - Number(floatSize.height)
								: Number(trackerSize.height) + yGap;

						if (trackerPos.y + yOffset < containerPos.y) {
							yOffset = containerPos.y - trackerPos.y;
						}

						return {
							x: Math.min(Number(containerSize.width) - maxX, 0),
							y: yOffset,
						};
					}}
					onClose={() => setShowPicker(false)}
				>
					<Calendar
						value={value || new Date()}
						onSubmit={(newDate) => {
							clampAndChange(newDate);
							setText(dateToString(newDate));
							setShowPicker(false);
						}}
						onCancel={() => {
							setShowPicker(false);
						}}
						minDate={minDate}
						maxDate={maxDate}
						locale={locale}
					/>
				</Popover>
			),
			[
				clampAndChange,
				floatContainerId,
				floatTrackerId,
				locale,
				maxDate,
				minDate,
				showPicker,
				value,
			],
		);

		const placeholderString = useMemo(() => {
			if (placeholder === true) {
				return dateToString(new Date());
			}

			if (placeholder instanceof Date) {
				return dateToString(placeholder);
			}

			if (typeof placeholder === "string") {
				return placeholder;
			}

			return undefined;
		}, [placeholder]);

		const onChangeInput = useCallback(
			(e: React.ChangeEvent<HTMLInputElement>) => {
				const { value: newValue } = e.target as HTMLInputElement;
				const currentDate = new Date();
				const currentYear = currentDate.getFullYear();
				currentDate.setFullYear(currentYear + 100);

				const newDate = new Date(newValue);

				if (newDate.valueOf() > currentDate.valueOf()) return;

				setText(newValue);
				const calendarDate = newValue
					? newValue?.split("-")?.reverse()?.join(".")
					: "";
				clampAndChange(parseDate(calendarDate));
			},
			[clampAndChange],
		);

		return (
			<>
				<Float.Tracker id={floatTrackerId}>
					<InputBorders {...props}>
						<Row gaps="10px*" sizes="1fr auto!">
							<StyledInput
								type="date"
								value={text}
								placeholder={placeholderString}
								onChange={onChangeInput}
								ref={(input) => {
									if (!input) return;

									if (ref) setRefValue(ref, input);
									innerRef.current = input;

									input.onkeydown = (e) => {
										if (e.key !== "Tab") {
											e.stopPropagation();
										}

										if (
											e.key === "Enter" ||
											e.key === "Escape"
										) {
											setFocus(false);
											input.blur();
											setText(dateToString(date));
										}
									};
									input.onfocus = () => setFocus(true);
									input.onblur = () => setFocus(false);
								}}
							/>

							<Flex
								justify="center"
								align="center"
								style={{ cursor: "pointer" }}
								onClick={() => setShowPicker(true)}
							>
								<Icon
									id="calendar"
									size={16}
									colors={[iconColor || theme.colors.primary]}
								/>
							</Flex>
						</Row>
					</InputBorders>
				</Float.Tracker>

				{popover}
			</>
		);
	},
);

export default DatePicker;
