import styled from "styled-components";
import theme from "../../../../../../styles/theme";
import Select from "../../../../../Select";

const TextBox = styled.input<TextBox.Props>`
	all: unset;

	width: 100%;
	height: 18px;

	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;

	color: ${({ disabled }) =>
		disabled ? theme.colors.disabled_text : theme.colors.primary};
	caret-color: ${theme.colors.accent};

	&::placeholder {
		color: ${theme.colors.disabled_text};
	}
`;

declare namespace TextBox {
	type Props = Select.SelectedComponentBase.Props;
}

export default TextBox;
