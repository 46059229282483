import { noop } from "lodash"
import styled from "styled-components"
import theme from "../../../../../../styles/theme"

const Root = styled.div.attrs<Root.Props, Root.Props>((props) => ({
    ...props,
    ...(props.disabled ? { onClick: noop } : {}),
}))`
    display: flex;

    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;

    width: 24px;
    height: 24px;

    ${
        ({ active }) =>
            active
                ? `
                    background: ${theme.colors.accent};
                    color: ${theme.colors.white};
                `
                : `color: ${theme.colors.secondary};`
    }

    border-radius: 5px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    user-select: none;

    ${
        ({ disabled }) =>
            disabled
                ? "cursor: not-allowed;"
                : "cursor: pointer;"
    }
`

declare namespace Root {
    export interface Props {
        active?: boolean
        disabled?: boolean
    }
}

export default Root
