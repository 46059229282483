import React, { FC, ReactElement, useContext } from "react";
import Grid from "../..";
import useCursorPosition from "../../../../hooks/useCursorPosition";
import useEvent from "../../../../hooks/useEvent";
import { popElements } from "../../../../utils/array";
import { GridDragAreaContext } from "../../../GridDragArea";
import { GridGroupAreaContext } from "../../../GridGroupArea";
import { groupItems, placeItem, startDragging } from "../../utils";
import { GroupAreaHoverCore } from "../GroupAreaHoverCore";
import { ItemContext, RenderItemContext } from "../Item";

export interface MoveableCoreGridContextState {
  currentStartDraggingOptions: Grid.StartDraggingOptions | null
}

export interface Props {
  children?: ReactElement

  active: boolean
}

export const MoveableCore: FC<Props> = ({
  children,

  active,
}) => {
  const cursorPosition = useCursorPosition()

  const grid = useContext(Grid.Context)
  const item = useContext(ItemContext)

  useEvent(
    document,
    "mouseup",
    () => {
      if (grid)
        grid.state.currentStartDraggingOptions = null
    }
  )

  return grid && item && active
    ? (
      <GroupAreaHoverCore>
        <GridGroupAreaContext.Provider value={{
          onGroupAreaLeave: () => {
            if (grid.state.currentStartDraggingOptions) {
              grid.setLayout(startDragging(grid.state.currentStartDraggingOptions))

              grid.state.currentStartDraggingOptions = null
            }
          }
        }}>
          <GridDragAreaContext.Provider value={{
            onDragStart: (cursorX, cursorY, indexes) => {
              const leftItemIndexes = popElements(item.indexes, indexes)
              const startDraggingOptions: Grid.StartDraggingOptions = {
                grid,

                itemId: item.id,

                indexes,

                cursorX,
                cursorY
              }

              if (!leftItemIndexes.length || !grid.state.hoveredItems.length)
                grid.setLayout(startDragging(startDraggingOptions))
              else
                grid.state.currentStartDraggingOptions = startDraggingOptions
            },

            onDragStop: (itemX, itemY) => {
              let newLayout = placeItem(
                grid.layout,
                item.id,
                {
                  x: itemX,
                  y: itemY,
                },
                grid
              )

              if (newLayout === grid.layout) {
                newLayout = groupItems(
                  grid.layout,
                  grid.layoutItems,
                  item.drag!.from,
                  item.id,
                )
              }

              grid.setLayout(newLayout)

              grid.state.currentStartDraggingOptions = null
            }
          }}>
            <RenderItemContext.Provider value={
              item.drag
                ? {
                  x: cursorPosition.x + item.drag.cursorOffsetX,
                  y: cursorPosition.y + item.drag.cursorOffsetY,
                  z: 1000,
                }
                : null
            }>
              {children}
            </RenderItemContext.Provider>
          </GridDragAreaContext.Provider>
        </GridGroupAreaContext.Provider>
      </GroupAreaHoverCore>
    )
    : children ?? null
}
