import React, {
	Dispatch,
	ReactElement,
	ReactNode,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
} from "react";
import { v4 as uuid } from "uuid";
import Radio from "../Radio";
import Key from "../../types/Key";

function RadioListBase<OptionValue>({
	value,

	options,
	autoFocus = false,

	children,

	onChange,
}: RadioList.Props<OptionValue>) {
	const name = useMemo(() => uuid(), []);

	const firstAvailableRadioRef = useRef<HTMLLabelElement | null>(null);

	const focus = useCallback(() => {
		firstAvailableRadioRef.current?.focus();
		firstAvailableRadioRef.current?.click();
	}, []);

	useEffect(() => {
		if (autoFocus) {
			process.nextTick(focus);
		}
	}, [autoFocus, focus]);

	const radios = useMemo(() => {
		if (!options) return [];

		const firstAvailableOption = options.find(
			({ disabled = false }) => !disabled,
		);

		return options.map((option) => (
			<Radio
				ref={
					firstAvailableOption === option
						? firstAvailableRadioRef
						: undefined
				}
				key={option.key}
				name={name}
				disabled={option.disabled}
				checked={value === option.value}
				onChange={() => {
					onChange?.(option.value);
				}}
			>
				{option.content}
			</Radio>
		));
	}, [name, onChange, options, value]);

	return children?.(radios) ?? <></>;
}

const RadioList = memo(RadioListBase) as typeof RadioListBase;

declare namespace RadioList {
	interface Option<Value> {
		key: Key;
		value: Value;
		disabled?: boolean;
		content?: ReactNode;
	}

	interface Props<OptionValue> {
		value?: OptionValue;

		autoFocus?: boolean;
		options?: Option<OptionValue>[];

		children?: (radios: ReactNode[]) => ReactElement;

		onChange?: Dispatch<OptionValue>;
	}
}

export default RadioList;
