import React from "react";
import { InputifiedComponentProps, inputify } from "../../utils/react"
import Column from "../Column";
import Row from "../Row";
import styled from "styled-components";
import theme from "../../styles/theme";
import { ToggleButton } from "../ToggleButton";
import CheckBoxWithContent from "../CheckBoxWithContent";

const StyledRoot = styled(Column)``

const StyledItem = styled(Row)`
	height: 32px;
	color: ${theme.colors.primary};
`

const ToggleGroup = inputify<ToggleGroup.OwnProps, ToggleGroup.Value>(
	({
		value,
		labels,
		onChange,
	}) => {
		if (!value)
			return <>value is undefined</>

		return (
			<StyledRoot>
				<StyledItem
					justify="space-between"
					align="center"
				>
					<div style={{
						color: theme.colors.primary,
						fontSize: '16px',
						fontWeight: 700,
					}}>
						{labels?.displaySelf || 'Untitled'}
					</div>

					<ToggleButton
						value={value.displaySelf}
						onChange={newValue => {
							onChange({
								...value,
								displaySelf: newValue,
							})
						}}
					/>
				</StyledItem>

				{Object.keys(value.display).map(key => (
					<StyledItem
						key={key}
						justify="space-between"
						align="center"
					>
						<CheckBoxWithContent
							gap='16px'
							disabled={!value.displaySelf}
							value={value.display[key]}
							onChange={newValue => {
								onChange({
									...value,
									display: {
										...value.display,
										[key]: newValue,
									}
								})
							}}
							style={{
								fontSize: "14px",
								color: value.displaySelf ? 'inherit' : theme.colors.disabled_text
							}}
						>
							{labels?.display[key] || 'untitled'}
						</CheckBoxWithContent>
					</StyledItem>
				))}
			</StyledRoot>
		)
	}
) as <Keys extends string>(props: ToggleGroup.Props<Keys>) => React.ReactElement

declare namespace ToggleGroup {
	interface DisplayList<Value, Keys extends string = string> {
		displaySelf: Value
		display: Record<Keys, Value>
	}

	type Value<Keys extends string = string> = DisplayList<boolean, Keys>
	type Labels<Keys extends string = string> = DisplayList<string, Keys>

	interface OwnProps<Keys extends string = string> {
		labels?: Labels<Keys>
	}

	type Props<Keys extends string = string> = OwnProps<Keys> & InputifiedComponentProps<Value<Keys>>;
}

export default ToggleGroup