import React, { DOMAttributes, forwardRef, PropsWithChildren } from "react";
import { styled } from "@storybook/theming";

import { text } from "../../styles/typographyStyle";

export interface TextProps extends PropsWithChildren {
	variant: keyof typeof text;
}

const TextBase = styled.p<{ variant: keyof typeof text }>(
	{ marginBottom: 20, fontFamily: "Lato" },
	(props) => text[props.variant],
);

export interface Props
	extends PropsWithChildren,
		TextProps,
		DOMAttributes<HTMLElement> {}

export const Text = forwardRef<HTMLElement, Props>(
	({ children, variant, ...props }, ref) => (
		<TextBase {...props} variant={variant}>
			<>{children}</>
		</TextBase>
	),
);

TextBase.defaultProps = {
	variant: "Body",
};

export default Text;
