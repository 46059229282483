import { ForwardedRef, useCallback } from "react";
import { setRefValue } from "../utils/react";

export default function useRefSetter<T>(ref: ForwardedRef<T>) {
  const setter = useCallback(
    (value: T) => { setRefValue(ref, value) },
    [ref],
  )

  return setter
}
