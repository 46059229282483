import React, { useMemo } from "react"
import { inputify } from "../../utils/react"
import Row from "../Row"
import PageSelector from "./components/PageSelector"

function pageSelector(index: number) {
    return (
        <PageSelector
            type="index"
            index={index}
        />
    )
}

function customPageSelector() {
    return (
        <PageSelector
            type="custom"
        />
    )
}

function pageSelectorRange(fromIndex: number, toIndex: number) {
    return Array(toIndex - fromIndex + 1)
        .fill(null)
        .map((_, index) => pageSelector(fromIndex + index))
}

const Pagination = inputify<Pagination.Props, number>(
    ({
        value = 0,
        total,
        pageSize,

        onChange,
    }) => {
        const pages = useMemo(() => Math.ceil(total / pageSize), [total, pageSize])
        const type = useMemo(
            () =>
                pages < 8 ? "all" :
                value < 4 ? "left" :
                value > pages - 5 ? "right" :
                "middle",
            [value, pages]
        )

        return (
            <Row
                gaps="11px*"
            >
                <PageSelector.Context.Provider
                    value={{
                        value,
                        pages,

                        onChange,
                    }}
                >
                    <PageSelector type="prev" />
                    {
                        type === "all" ? pageSelectorRange(0, pages - 1) :
                        type === "left" ? [...pageSelectorRange(0, 4), customPageSelector(), pageSelector(pages - 1)] :
                        type === "middle" ? [pageSelector(0), customPageSelector(), ...pageSelectorRange(value - 1, value + 1), customPageSelector(), pageSelector(pages - 1)] :
                        type === "right" ? [pageSelector(0), customPageSelector(), ...pageSelectorRange(pages - 5, pages - 1)] :
                        null
                    }
                    <PageSelector type="next" />
                </PageSelector.Context.Provider>
            </Row>
        )
    }
)

declare namespace Pagination {
    export interface Props {
        total: number
        pageSize: number
    }
}

export default Pagination

