import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { noop } from "lodash";
import theme from "../../styles/theme";
import StyleProps from "../../types/StyleProps";
import { inputify } from "../../utils/react";
import Row from "../Row";
import variants from "./variants";

interface RootProps {
	hasPaddings: boolean;

	variant: keyof typeof variants;
	transparent: boolean;
	hovered: boolean;
	pressed: boolean;
	disabled: boolean;
}

const Root = styled.button<RootProps>`
	display: flex;

	flex-direction: row;
	flex-wrap: nowrap;

	align-items: center;
	justify-content: center;

	width: max-content;
	height: 32px;

	min-width: 32px;

	padding-inline: ${(props) => (props.hasPaddings ? "15px" : "none")};

	font-family: ${theme.font.n};
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;

	border: none;
	border-radius: 5px;

	cursor: pointer;

	user-select: none;

	${({ variant, transparent, hovered, pressed, disabled }) => {
		const type = transparent ? "transparent" : "default";
		const variantSchema = variants[variant][type];

		return `
			fill: ${variantSchema.normal.content};
			color: ${variantSchema.normal.content};
			background: ${variantSchema.normal.background};

			${hovered ? "&," : ""}
			&:hover,
			&:has(:hover) {
				fill: ${variantSchema.hover.content};
				color: ${variantSchema.hover.content};
				background: ${variantSchema.hover.background};
			}

			${pressed ? "&," : ""}
			&:active,
			&:has(:active) {
				fill: ${variantSchema.pressed.content};
				color: ${variantSchema.pressed.content};
				background: ${variantSchema.pressed.background};
			}

			${disabled ? "&," : ""}
			&:disabled,
			&[disabled] {
				fill: ${variantSchema.disabled.content} !important;
				color: ${variantSchema.disabled.content} !important;
				background: ${variantSchema.disabled.background} !important;

				cursor: not-allowed;
			}
		`;
	}}

	&:focus {
		border: none;
		outline: 1px solid ${theme.colors.primary};
	}
`;

export type Variant = keyof typeof variants;

export interface Props extends StyleProps {
	variant?: Variant;
	transparent?: boolean;
	hasPaddings?: boolean;

	id?: string;
	text?: string;
	title?: string;
	icon?: React.ReactNode;
	iconPosition?: "left" | "right";

	tabIndex?: number;

	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	onMouseDown?: React.MouseEventHandler<HTMLButtonElement>;
	onMouseUp?: React.MouseEventHandler<HTMLButtonElement>;
}

export const Button = inputify<Props, never>(
	({
		id,
		style,
		className,

		hovered,
		disabled,

		variant = "primary",
		transparent = false,
		hasPaddings,

		text = "",
		title,
		icon,
		iconPosition = "left",

		tabIndex,

		onEnter,
		onLeave,
		onFocus,
		onBlur,

		onClick,
		onMouseDown,
		onMouseUp,
	}) => {
		const rootRef = useRef<HTMLButtonElement | null>(null);
		const [pressed, setPressed] = useState<boolean>(false);

		const rootOnKeyDown = useCallback(
			(event: React.KeyboardEvent<HTMLButtonElement>) => {
				if (event.code === "Enter" || event.code === "NumpadEnter") {
					event.preventDefault();
					event.stopPropagation();

					rootRef.current?.click();
				}
			},
			[],
		);

		return (
			<Root
				id={id}
				title={title}
				ref={rootRef}
				style={style}
				className={className}
				variant={variant}
				transparent={transparent}
				hasPaddings={hasPaddings ?? text.length !== 0}
				hovered={hovered}
				pressed={pressed}
				disabled={disabled}
				tabIndex={tabIndex}
				onMouseEnter={onEnter}
				onMouseLeave={onLeave}
				onFocus={onFocus}
				onBlur={onBlur}
				onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
					onMouseDown?.(event);

					setPressed(true);
				}}
				onMouseUp={(event: React.MouseEvent<HTMLButtonElement>) => {
					onMouseUp?.(event);

					setPressed(false);
				}}
				onKeyDown={rootOnKeyDown}
				onClick={disabled ? noop : onClick}
			>
				<Row gaps="10px" align="center" justify="center">
					{iconPosition === "left" ? (
						<>
							{icon}
							{text}
						</>
					) : (
						<>
							{text}
							{icon}
						</>
					)}
				</Row>
			</Root>
		);
	},
);
