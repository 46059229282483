import React from 'react';
import styled from 'styled-components';
import { inputify } from '../../utils/react';
import Row from '../Row';
import { ToggleButton } from '../ToggleButton';
import theme from '../../styles/theme';

const Root = styled.div`
  cursor: pointer;
`;

interface ContentProps {
  active: boolean
}

const Content = styled.div<ContentProps>`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: ${
    ({ active }) =>
      active
        ? theme.colors.primary
        : theme.colors.secondary
  };
`;

export interface Props {
  content: React.ReactNode
}

export const ToggleButtonWithContent = inputify<Props, boolean>(({
  value = false,
  hovered,
  focused,
  error,
  disabled,
  content,
  onChange,
  onEnter,
  onLeave,
  onFocus,
  onBlur,
}) => {
  return (
    <Root
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <Row
        align="center"
        gaps="14px"
      >
        <ToggleButton
          value={value}
          hovered={hovered}
          focused={focused}
          error={error}
          disabled={disabled}
          onChange={onChange}
          onEnter={onEnter}
          onLeave={onLeave}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <Content active={value}>
          {content}
        </Content>
      </Row>
    </Root>
  )
})
