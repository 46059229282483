import styled from "styled-components"
import Select from "../.."
import { InputBorders, Props as InputBordersProps, classes as inputBordersClasses } from "../../../InputBorders"

const MainBorders = styled(InputBorders)<MainBorders.Props>`
    height: 100%;

    ${({ open, direction }) => open
        ?   `
                & .${inputBordersClasses.borders} {
                    ${
                        direction === "down"
                            ? `
                                border-bottom-color: transparent;
                                border-bottom-left-radius: 0;
                                border-bottom-right-radius: 0;
                            `
                            : `
                                border-top-color: transparent;
                                border-top-left-radius: 0;
                                border-top-right-radius: 0;
                            `
                    }
                }
            `
        : ""
    }
`

declare namespace MainBorders {
    export interface Props extends InputBordersProps {
	    open: boolean
        direction: Select.Direction
    }
}

export default MainBorders
