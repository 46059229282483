import React, {
	InputHTMLAttributes,
	PropsWithChildren,
	forwardRef,
	useCallback,
	useEffect,
	useRef,
} from "react";
import Input from "./components/Input";
import Root from "./components/Root";
import Circle from "./components/Circle";

const Radio = forwardRef<Radio.Ref, Radio.Props>(
	({ autoFocus = false, children, ...props }, ref) => {
		const inputRef = useRef<HTMLInputElement>(null);

		const focus = useCallback(() => inputRef.current?.focus(), []);

		useEffect(() => {
			if (autoFocus) process.nextTick(focus);
		}, [autoFocus, focus]);

		return (
			<Root ref={ref}>
				<Input ref={inputRef} type="radio" name="" {...props} />
				<Circle />
				{children}
			</Root>
		);
	},
);

declare namespace Radio {
	type Ref = HTMLLabelElement;

	interface Props
		extends PropsWithChildren,
			Omit<InputHTMLAttributes<HTMLInputElement>, "type" | "ref"> {}
}

export default Radio;
