import React, { forwardRef } from "react";
import styled from "styled-components";
import StyleProps from "../../types/StyleProps";

const Root = styled.div`
	position: relative;
`;

interface OverlayContainerProps {
	zIndex?: number;
}

const OverlayContainer = styled.div<OverlayContainerProps>`
	position: absolute;
	z-index: ${({ zIndex }) => zIndex ?? 1000};

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	pointer-events: none;

	& > * {
		pointer-events: all;
	}
`;

const ContentContainer = styled.div`
	width: 100%;
	height: 100%;
`;

const Overlayer = forwardRef<HTMLDivElement, Overlayer.Props>(
	({ children, overlay, zIndex, ...props }, ref) => (
		<Root ref={ref} {...props}>
			<ContentContainer>{children}</ContentContainer>
			<OverlayContainer zIndex={zIndex}>{overlay}</OverlayContainer>
		</Root>
	),
);

namespace Overlayer {
	export interface Props extends React.PropsWithChildren, StyleProps {
		overlay?: React.ReactNode | React.ReactNode[];
		zIndex?: number;
	}
}

export default Overlayer;
