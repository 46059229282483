import React, {
	Dispatch,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
} from "react";
import styled from "styled-components";
import moment from "moment";
import { InputBorders, Props as InputBordersProps } from "../InputBorders";

const TimeInputBase = styled.input`
	height: 17px;
	border: none;
	outline: none;

	&[type="time"]::-webkit-calendar-picker-indicator {
		display: none;
	}
`;

declare namespace TimeInput {
	export type Value = Date | null | undefined;

	export interface Props extends InputBordersProps {
		value?: Value;

		step?: number;
		autoFocus?: boolean;
		disabled?: boolean;

		onChange?: Dispatch<Value>;
		id?: string;
	}
}

const TimeInput = ({
	value,

	step = 1,
	autoFocus,
	disabled = false,

	onChange,
	id,
	...props
}: TimeInput.Props) => {
	const ref = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (autoFocus) ref.current?.focus();
	}, [autoFocus]);

	const inputBordersOnClick = useCallback((event: React.MouseEvent) => {
		event.preventDefault();
		event.stopPropagation();

		ref.current?.focus();
	}, []);

	const inputBordersOnMouseDown = useCallback((event: React.MouseEvent) => {
		event.preventDefault();
		event.stopPropagation();
	}, []);

	const onInputChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const { valueAsDate, valueAsNumber } = e.target;

			if (!valueAsDate) {
				onChange?.(null);

				return;
			}

			onChange?.(
				moment(new Date(value ?? 0))
					.startOf("day")
					.add(valueAsNumber, "milliseconds")
					.toDate(),
			);
		},
		[onChange, value],
	);

	const internalValue = useMemo(() => {
		if (!value) return null;

		let time = moment(value);

		if (step >= 1) {
			if (step >= 3600) time = time.startOf("hour");
			else if (step >= 60) time = time.startOf("minute");
			else if (step >= 1) time = time.startOf("second");

			time = time.subtract(value.getTimezoneOffset(), "minutes");
		}

		return time.toDate();
	}, [step, value]);

	useLayoutEffect(() => {
		if (!ref.current) return;

		ref.current.valueAsDate = internalValue;
	}, [internalValue, step, value]);

	if (ref.current) {
		ref.current.valueAsDate = internalValue;
	}

	return (
		<InputBorders
			disabled={disabled}
			onClick={inputBordersOnClick}
			onMouseDown={inputBordersOnMouseDown}
			{...props}
		>
			<TimeInputBase
				id={id}
				ref={ref}
				type="time"
				disabled={disabled}
				step={step}
				onChange={onInputChange}
			/>
		</InputBorders>
	);
};

export default TimeInput;
