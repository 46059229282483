/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React, { FC, memo, useEffect, useState } from "react";
import styled from "styled-components";
import cn from "classnames";
import { Tab } from "semantic-ui-react";
import { SVGgeneral } from "../SvgComponents/index";
import { files } from "../../icons/test";

import { TabsColor, TabsColors } from "./tabsColors";

import { Button } from "../Button/index";

interface StyledTabsProps {
	color: TabsColor;
}

const StyledMainComponentsTabs = styled.div`
	height: calc(100% - 55px);

	.tabs {
		height: 100%;
	}

	.tabs > div:first-child {
		display: flex !important;
		border-top-right-radius: 7px !important;
		border-top-left-radius: 7px !important;
	}

	.tabs > div:first-child {
		background: #ebebeb !important;
		padding: 8px 8px 0 !important;
		overflow-x: auto;
		overflow-y: hidden;
		border-bottom: 0px solid #d4d4d5 !important;
		position: relative;
	}

	.tabs::-webkit-scrollbar {
		height: 3px;
		cursor: pointer;
	}

	.mapButton {
		position: absolute;
		top: 30px;
	}

	.addTabs {
		padding: 0 0 7px 15px;
		z-index: 2;
	}
`;

const StyledTabsItem = styled.div<StyledTabsProps>`
	font-family: Lato;
	font-size: 13px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: center;
	color: #647079;
	padding: 0px 2px 0 2px;

	&.tab {
		display: flex !important;
		align-items: center !important;
		gap: 3.5px;
		padding: 8px 14px 8px 14px !important;
		border-top-right-radius: 7px !important;
		border-top-left-radius: 7px !important;
		background: #ebebeb !important;
		cursor: pointer;
	}

	&.active {
		background: #fff !important;
		color: #03a9f4 !important;
	}

	&.label {
		margin: 0px 5px 0 5px;
	}

	.content {
		border-radius: 5px;
		padding: 1px 6px 2px 6px;
		background-color: ${(props) => TabsColors[props.color]};
		color: #ffffff;

		font-family: Lato;
		font-size: 12px;
		font-weight: 600;
		line-height: 14px;
		letter-spacing: 0em;
		text-align: center;
	}

	.cancelbuttonicon {
		height: 10px;
		width: 10px;
		margin: 3px 5px 0 5px;
		fill: grey;
	}

	.dragIcon {
		width: 12px;
		height: 12px;
		cursor: grab;
		fill: #9ba3a8;
	}
`;

const TabItem = ({
	onClick,
	active,
	label,
	onClose,
	dragIcon,
	disabledIcon,
	numberIcon,
	number,
	color,
}) => (
	<StyledTabsItem
		color={color}
		onClick={onClick}
		className={cn("tab", { active, "": false })}
	>
		{disabledIcon || numberIcon ? null : dragIcon ? (
			<SVGgeneral data={files} id={"dragIcon"} />
		) : null}
		<div className={disabledIcon || numberIcon ? "label" : ""}>{label}</div>
		{disabledIcon ? null : numberIcon ? (
			number ? (
				<>
					<div className={"content"}>{number}</div>
					<div
						onClick={(e) => {
							onClose();
							e.stopPropagation();
						}}
					>
						<SVGgeneral
							data={files}
							id={"cancelbuttonicon"}
							className={"cancelbuttonicon"}
						/>
					</div>
				</>
			) : (
				<div
					onClick={(e) => {
						onClose();
						e.stopPropagation();
					}}
				>
					<SVGgeneral
						data={files}
						id={"cancelbuttonicon"}
						className={"cancelbuttonicon"}
					/>
				</div>
			)
		) : (
			<div
				onClick={(e) => {
					onClose();
					e.stopPropagation();
				}}
			>
				<SVGgeneral
					data={files}
					id={"cancelbuttonicon"}
					className={"cancelbuttonicon"}
				/>
			</div>
		)}
	</StyledTabsItem>
);

export interface SliderData {
	label: string;
	type: string;
	number: number;
	color: string;
}

export interface TabSlidersProps {
	slidersData: SliderData[];
	variant?: boolean;
	dragIcon?: boolean;
	disabledIcon?: boolean;
	numberIcon?: boolean;
	color?: TabsColor;
	activeTab: number;
	onCloseTab?: (tab: number) => void;
	onTabChange?: (tab: number) => void;
	onAddTabs?: () => void;
}

const TabsBase: FC<TabSlidersProps> = ({
	slidersData,
	dragIcon,
	disabledIcon = false,
	numberIcon = false,
	activeTab = 0,
	onTabChange = () => {},
	onCloseTab = () => {},
	onAddTabs = () => {},
}) => {
	const getActualTabs = () =>
		slidersData.map((item) => ({
			menuItem: (_, props) => (
				<TabItem
					onClick={() => onTabChange(slidersData.indexOf(item))}
					label={item.label}
					active={props.active}
					dragIcon={dragIcon}
					disabledIcon={disabledIcon}
					numberIcon={numberIcon}
					number={item.number}
					color={item.color}
					onClose={() => onCloseTab(slidersData.indexOf(item))}
				/>
			),
			render: () => null,
		}));

	const getButtonAdd = () => ({
		menuItem: () => (
			<div className="addTabs">
				<Button
					variant={"primary"}
					icon={
						<SVGgeneral
							data={files}
							id={"plusButton"}
							className={"cancelbuttonicon"}
						/>
					}
					onClick={() => onAddTabs()}
				/>
			</div>
		),
		render: () => null,
	});

	const [navTabs, setNavTabs] = useState(getActualTabs());
	const [buttonTab] = useState(getButtonAdd());

	useEffect(() => {
		setNavTabs(getActualTabs());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slidersData, activeTab]);

	return (
		<StyledMainComponentsTabs>
			<div>
				<Tab
					className={"tabs"}
					activeIndex={activeTab}
					onTabChange={(_, { activeIndex }) => {
						if (typeof activeIndex === "number")
							onTabChange(activeIndex);
					}}
					panes={
						disabledIcon
							? navTabs
							: Array.from(navTabs.concat(buttonTab))
					}
				/>
			</div>
		</StyledMainComponentsTabs>
	);
};

export const Tabs = memo(TabsBase);
