import { createContext, Dispatch } from "react";
import useRender from "../../hooks/useRender";

const Context = createContext<Context.Value | null>(null);

declare namespace Context {
	interface Value {
		doubleClickDebounce: number;
		disableDoubleClickDebounce: boolean;
		rowHeights: (number | undefined)[][];

		render: ReturnType<typeof useRender>;

		onRowClick?: Dispatch<any>;
		onRowDoubleClick?: Dispatch<any>;
	}
}

export default Context;
