import React, {
	MouseEvent,
	PropsWithChildren,
	useCallback,
	useRef,
} from "react";
import { extend } from "lodash";
import { inputify } from "../../utils/react";
import Row from "../Row";
import CheckBox from "../CheckBox";
import StyleProps from "../../types/StyleProps";
import Content from "./components/Content";
import Root from "./components/Root";

const CheckBoxWithContent = extend(
	inputify<CheckBoxWithContent.Props, boolean>(
		({
			children,

			value = false,
			hovered,
			focused,
			error,
			disabled,

			gap = "10px",
			maxContentWidth = false,

			style,
			className,

			onChange,
			onEnter,
			onLeave,
			onFocus,
			onBlur,
		}) => {
			const checkBoxRef = useRef<HTMLDivElement | null>(null);

			const onClick = useCallback(
				(event: MouseEvent<HTMLDivElement>) => {
					event.preventDefault();
					event.stopPropagation();

					checkBoxRef.current?.focus();

					disabled ? undefined : onChange(!value);
				},
				[onChange, value, disabled],
			);

			const internalOnFocus = useCallback(() => {
				// checkBoxRef.current?.focus();
			}, []);

			return (
				<Root
					style={style}
					className={className}
					disabled={disabled}
					onMouseEnter={onEnter}
					onMouseLeave={onLeave}
					onFocus={internalOnFocus}
					onBlur={onBlur}
					onClick={onClick}
				>
					<Row align="center" gaps={gap}>
						<CheckBox
							ref={checkBoxRef}
							value={value}
							hovered={hovered}
							focused={focused}
							error={error}
							disabled={disabled}
							onChange={onChange}
							onEnter={onEnter}
							onLeave={onLeave}
							onFocus={onFocus}
							onBlur={onBlur}
						/>
						<Content
							maxWidth={maxContentWidth}
							active={value}
							disabled={disabled}
						>
							{children}
						</Content>
					</Row>
				</Root>
			);
		},
	),
	{
		classes: {
			root: Root.styledComponentId,
			content: Content.styledComponentId,
		},
	},
);

declare namespace CheckBoxWithContent {
	export interface Props extends PropsWithChildren, StyleProps<typeof Root> {
		gap?: string;

		maxContentWidth?: boolean;
	}
}

export default CheckBoxWithContent;
