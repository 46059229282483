import React, { FC, useContext, useMemo } from "react";
import TextSelect from "../..";
import Select from "../../../Select";

const Option: FC<Option.Props> = ({ option, ...props }) => {
	const context = useContext(TextSelect.Context);

	const hovered = useMemo(
		() => context?.options?.[context?.hoveredOptionIndex] === option,
		[context?.options, context?.hoveredOptionIndex, option],
	);
	const index = useMemo(
		() => context?.options?.indexOf(option) ?? -1,
		[context?.options, option],
	);

	return (
		context && (
			<Select.OptionList.Item.Root
				{...props}
				hovered={hovered}
				option={option}
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				onMouseEnter={() => context!.onChangeHoveredOptionIndex(index)}
			/>
		)
	);
};

declare namespace Option {
	type Props = Select.OptionComponentBase.Props;
}

export default Option;
