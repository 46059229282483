import { clone } from 'lodash';
import React, { FC, ReactElement, createContext, useContext } from 'react';
import { mergeValues } from '../../../../utils/object';
import { createStackableContext, renderNested } from '../../../../utils/react';
import { LayoutItemDrag, ItemLayout, LayoutItemContextOptions } from '../../types';
import { Root } from './components/Root';

type RenderItemContextValue = Partial<Props> | null

function combineRenderItemContextValues(prevContextValue: RenderItemContextValue, nextContextValue: RenderItemContextValue) {
  const result = Object.assign(clone(prevContextValue) ?? { }, nextContextValue)

  result.ItemLayout = mergeValues({ ItemLayout: prevContextValue?.ItemLayout }, { ItemLayout: nextContextValue?.ItemLayout })?.ItemLayout

  return result
}

export const ItemContext = createContext<LayoutItemContextOptions | null>(null)
export const RenderItemContext = createStackableContext<RenderItemContextValue>(null, combineRenderItemContextValues)

export interface Props {
  children: Record<string, ReactElement>

  indexes: string[]

  drag?: LayoutItemDrag

  visible: boolean

  x: number
  y: number
  z: number

  width: number
  height: number

  ItemLayout: ItemLayout | ItemLayout[]
}

export const Item: FC<Props> = (props) => {
  const outerItemProps = useContext(RenderItemContext)

   const {
    children,
  
    visible,
  
    x,
    y,
    z,
  
    width,
    height,
  
    ItemLayout,
  } = combineRenderItemContextValues(clone(props), outerItemProps) as Required<Props>

  const itemLayouts = Array.isArray(ItemLayout) ? ItemLayout : [ItemLayout]

  return visible
    ? (
      <Root
        x={x}
        y={y}
        z={z}
        width={width}
        height={height}
      >
        {renderNested(children, itemLayouts)}
      </Root>
    )
    : null
}
