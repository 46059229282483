import { isUndefined } from "lodash";
import { useState, useCallback } from "react";

export default function useRender() {
	const [, render] = useState<object>();

	return useCallback(
		(immediately = false) => {
			if (immediately) {
				render((value) => (isUndefined(value) ? {} : undefined));

				return;
			}

			process.nextTick(() =>
				render((value) => (isUndefined(value) ? {} : undefined)),
			);
		},
		[render],
	);
}
