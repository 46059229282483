import React from 'react';
import styled from "styled-components";

const StyledSVG = styled.svg`
    margin-right: auto;
    margin-left: auto;
`

const copyFunc = (message: string) => {
    navigator.clipboard.writeText(message).then(
        () => console.log(message),
        () => console.log("text no copy")
    );
};

export interface Props {
    data: any
    id?: string | undefined
    prefix?: string
    tabIndex?: number | undefined
    isHidden?: boolean
    children?: React.ReactNode
    height?: any
    width?: any
    viewBox?: any
    className?: string
    style?: any
    fill?: string
    stroke?: string
}


export function SVGgeneral({
    data,
    id = "1",
    prefix = "img",
    tabIndex,
    isHidden = false,
    children,
    height,
    width,
    viewBox,
    className,
    style,
    fill,
    stroke,
}: Props) {
    if (id === "viewAll") {
        return (
            <div
                style={{
                    top: 0,
                    left: 0,
                    background: "white",
                    position: "fixed",
                    zIndex: 9999999999,
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {Object.keys(data.byId).map((iter) => (
                    <button
                        type="button"
                        onClick={() => copyFunc(iter)}
                        key={iter}
                        tabIndex={tabIndex}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            preserveAspectRatio="xMinYMin"
                            id={iter}
                            width={40}
                            height={40}
                            viewBox="0 0 40 40"
                        >
                            <use xlinkHref={`#${prefix}-${iter}`} />
                        </svg>
                    </button>
                ))}
            </div>
        );
    }

    if (isHidden) {
        return (
            <svg display="none" style={{ display: "none" }}>
                {children}
            </svg>
        );
    }

    const file = data.byId[id];

    if (!file) return null;
    // const width = props.width || file.width || null;
    const SvgHeight = height || file.height || null;
    const deltaWidth = width || height * (file.width / file.height) || null;

    const SvgClassName = className || "SVGsettings";
    const SvgViewBox = file.viewBox
        ? file.viewBox.join(" ")
        : `0 0 ${width} ${height}`;

    const viewData = typeof file.data === "string"
        ? {
            dangerouslySetInnerHTML: { __html: file.data },
        }
        : {
            children: file.data
        }

    return isHidden ? (
        <svg display="none">{children}</svg>
    ) : (
        <StyledSVG
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            preserveAspectRatio="xMinYMin"
            width={deltaWidth}
            height={SvgHeight}
            viewBox={viewBox ? viewBox : SvgViewBox}
            style={{ fill, stroke, ...style, display: "block" }}
            className={SvgClassName}
            id={id}
            {...viewData}
        >
        </StyledSVG>
    );
}
