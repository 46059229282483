import { isInteger } from "lodash";
import { isNumeric } from "./types";

export interface FRSize {
	type: "fr";
	value: number;
	strict: boolean;
}

export interface StringSize {
	type: "string";
	value: string;
	strict: boolean;
}

export type Size = FRSize | StringSize;

const frRegexp = /^(^\d+(?:\.)?(?:\d+)?)fr$/m;

function parseTemplate(template: string) {
	if (!template) return [];

	const elements = template.trim().split(/\s+/g);

	return elements
		.map((element) => {
			// eslint-disable-next-line prefer-const
			let [length, times] = element.split("*") as [
				string,
				string | number,
			];

			if (typeof times !== "string") return { length };

			if (isNumeric(times)) {
				times = parseFloat(times);

				if (times < 0) {
					console.warn(
						"You are using negative number of the same lengths, automatically ignore it",
					);

					times = 0;
				}

				if (!isInteger(times)) {
					console.warn(
						"You are using not integer number of the same lengths, automatically floor it",
					);

					times = Math.floor(times);
				}

				return Array<{ length: string }>(times).fill({ length });
			}
			if (times.length !== 0)
				console.warn(
					"You are using not numeric number of the same lengths, automatically change it to unlimited",
				);

			times = "auto";

			return { length, times };
		})
		.flat() as { length: string; times?: "auto" }[];
}

export function processLengths(template: string, limit: number) {
	if (limit === 0) return [];

	const tokens = parseTemplate(template);
	const dynamicTokenCount = tokens.reduce(
		(accumulator, token) =>
			accumulator + ((token.times === "auto") as unknown as number),
		0,
	);
	const staticTokenCount = tokens.length - dynamicTokenCount;

	if (staticTokenCount > limit)
		return tokens.slice(0, limit).map((token) => token.length);

	const dynamicElementCount = limit - staticTokenCount;
	const elementsPerDynamicToken = dynamicElementCount / dynamicTokenCount;
	const flooredElementsPerDynamicToken = Math.floor(elementsPerDynamicToken);
	const additionalElementsPerDynamicToken =
		elementsPerDynamicToken - flooredElementsPerDynamicToken;

	let currentAdditionalElementsPerDynamicToken = 0;

	const elementsPerDynamicTokenList = Array(dynamicTokenCount)
		.fill(null)
		.map(() => {
			currentAdditionalElementsPerDynamicToken +=
				additionalElementsPerDynamicToken;

			const flooredCurrentAdditionalElementsPerDynamicToken = Math.floor(
				currentAdditionalElementsPerDynamicToken,
			);

			currentAdditionalElementsPerDynamicToken -=
				flooredCurrentAdditionalElementsPerDynamicToken;

			return (
				flooredElementsPerDynamicToken +
				flooredCurrentAdditionalElementsPerDynamicToken
			);
		})
		.reverse();

	let autoTokenIndex = 0;

	return tokens
		.map((token) => {
			if (token.times === "auto")
				return Array<string>(
					elementsPerDynamicTokenList[autoTokenIndex++],
				).fill(token.length);

			return token.length;
		})
		.flat();
}

export function processGaps(template: string, limit: number) {
	return processLengths(template, limit);
}

export function processSizes(template: string, limit: number) {
	let minFr = Number.POSITIVE_INFINITY;

	const sizes = processLengths(template, limit).map((length) => {
		const strict = length.endsWith("!");

		if (strict) length = length.slice(0, -1);

		const match = length.match(frRegexp);

		if (!match)
			return {
				type: "string",
				value: length,
				strict,
			} as Size;

		const value = parseFloat(match[1]);

		minFr = Math.min(minFr, value);

		return {
			type: "fr",
			value,
			strict,
		} as Size;
	});

	sizes.forEach((length) => {
		if (length.type === "fr") length.value /= minFr;
	});

	return sizes;
}
