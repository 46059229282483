import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import theme from "../../styles/theme"
import { inputify } from "../../utils/react";

function propsToColor({ hovered, focused, error, disabled }) {
  while (true) {
    if (disabled)
      break;

    if (error)
      return theme.colors.error;

    if (focused)
      return theme.colors.accent;

    if (hovered)
      return theme.colors.primary;

    break;
  }

  return theme.colors.color_border_basic;
}

interface InputStateProps {
  hovered: boolean
  focused: boolean
  error: string | boolean
  disabled: boolean
}

interface RootProps extends PropsWithChildren, InputStateProps {
	nested?: "first" | "middle" | "last"
}

const Root = styled.div<RootProps>`
  border: 1px ${propsToColor} solid;
  border-left: 0;
  border-right: 0;

  ${({ nested }) => {
		switch (nested) {
			case "first":
				return `
					padding-right: 6px;
				`;
			case "last":
				return `
					padding-left: 6px;
				`;
			case "middle":
				return `
					padding-left: 6px;
					padding-right: 6px;
				`;
			default:
				return "";
		}
	}}
`;

interface StrokeProps extends InputStateProps {
}

const Stroke = styled.div<StrokeProps>`
  width: 1px;
  height: 100%;

  background-color: ${propsToColor};
`

export const uniqClass = Root.styledComponentId;

export interface Props {
	nested?: "first" | "middle" | "last"
}

export const InputBorder = inputify<Props>(({
  onEnter,
  onLeave,

  ...props
}) => {
  return (
    <Root
      {...props}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      <Stroke {...props} />
    </Root>
  );
});
