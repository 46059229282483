import React, { FC, useCallback, useContext, useEffect, useRef } from "react";
import TextSelect from "../..";
import useInternal from "../../../../hooks/useInternal";
import Select from "../../../Select";
import TextBox from "./components/TextBox";

const Selected: FC<Selected.Props> = ({ disabled, selected }) => {
	const context = useContext(TextSelect.Context);

	const contextOnChange = context?.onChange;

	const [internalValue, setInternalValue] = useInternal(context?.value ?? "");

	const setValue = useCallback(
		(value: string) => {
			contextOnChange?.(value);
			setInternalValue(value);
		},
		[contextOnChange, setInternalValue],
	);

	const rootRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		if (!rootRef.current) return;

		if (context?.focused) rootRef.current.focus();
		else rootRef.current.blur();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rootRef.current, context?.focused]);

	return (
		context && (
			<TextBox
				ref={rootRef}
				placeholder={context.placeholder}
				autoFocus={context.autoFocus}
				disabled={disabled}
				selected={selected}
				value={internalValue}
				onChange={(event) => setValue(event.target.value)}
				onKeyDown={(event) => {
					if (
						event.key !== "ArrowUp" &&
						event.key !== "ArrowDown" &&
						(event.key !== "Enter" || !context.options)
					) {
						return;
					}

					event.preventDefault();
					event.stopPropagation();

					if (event.key === "Enter")
						context.onSelect(
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							context.options![context.hoveredOptionIndex],
						);
					else if (event.key === "ArrowUp") context.onPrevOption();
					else if (event.key === "ArrowDown") context.onNextOption();
				}}
				onBlur={context.onBlur}
			/>
		)
	);
};

declare namespace Selected {
	type Props = Select.SelectedComponentBase.Props;
}

export default Selected;
