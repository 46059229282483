import styled from "styled-components";
import Select from "../..";
import theme from "../../../../styles/theme";

const Text = styled.div<Text.Props>`
	width: 0;
	height: 18px;

	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;

	color: ${({ disabled, selected }) =>
		// eslint-disable-next-line no-nested-ternary
		disabled
			? theme.colors.disabled_text
			: selected
			? theme.colors.primary
			: theme.colors.text_hovered_placeholder};

	${theme.longWord}
`;

declare namespace Text {
	type Props = Select.SelectedComponentBase.Props;
}

export default Text;
