import styled from "styled-components"
import theme from "../../../../../../styles/theme"

const Item = styled.div<Item.Props>`
    min-width: max-content;

    padding: 5px 10px;
    
    border-radius: 5px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;

    background: ${
        ({selected}) =>
            selected
                ? theme.colors.white
                : theme.colors.transparent
    };

    color: ${
        ({selected}) =>
            selected
                ? theme.colors.accent
                : theme.colors.secondary_tab_text
    };

    user-select: none;

    &:hover {
        background: ${
            ({selected}) =>
                selected
                    ? theme.colors.white
                    : theme.colors.color_border_basic
        };

        color: ${
            ({selected}) =>
                selected
                    ? theme.colors.accent
                    : theme.colors.primary
        };
    }
`

declare namespace Item {
    export interface Props {
        selected: boolean
    }
}

export default Item
