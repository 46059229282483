import { RegisteredIcon } from "@ioc:uikit/icon";
import React, { ReactNode } from "react";
import ViewBox from "../../../../types/ViewBox";

const registered: Record<RegisteredIcon, Provider.Data> = {} as Record<
	RegisteredIcon,
	Provider.Data
>;

function Provider() {
	return (
		<svg display="none">
			<defs>
				{Object.entries(registered).map(([name, data]) => (
					<g key={name} id={name}>
						{data.node}
					</g>
				))}
			</defs>
		</svg>
	);
}

namespace Provider {
	export interface Props {}

	export interface Data {
		viewBox: ViewBox;
		node: ReactNode;
	}

	export function register(name: RegisteredIcon, data: Data) {
		registered[name] = data;
	}

	export function has(name: RegisteredIcon) {
		return !!registered[name];
	}

	export function get(name: RegisteredIcon): Data | undefined {
		return registered[name];
	}
}

export default Provider;
