import styled from "styled-components";

export interface Props {
  x: number
  y: number
  z: number
  
  width: number
  height: number
}

export const Root = styled.div.attrs<Props, Props>(({ x, y, z, width, height }) => ({
  style: {
    left: `${x}px`,
    top: `${y}px`,

    width: `${width}px`,
    height: `${height}px`,
  },

  x,
  y,
  z,

  width,
  height,
}))`
  position: absolute;

  z-index: ${({ z }) => z};

  & > * {
    width: 100%;
    height: 100%;
  }
`;
