import styled from "styled-components";
import Input from "../Input";
import Circle from "../Circle";
import theme from "../../../../styles/theme";

const inputSelector = `.${Input.styledComponentId}`;
const circleSelector = `.${Circle.styledComponentId}`;

const Root = styled.label`
	display: block;

	position: relative;

	min-height: 16px;

	padding-left: 26px;

	color: ${theme.colors.primary};

	user-select: none;

	cursor: pointer;

	:has(> ${inputSelector}:checked) ${circleSelector} {
		border-color: ${theme.colors.accent};
		border-width: 6px;
	}

	:has(> ${inputSelector}:disabled) {
		color: #647079;

		${circleSelector} {
			background: #e9e9e9;

			border: 1px solid #dee0e2;
		}
	}

	:has(> ${inputSelector}:disabled:checked) ${circleSelector} {
		background: #9ba3a8;

		border-color: #e9e9e9;
		border-width: 5px;
	}

	:has(> ${inputSelector}:focus:not(:checked)) ${circleSelector} {
		border: none;
		outline: 1px solid ${theme.colors.primary};
	}

	:has(> ${inputSelector}:focus) ${circleSelector} {
		outline: 1px solid ${theme.colors.primary};
	}
`;

export default Root;
