/* eslint-disable func-names */

import Leaflet from "leaflet";
import { clone } from "lodash";
import { CallbackContext } from "../type";
import { latLngToCoordinates } from "../utils";
import { ContextedFunction } from "../../../../../hooks/useContextedFunction";

const onEditVertex: ContextedFunction<
	CallbackContext,
	[Leaflet.LeafletEvent],
	void
> = function (event) {
	const drawEvent = event as Leaflet.DrawEvents.EditVertex;

	this.setInternalPolygons((polygons) => {
		const newPolygons = clone(polygons);
		const newPolygonIndex = newPolygons.findIndex(
			(polygon) => this.selected === polygon.id,
		);

		if (newPolygonIndex === -1) return polygons;

		newPolygons[newPolygonIndex] = {
			...newPolygons[newPolygonIndex],

			vertices: (drawEvent.poly as any)._latlngs[0].map(
				latLngToCoordinates,
			),
		};

		return newPolygons;
	});
};

export default onEditVertex;
