import React, { FC, useCallback, useContext, useMemo } from "react";
import MultiSelect from "../..";
import Select from "../../../Select";
import Row from "../../../Row";
import CheckBox from "../../../CheckBox";

const Option: FC<Option.Props> = ({ children, option, ...props }) => {
	const context = useContext(MultiSelect.Context);

	const rootOnMouseDown = useCallback((event: React.MouseEvent) => {
		event.preventDefault();
		event.stopPropagation();
	}, []);

	const selected = useMemo(
		() =>
			(context?.all &&
				context?.areAllOptionsSelected &&
				option.key === "all") ||
			(context?.value?.includes(option.key) ?? false),
		[context?.value, option.key],
	);

	const checkBoxOnChange = useCallback(
		() => context?.onClick(option),
		[context?.onClick, option],
	);

	return (
		context && (
			<Select.OptionList.Item.Root
				{...props}
				selected={false}
				option={option}
				onMouseDown={rootOnMouseDown}
			>
				<Row gaps="10px" align="center">
					<CheckBox value={selected} onChange={checkBoxOnChange} />
					{children}
				</Row>
			</Select.OptionList.Item.Root>
		)
	);
};

declare namespace Option {
	type Props = Select.OptionComponentBase.Props;
}

export default Option;
