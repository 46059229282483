import React from "react";

export default (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1908_12461)">
      <path d="M12.0001 0.148193C9.65598 0.148193 7.36454 0.843292 5.41551 2.14559C3.46649 3.44789 1.94741 5.2989 1.05037 7.46454C0.153329 9.63018 -0.0813776 12.0132 0.375929 14.3122C0.833235 16.6113 1.96201 18.7231 3.61953 20.3806C5.27704 22.0381 7.38884 23.1669 9.68787 23.6242C11.9869 24.0815 14.3699 23.8468 16.5356 22.9497C18.7012 22.0527 20.5522 20.5336 21.8545 18.5846C23.1568 16.6356 23.8519 14.3441 23.8519 12C23.8519 8.85674 22.6032 5.84218 20.3806 3.61952C18.1579 1.39687 15.1434 0.148193 12.0001 0.148193ZM12.0001 22.3704C9.94899 22.3704 7.94398 21.7622 6.23858 20.6227C4.53318 19.4832 3.20399 17.8636 2.41908 15.9686C1.63417 14.0737 1.4288 11.9885 1.82894 9.97689C2.22909 7.96523 3.21677 6.11741 4.66709 4.66709C6.11741 3.21676 7.96524 2.22908 9.97689 1.82894C11.9885 1.4288 14.0737 1.63416 15.9686 2.41907C17.8636 3.20398 19.4832 4.53318 20.6227 6.23858C21.7622 7.94398 22.3704 9.94898 22.3704 12C22.3704 14.7504 21.2778 17.3882 19.333 19.333C17.3882 21.2778 14.7504 22.3704 12.0001 22.3704Z" fill="#4CAF50" />
      <path d="M19.4072 7.62959C19.2685 7.49162 19.0807 7.41418 18.885 7.41418C18.6893 7.41418 18.5016 7.49162 18.3628 7.62959L10.1406 15.8148L5.69612 11.3703C5.56057 11.224 5.37242 11.1375 5.17308 11.1298C4.97373 11.1222 4.77952 11.194 4.63316 11.3296C4.4868 11.4651 4.40028 11.6533 4.39264 11.8526C4.385 12.052 4.45686 12.2462 4.59242 12.3926L10.1406 17.9259L19.4072 8.68144C19.4767 8.61258 19.5318 8.53065 19.5694 8.44038C19.607 8.35012 19.6263 8.2533 19.6263 8.15551C19.6263 8.05773 19.607 7.96091 19.5694 7.87064C19.5318 7.78038 19.4767 7.69845 19.4072 7.62959Z" fill="#4CAF50" />
    </g>
    <defs>
      <clipPath id="clip0_1908_12461">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
