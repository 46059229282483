import React, { Dispatch } from "react";

import StyleProps from "../../types/StyleProps";
import Key from "../../types/Key";
import { inputify } from "../../utils/react";

import MonoBlock from "./components/Monoblock";
import Vertical from "./components/Vertical";
import Panels from "./components/Panels";
import Transparent from "./components/Transparent";
import Bookmarks from "./components/Bookmarks";

const variants = {
	bookmarks: Bookmarks,
	panels: Panels,
	monoblock: MonoBlock,
	transparent: Transparent,
	vertical: Vertical,
};

type Variant = keyof typeof variants;

const Tabs = inputify<Tabs.Props, Key>(({ variant, ...props }) => {
	// eslint-disable-next-line no-shadow
	const Tabs = variants[variant];

	return Tabs && <Tabs {...props} />;
});

declare namespace Tabs {
	export interface Option<T> {
		key: Key;
		label: React.ReactNode;
		disabled?: boolean;
		value: T;
	}

	export interface Props extends StyleProps {
		options: Option<any>[];
		variant: Variant;

		onChangeSelectedOption?: Dispatch<Option<any>>;
	}
}

export default Tabs;
