export function componentPropToCSSProp(map: Record<string, string | string[]>) {
  return (props) =>
    Object.entries(map)
      .map(
        ([componentProp, cssProp]) =>
          props[componentProp]
            ? Array.isArray(cssProp)
              ? cssProp
                .map(cssProp => `${cssProp}: ${props[componentProp]};`)
                .join("")
              : `${cssProp}: ${props[componentProp]};`
            : ''
      )
      .join("");
}
