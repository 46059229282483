import React, { forwardRef, PropsWithChildren, RefAttributes } from "react";
import styled from "styled-components";
import theme from "../../styles/theme";
import StyleProps from "../../types/StyleProps";
import { inputify } from "../../utils/react";
import Column from "../Column";

interface RootProps extends PropsWithChildren {
	hovered: boolean;
	focused: boolean;
	error: string | boolean;
	disabled: boolean;
	hasBorders: boolean;
	hasPaddings: boolean;
	nested?: "first" | "middle" | "last";
}

const Root = styled.div<RootProps>`
	height: 100%;

	${({ hasPaddings }) => (hasPaddings ? "padding: 6px 12px;" : "")}

	background-color: ${({ disabled }) =>
		disabled ? theme.colors.disabled_filled : theme.colors.white};

	border: ${({ hasBorders }) => (hasBorders ? "1px solid" : "none")};
	border-radius: 5px;
	border-color: ${({ hovered, focused, error, disabled }) => {
		// eslint-disable-next-line no-unreachable-loop, no-constant-condition
		while (true) {
			if (disabled) break;

			if (error) return theme.colors.error;

			if (focused) return theme.colors.accent;

			if (hovered) return theme.colors.primary;

			break;
		}

		return theme.colors.color_border_basic;
	}};

	${({ nested }) => {
		switch (nested) {
			case "first":
				return `
					border-right: none;

					border-top-right-radius: 0;
					border-bottom-right-radius: 0;

					padding-right: 6px;
				`;
			case "last":
				return `
					border-left: none;

					border-top-left-radius: 0;
					border-bottom-left-radius: 0;

					padding-left: 6px;
				`;
			case "middle":
				return `
					border-left: none;
					border-right: none;

					border-radius: 0;

					padding-left: 6px;
					padding-right: 6px;
				`;
			default:
				return "";
		}
	}}

	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

	& &:not(:first-child:last-child) {
	}
`;

const Error = styled.div`
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;

	color: #ed4c5c;
`;

export interface ControlProps {
	hasBorders?: boolean;
	hasPaddings?: boolean;
	nested?: "first" | "middle" | "last";
}

export interface Props
	extends PropsWithChildren,
		ControlProps,
		StyleProps<typeof Column> {
	onClick?: React.MouseEventHandler<HTMLDivElement>;
	onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
}

export const InputBorders = inputify<Props & RefAttributes<HTMLDivElement>>(
	forwardRef(
		(
			{
				children,
				onEnter,
				onLeave,
				hasBorders = true,
				hasPaddings = true,
				nested,

				style,
				className,

				onClick,
				onMouseDown,

				...props
			},
			ref,
		) => (
			<Column
				ref={ref}
				gaps="3px"
				style={style}
				className={className}
				onClick={onClick}
				onMouseDown={onMouseDown}
			>
				<Root
					{...props}
					hasBorders={hasBorders}
					hasPaddings={hasPaddings}
					onMouseEnter={onEnter}
					onMouseLeave={onLeave}
					nested={nested}
				>
					{children}
				</Root>
				{typeof props.error === "string" &&
				props.error &&
				!props.disabled ? (
					<Error>{props.error}</Error>
				) : undefined}
			</Column>
		),
	),
);

export const classes = {
	borders: Root.styledComponentId,
};
