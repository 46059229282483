import styled from "styled-components";
import theme from "../../../../../../styles/theme";
import Flex from "../../../../../Flex";

const Item = styled(Flex)<Item.Props>`
	/* min-width: max-content; */
	width: 240px;
	height: 40px;

	padding: 5px 10px;

	border-radius: 5px;

	font-family: "Lato";
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 18px;

	box-shadow: ${({ selected }) =>
		selected ? `0px 1px 3px rgba(8, 35, 48, 0.1)` : "none"};

	background: ${({ selected }) =>
		selected ? theme.colors.white : theme.colors.transparent};

	color: ${({ selected, disabled }) => {
		if (disabled) {
			return theme.colors.disabled_text;
		}

		return selected ? theme.colors.accent : theme.colors.secondary_tab_text;
	}};

	user-select: none;
	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

	&:hover {
		background: ${({ selected }) =>
			selected ? theme.colors.white : theme.colors.color_border_basic};

		color: ${({ selected, disabled }) => {
			if (disabled) {
				return theme.colors.disabled_text;
			}

			return selected ? theme.colors.accent : theme.colors.primary;
		}};
	}
`;

declare namespace Item {
	export interface Props {
		disabled?: boolean;
		selected: boolean;
	}
}

export default Item;
