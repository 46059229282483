import React, { Children, PropsWithChildren, ReactElement, useRef } from 'react';
import useRender from '../../hooks/useRender';
import { processSizes } from '../../utils/flex';
import { inputify } from '../../utils/react';
import { uniqClass as inputBorderUniqClass } from '../InputBorder';
import { InputBorders, ControlProps as InputBordersControlProps } from '../InputBorders';
import Row from '../Row';

export interface Props extends PropsWithChildren, InputBordersControlProps, Pick<Row.Props, "sizes"> { }

export const InputGroup = inputify<Props>(({
  children,
  sizes,
  ...props
}) => {
  const hoveredStates = useRef<Record<number, boolean>>({});
  const focusedStates = useRef<Record<number, boolean>>({});

  const render = useRender();

  const hovered = props.hovered || Object.values(hoveredStates.current).includes(true);
  const focused = props.focused || Object.values(focusedStates.current).includes(true);

  const childCount = Children.count(children);

  return (
    <InputBorders
      {...props}
      hovered={hovered}
      focused={focused}
      hasBorders={false}
      hasPaddings={false}
    >
      <Row
        align="stretch"
        sizes={sizes ? (child) => {
          // TODO: Optimize.
          const parent = child.parentElement;
          const children = Array.from(parent?.children ?? [])
            .filter((child) => !child.classList.contains(inputBorderUniqClass));
          const processedSizes = typeof sizes === "string"
            ? processSizes(sizes, children.length)
            : children.map(sizes);

          return processedSizes[children.indexOf(child)] ?? {
            type: "string",
            value: "1px",
            strict: false,
          };
        } : ""}
      >
        {
          Children.map(children, (child) => child)
            ?.filter(Boolean)
            .map((child, index) => {
              if (!child || typeof child !== "object")
                return child

              return React.cloneElement(child as ReactElement, {
                hovered,
                focused,
                error: props.error,
                disabled: props.disabled,
                nested:
                  childCount === 1 ? undefined :
                    index === 0 ? "first" :
                      index == childCount - 1 ? "last" :
                        "middle",
                onEnter: () => {
                  hoveredStates.current[index] = true;

                  render(true);
                },
                onLeave: () => {
                  hoveredStates.current[index] = false;

                  render(true);
                },
                onFocus: () => {
                  focusedStates.current[index] = true;

                  render(true);
                },
                onBlur: () => {
                  focusedStates.current[index] = false;

                  render(true);
                },
              })
            })
        }
      </Row>
    </InputBorders>
  );
})
