import styled, { css } from "styled-components";
import theme from "../../../../styles/theme";

const Content = styled.div<Content.Props>`
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;

	${({ maxWidth }) =>
		maxWidth
			? css`
					width: 100%;
			  `
			: ""}

	color: ${({ active }) =>
		active ? theme.colors.primary : theme.colors.secondary};

	${({ disabled }) => disabled && `filter: grayscale(1) brightness(1.2);`}

	user-select: none;
`;

declare namespace Content {
	export interface Props {
		active: boolean;
		disabled: boolean;
		maxWidth: boolean;
	}
}

export default Content;
