/* eslint-disable func-names */

import Leaflet from "leaflet";
import { clone } from "lodash";
import { CallbackContext } from "../type";
import { latLngToCoordinates } from "../utils";
import { ContextedFunction } from "../../../../../hooks/useContextedFunction";

const onCreated: ContextedFunction<
	CallbackContext,
	[Leaflet.LeafletEvent],
	void
> = function (event) {
	const drawEvent = event as Leaflet.DrawEvents.Created;

	this.setInternalPolygons((polygons) => {
		const newPolygons = clone(polygons);
		const newPolygonIndex = newPolygons.findIndex(
			(polygon) => this.selected === polygon.id,
		);

		if (newPolygonIndex === -1) return polygons;

		const newPolygon = clone(newPolygons[newPolygonIndex]);

		newPolygon.vertices = (drawEvent.layer as any)._latlngs[0].map(
			latLngToCoordinates,
		);

		delete newPolygon.creating;

		newPolygons[newPolygonIndex] = newPolygon;

		return newPolygons;
	});
};

export default onCreated;
