import { extend, noop } from "lodash"
import React, { FC } from "react"
import Select from "../../../.."
import Root from "./components/Root"

const Item = extend(
    (({ selected, onClick, ...props }) => (
        <Root
            {...props}
    
            selected={selected}
    
            onClick={selected ? noop : onClick}
        />
    )) as FC<Item.Props>,
    {
        Root,
    }
)

declare namespace Item {
    type Props = Select.OptionComponentBase.Props 
}

declare namespace Item.Root {
    type Props = Select.OptionComponentBase.Props
}

export default Item
