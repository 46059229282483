/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import React, {
	ComponentType,
	Dispatch,
	HTMLAttributes,
	PropsWithChildren,
	ReactElement,
	ReactNode,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
} from "react";
import { ClickAwayListener } from "@mui/material";
import { extend, isUndefined } from "lodash";
import {
	inputify,
	InputifiedComponentProps,
	InputifyComponentProps,
} from "../../utils/react";
import OptionType from "../../types/Option";
import Key from "../../types/Key";
import { Props as InputBordersProps } from "../InputBorders";
import Overlayer from "../Overlayer";
import Row from "../Row";
import useInternal from "../../hooks/useInternal";
import Column from "../Column";

import Root from "./components/Root";
import OverlayBorders from "./components/OverlayBorders";
import InternalOptionList from "./components/OptionList";
import MainBorders from "./components/MainBorders";
import InternalText from "./components/Text";
import ArrowContainer from "./components/ArrowContainer";
import Icon from "../Icon";

const Select = extend(
	inputify(
		// eslint-disable-next-line comma-spacing
		<OptionValue,>({
			style,
			className,

			value,

			hovered,
			focused,
			error,
			disabled,

			hasBorders,
			hasPaddings,
			nested,

			open,
			autoFocus = false,
			options,
			placeholder,
			direction = "down",
			arrow = true,
			closeOnSelect = true,

			Option = InternalOptionList.Item,
			Selected = InternalText,

			onChange,
			onSelect,

			onEnter,
			onLeave,
			onFocus,
			onBlur,
		}: Select.InternalProps<OptionValue>) => {
			const [internalOpen, setInternalOpen] = useInternal(open ?? false, {
				when: () => isUndefined(open),
			});

			const toggleOpen = useCallback(
				(event: React.MouseEvent) => {
					event.preventDefault();
					event.stopPropagation();

					setInternalOpen((value) => {
						value = !value;

						if (value) onFocus();
						else onBlur();

						return value;
					});
				},
				[onBlur, onFocus, setInternalOpen],
			);

			const makeClose = useCallback(() => {
				setInternalOpen(false);
				onLeave();
				onBlur();
			}, [onBlur, onLeave, setInternalOpen]);

			const mainInputBordersControlProps = useMemo(
				() => ({
					hasBorders,
					hasPaddings,
					nested,

					direction,

					hovered,
					focused,
					error,
					disabled,
				}),
				[
					hasBorders,
					hasPaddings,
					nested,
					direction,
					hovered,
					focused,
					error,
					disabled,
				],
			);

			const overlayInputBordersControlProps = useMemo(
				() => ({
					...mainInputBordersControlProps,

					hasPaddings: false,

					nested: undefined,

					direction,
				}),
				[mainInputBordersControlProps, direction],
			);

			const selectedOption = useMemo(
				() => options?.find((option) => option.key === value),
				[options, value],
			);

			useLayoutEffect(() => {
				if (disabled) makeClose();
			}, [disabled, makeClose]);

			useLayoutEffect(() => {
				if (!focused) makeClose();
			}, [focused, makeClose]);

			const rootRef = useRef<HTMLInputElement | null>(null);

			const focus = useCallback(() => rootRef.current?.focus(), []);

			useEffect(() => {
				if (autoFocus) {
					process.nextTick(focus);
				}
			}, [autoFocus, focus]);

			return (
				<ClickAwayListener onClickAway={makeClose}>
					<Root
						ref={rootRef}
						style={style}
						className={className}
						disabled={disabled}
						tabIndex={0}
						onClick={toggleOpen}
						onMouseEnter={onEnter}
						onMouseLeave={onLeave}
						onFocus={onFocus}
						onBlur={onBlur}
					>
						<Overlayer
							style={{ height: "100%" }}
							overlay={
								internalOpen &&
								!disabled && (
									<OverlayBorders
										{...overlayInputBordersControlProps}
										onEnter={onEnter}
										onLeave={onLeave}
									>
										<InternalOptionList
											direction={direction}
										>
											<Column sizes="auto!*">
												{options?.map((option) => (
													<Option
														key={option.key}
														selected={
															option.key === value
														}
														option={option}
														onClick={(event) => {
															event.stopPropagation();

															if (closeOnSelect)
																makeClose();

															onChange(
																option.key,
															);
															onSelect?.(option);
														}}
													>
														{option.label}
													</Option>
												))}
											</Column>
										</InternalOptionList>
									</OverlayBorders>
								)
							}
						>
							<MainBorders
								{...mainInputBordersControlProps}
								open={!disabled && internalOpen}
								onEnter={onEnter}
								onLeave={onLeave}
							>
								<Row
									sizes="1fr auto!"
									gaps="10px*"
									align="center"
								>
									<Selected
										disabled={disabled}
										selected={!!selectedOption}
									>
										{selectedOption
											? selectedOption.label ?? ""
											: placeholder ?? ""}
									</Selected>
									{arrow && (
										<ArrowContainer>
											<Icon
												id="down-arrow"
												size={[8, 5]}
											></Icon>
										</ArrowContainer>
									)}
								</Row>
							</MainBorders>
						</Overlayer>
					</Root>
				</ClickAwayListener>
			);
		},
	) as <OptionValue>(props: Select.Props<OptionValue>) => ReactElement | null,
	{
		OptionList: InternalOptionList,
		Text: InternalText,
	},
);

declare namespace Select {
	type Direction = "up" | "down";

	type Value = Key;

	type OptionComponentBase = ComponentType<OptionComponentBase.Props>;

	namespace OptionComponentBase {
		interface Props extends Pick<HTMLAttributes<HTMLElement>, "onClick"> {
			children?: ReactNode;

			selected: boolean;
			hovered?: boolean;

			option: OptionType<unknown>;
		}
	}

	type SelectedComponentBase = ComponentType<SelectedComponentBase.Props>;

	namespace SelectedComponentBase {
		interface Props extends PropsWithChildren {
			disabled: boolean;
			selected: boolean;
		}
	}

	interface PropsBase<OptionValue>
		extends Omit<InputBordersProps, "children"> {
		open?: boolean;
		autoFocus?: boolean;
		options?: OptionType<OptionValue>[];
		placeholder?: string;
		direction?: Direction;
		arrow?: boolean;
		closeOnSelect?: boolean;

		Option?: OptionComponentBase;
		Selected?: SelectedComponentBase;

		onSelect?: Dispatch<OptionType<OptionValue>>;
	}

	type InternalProps<OptionValue> = PropsBase<OptionValue> &
		InputifyComponentProps<Value>;

	type Props<OptionValue> = PropsBase<OptionValue> &
		InputifiedComponentProps<Value>;

	namespace OptionList {
		type Props = InternalOptionList.Props;

		namespace Item {
			type Props = InternalOptionList.Item.Props;

			namespace Root {
				type props = InternalOptionList.Item.Root.Props;
			}
		}
	}

	namespace Text {
		type Props = InternalText.Props;
	}
}

export default Select;
