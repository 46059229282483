import React, { forwardRef } from "react";
import Flex from "../Flex";

const Row = forwardRef<HTMLDivElement, Row.Props>(
	({ children, wrap = "nowrap", ...props }, ref) => (
		<Flex ref={ref} dir="row" wrap={wrap} {...props}>
			{children}
		</Flex>
	),
);

declare namespace Row {
	type Props = Omit<Flex.Props, "dir">;
}

export default Row;
