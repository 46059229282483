

export function emit<Event extends (...args: any[]) => any>(events: Event | Event[] | undefined, ...args: Parameters<Event>) {
  if (!events)
    return
  
  events = Array.isArray(events) ? events : [events]

  events.forEach(event => {
    event(...args)
  })
}
