
export const keyGroups = {
	Alt: ["AltLeft", "AltRight"],
	Shift: ["ShiftLeft", "ShiftRight"],
	Control: ["ControlLeft", "ControlRight"],
}

export const keyNames = {
	Alt: "Alt",
	AltLeft: "Alt",
	AltRight: "Alt",
	ArrowDown: "ArrowDown",
	ArrowLeft: "ArrowLeft",
	ArrowRight: "ArrowRight",
	ArrowUp: "ArrowUp",
	Backquote: "`",
	Backslash: "\\",
	Backspace: "Backspace",
	BracketLeft: "[",
	BracketRight: "]",
	CapsLock: "CapsLock",
	Comma: ",",
	Control: "Ctrl",
	ControlLeft: "Ctrl",
	ControlRight: "Ctrl",
	Delete: "Delete",
	Digit0: "0",
	Digit1: "1",
	Digit2: "2",
	Digit3: "3",
	Digit4: "4",
	Digit5: "5",
	Digit6: "6",
	Digit7: "7",
	Digit8: "8",
	Digit9: "9",
	Minus: "-",
	Equal: "=",
	End: "End",
	Enter: "Enter",
	Escape: "Escape",
	Home: "Home",
	Insert: "Insert",
	IntlBackslash: "IntlBackslash",
	IntlRo: "IntlRo",
	IntlYen: "IntlYen",
	F1: "F1",
	F2: "F2",
	F3: "F3",
	F4: "F4",
	F5: "F5",
	F6: "F6",
	F7: "F7",
	F8: "F8",
	F9: "F9",
	F10: "F10",
	F11: "F11",
	F12: "F12",
	F13: "F13",
	F14: "F14",
	F15: "F15",
	F16: "F16",
	F17: "F17",
	F18: "F18",
	F19: "F19",
	F20: "F20",
	F21: "F21",
	F22: "F22",
	F23: "F23",
	F24: "F24",
	KeyA: "A",
	KeyB: "B",
	KeyC: "C",
	KeyD: "D",
	KeyE: "E",
	KeyF: "F",
	KeyG: "G",
	KeyH: "H",
	KeyI: "I",
	KeyJ: "J",
	KeyK: "K",
	KeyL: "L",
	KeyM: "M",
	KeyN: "N",
	KeyO: "O",
	KeyP: "P",
	KeyQ: "Q",
	KeyR: "R",
	KeyS: "S",
	KeyT: "T",
	KeyU: "U",
	KeyV: "V",
	KeyW: "W",
	KeyX: "X",
	KeyY: "Y",
	KeyZ: "Z",
	Numpad0: "Numpad 0",
	Numpad1: "Numpad 1",
	Numpad2: "Numpad 2",
	Numpad3: "Numpad 3",
	Numpad4: "Numpad 4",
	Numpad5: "Numpad 5",
	Numpad6: "Numpad 6",
	Numpad7: "Numpad 7",
	Numpad8: "Numpad 8",
	Numpad9: "Numpad 9",
	NumpadAdd: "+",
	NumpadSubtract: "-",
	NumpadMultiply: "*",
	NumpadDivide: "/",
	NumpadEqual: "=",
	NumpadDecimal: ".",
	NumpadComma: ",",
	NumpadEnter: "Enter",
	PageUp: "PageUp",
	PageDown: "PageDown",
	Pause: "Pause",
	Period: ".",
	Quote: '"',
	ScrollLock: "ScrollLock",
	Semicolon: ";",
	Shift: "Shift",
	ShiftLeft: "Shift",
	ShiftRight: "Shift",
	Slash: "/",
	Space: "Space",
	Tab: "Tab",
};

export type KeyName = keyof typeof keyNames | keyof typeof keyGroups | string & {}
export type Keybind = KeyName[]
