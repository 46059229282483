import styled from "styled-components";

const Input = styled.input`
	position: absolute;

	top: 0;
	left: 0;

	width: 0px;
	height: 0px;

	opacity: 0;

	cursor: pointer;
`;

export default Input;
