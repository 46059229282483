import { useCallback, useRef } from "react";

export type ContextedFunction<
	ContextType = any,
	Parameters extends any[] = any[],
	ReturnType = any,
> = (this: ContextType, ...args: Parameters) => ReturnType;
export type ContextSetter<ContextType = any> = (context: ContextType) => void;

export default function useContextedFunction<
	ContextType,
	Parameters extends any[],
	ReturnType,
>(
	callback: ContextedFunction<ContextType, Parameters, ReturnType>,
	defaultContext: ContextType,
) {
	const contextRef = useRef<ContextType>(defaultContext);

	const contextedFunction = useCallback(
		(...args: Parameters) => callback.apply(contextRef.current, args),
		[],
	);

	return [
		contextedFunction,
		useCallback((context: ContextType) => {
			contextRef.current = context;
		}, []),
	] as [typeof contextedFunction, ContextSetter<ContextType>];
}
