/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-multi-assign */

import { defaults, isEqual } from "lodash";
import { useRef, useCallback, Dispatch, SetStateAction } from "react";
import useRender from "./useRender";

function compare(value1: unknown, value2: unknown, deep = false) {
	return deep ? isEqual(value1, value2) : value1 === value2;
}

interface Options {
	deep?: boolean;
	when?: () => boolean;
}

export default function useInternal<T>(value: T, options: Options = {}) {
	const render = useRender();

	const internalValue = useRef(value);
	const originalValue = useRef(value);

	const { deep, when } = defaults(options, { deep: true });

	const setInternalValue = useCallback(
		(value: SetStateAction<T>) => {
			if (typeof value === "function")
				value = (value as (prevState: T) => T)(internalValue.current);

			if (compare(internalValue.current, value, deep)) return;

			internalValue.current = value;

			render(true);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[internalValue, render],
	);

	const use = when?.() ?? true;

	if (!compare(originalValue.current, value, deep))
		internalValue.current = originalValue.current = value;

	return [use ? internalValue.current : value, setInternalValue] as [
		T,
		Dispatch<SetStateAction<T>>,
	];
}
