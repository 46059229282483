import { Dispatch, MutableRefObject, useCallback, useRef } from "react";
import useRender from "./useRender";

export default function useReactiveRef<T>(initialValue: T): [MutableRefObject<T>, Dispatch<T>] {
  const render = useRender()
  const ref = useRef(initialValue)
  
  const setter = useCallback(
    (value: T) => {
      ref.current = value

      render()
    },
    [render],
  )

  return [ref, setter]
}
