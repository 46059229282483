/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import { defaults, isUndefined } from "lodash";
import React, {
	ComponentClass,
	createContext,
	FC,
	ForwardedRef,
	forwardRef,
	PropsWithChildren,
	ReactElement,
	ReactNode,
	useCallback,
	useContext,
	useImperativeHandle,
	useMemo,
} from "react";
import useInternal from "../hooks/useInternal";
import { ConstructorOf } from "../types/ConstructorOf";

const DynamicType = Symbol("DynamicType");

type IsSymbolType<Type, SymbolType> =
	// IDK why, but it works (anti boolean divider into true | false)
	[Type] extends [never] ? false : [Type] extends [SymbolType] ? true : false;

export type DynamicType = typeof DynamicType;

export type Replace<Type, From, To> = Type extends From
	? To
	: Type extends object
	? {
			[P in keyof Type]: Replace<Type[P], From, To>;
	  }
	: Type;

export type InputifyComponentWithStaticValueType<
	Props extends object,
	ValueType,
> = FC<Props & InputifyComponentProps<ValueType>>;

export interface InputifyComponentWithDynamicValueType<Props extends object>
	extends Pick<FC<Props>, keyof FC<Props>> {
	<ValueType>(
		props: Replace<Props, DynamicType, ValueType> &
			InputifyComponentProps<ValueType>,
		context?: any,
	): ReactElement<any, any> | null;
}

export type InputifiedComponentWithStaticValueType<
	Props extends object,
	ValueType,
> = FC<Props & InputifiedComponentProps<ValueType>>;

export interface InputifiedComponentWithDynamicValueType<Props extends object>
	extends Pick<FC<Props>, keyof FC<Props>> {
	<ValueType>(
		props: Replace<Props, DynamicType, ValueType> &
			InputifiedComponentProps<ValueType>,
		context?: any,
	): ReactElement<any, any> | null;
}

export interface InputifiedControlProps {
	hovered: boolean;
	focused: boolean;
	error: string | boolean;
	disabled: boolean;
}

export type InputifiedVoidEvent = () => void;
export type InputifiedValueEvent<Type = unknown> = (value: Type) => void;

export interface InputifiedPropEvents<ValueType = unknown> {
	onChange: [ValueType] extends [never]
		? never
		: InputifiedValueEvent<ValueType>;
	onEnter: InputifiedVoidEvent;
	onLeave: InputifiedVoidEvent;
	onFocus: InputifiedVoidEvent;
	onBlur: InputifiedVoidEvent;
}

export interface InputifiedValueProps<ValueType = unknown> {
	value?: ValueType;
}

export type InputProps<ValueType = unknown> = InputifiedControlProps &
	InputifiedPropEvents<ValueType>;
export type InputifyComponentProps<ValueType = unknown> = Required<
	InputProps<ValueType>
> &
	InputifiedValueProps<ValueType>;
export type InputifiedComponentProps<ValueType = unknown> = Partial<
	InputProps<ValueType>
> &
	InputifiedValueProps<ValueType>;

const DynamicValueTypeSymbol = Symbol("DynamicValueType");

export type DynamicValueType = { [DynamicValueTypeSymbol]: boolean };

export type InputifyComponent<Props extends object, ValueType> = IsSymbolType<
	ValueType,
	DynamicType
> extends false
	? InputifyComponentWithStaticValueType<Props, ValueType>
	: InputifyComponentWithDynamicValueType<Props>;

export type InputifiedComponent<Props extends object, ValueType> = IsSymbolType<
	ValueType,
	DynamicType
> extends false
	? InputifiedComponentWithStaticValueType<Props, ValueType>
	: InputifiedComponentWithDynamicValueType<Props>;

export function inputify<Props extends object, ValueType = unknown>(
	Component: InputifyComponent<Props, ValueType>,
): InputifiedComponent<Props, ValueType> {
	function InputifiedComponent(
		props: Props & InputifiedComponentProps<ValueType>,
		ref: any,
	) {
		const [value, setValue] = useInternal(props.value, {
			when: () => isUndefined(props.value),
		});
		const [hovered, setHover] = useInternal(props.hovered ?? false, {
			when: () => isUndefined(props.hovered),
		});
		const [focused, setFocus] = useInternal(props.focused ?? false, {
			when: () => isUndefined(props.focused),
		});

		const processedProps = defaults(
			{ ref, ...props },
			{
				error: "",
				disabled: false,
			},
		);

		const events = {
			onChange: useCallback(
				(value: ValueType) => {
					setValue(value);

					processedProps.onChange?.(value);
				},
				// eslint-disable-next-line react-hooks/exhaustive-deps
				[setValue, processedProps.onChange],
			),

			onEnter: useCallback(() => {
				setHover(true);

				processedProps.onEnter?.();
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [setHover, processedProps.onEnter]),

			onLeave: useCallback(() => {
				setHover(false);

				processedProps.onLeave?.();
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [setHover, processedProps.onLeave]),

			onFocus: useCallback(() => {
				setFocus(true);

				processedProps.onFocus?.();
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [setFocus, processedProps.onFocus]),

			onBlur: useCallback(() => {
				setFocus(false);

				processedProps.onBlur?.();
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [setFocus, processedProps.onBlur]),
		};

		return (
			<Component
				{...(processedProps as any)}
				{...events}
				value={value}
				hovered={hovered}
				focused={focused}
			/>
		);
	}

	return (forwardRef as any)(InputifiedComponent);
}

export function setRefValue<T>(ref: ForwardedRef<T>, value: T) {
	if (!ref) return;

	if (typeof ref === "function") ref(value);
	else ref.current = value;
}

export type StackableContextReducer<T> = (prevValue: T, nextValue: T) => T;
export interface StackableContextProviderProps<T> extends PropsWithChildren {
	value: T;
}
export type StackableContextProvider<T> = FC<StackableContextProviderProps<T>>;

export function createStackableContext<T>(
	defaultValue: T,
	reducer: StackableContextReducer<T>,
) {
	const context = createContext<T>(defaultValue);

	const OriginalProvider = context.Provider;
	const Provider: StackableContextProvider<T> = ({
		children,

		value: nextValue,
	}) => {
		const prevValue = useContext(context);

		return (
			<OriginalProvider value={reducer(prevValue, nextValue)}>
				{children}
			</OriginalProvider>
		);
	};

	return Object.assign(context, { Provider });
}

export function renderNested(
	content: any,
	components: (FC<any> | ComponentClass<any>)[],
) {
	return components.reduce<ReactNode>(
		(content, Component) => <Component>{content}</Component>,
		content,
	);
}

export class ControllerBase<Context = any> {
	protected context: Context | null;

	constructor() {
		this.context = null;
	}

	setContext(value: Context | null) {
		this.context = value;
	}
}

export function withController<Props, ControllerType>(
	Component: FC<Props & { controller: ControllerType }>,
	Controller: ConstructorOf<ControllerType>,
) {
	return forwardRef<ControllerType | null, Props>((props, ref) => {
		const controller = useMemo(
			() => new Controller(),
			[],
		) as ControllerType;

		useImperativeHandle(ref, () => controller, [controller]);

		return <Component {...props} controller={controller} />;
	});
}
export function delay<Arguments extends any[]>(
	callback: (...args: Arguments) => void,
) {
	return (...args: Arguments) => process.nextTick(callback, ...args);
}
