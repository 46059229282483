import styled from "styled-components";

const Circle = styled.span`
	position: absolute;

	top: 50%;
	left: 0;

	box-sizing: border-box;

	width: 16px;
	height: 16px;

	border: 1px solid #d0d5dd;
	border-radius: 50%;

	transform: translateY(-50%);
`;

export default Circle;
