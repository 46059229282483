import React, {
	CSSProperties,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import theme from "../../styles/theme";
import { keyNames, Keybind } from "../../utils/keys";
import { inputify } from "../../utils/react";
import Flex from "../Flex";
import { InputBorders } from "../InputBorders";
import Row from "../Row";
import close from "../../icons/close";

const KeybindInput = inputify<KeybindInput.Props, Keybind | undefined>(
	({
		locale = {},
		value,
		onChange,
		focused,
		onFocus,
		onBlur,
		tabIndex = 0,
	}) => {
		const ref = useRef<HTMLDivElement | null>(null);
		const border = useRef<HTMLDivElement | null>(null);
		const [width, setWidth] = useState<CSSProperties["width"]>("auto");
		const [preview, setPreview] = useState<string[] | null>(null);

		useEffect(() => {
			setWidth(ref.current ? `${ref.current.clientWidth}px` : "auto");
		}, [focused]);

		const focus = useCallback(() => {
			onFocus();
		}, [onFocus]);

		const blur = useCallback(
			(target?: HTMLElement | null | undefined) => {
				target?.blur();
				border.current?.blur();
				onBlur();
				setPreview(null);
			},
			[onBlur],
		);

		return (
			<InputBorders ref={border} focused={focused} hasPaddings={false}>
				<Row
					tabIndex={tabIndex}
					justify="space-between"
					align="center"
					style={{
						width: "250px",
						height: "32px",
						paddingLeft: "10px",
						paddingRight: "5px",
						cursor: focused ? "default" : "pointer",
						userSelect: "none",
					}}
					onClick={(e) => {
						e.stopPropagation();

						if (focused)
							setImmediate(() => {
								blur(e.target as HTMLElement);
							});
						else setImmediate(focus);
					}}
					onBlur={(e) => {
						blur(e.target);
					}}
					onKeyDown={(e) => {
						e.preventDefault();
						e.stopPropagation();

						if (e.repeat) return;

						if (e.key === "Escape") {
							blur(e.target as HTMLElement);

							return;
						}

						if (
							e.key === "Shift" ||
							e.key === "Control" ||
							e.key === "Alt"
						) {
							setPreview((prev) =>
								prev ? [...prev, e.key] : [e.key],
							);
							return;
						}

						const keys: Keybind = [e.code];

						if (e.altKey) keys.unshift("Alt");

						if (e.shiftKey) keys.unshift("Shift");

						if (e.ctrlKey) keys.unshift("Control");

						onChange(keys);
						blur(e.target as HTMLElement);
					}}
					onKeyUp={(e) => {
						if (
							e.key === "Shift" ||
							e.key === "Control" ||
							e.key === "Alt"
						) {
							setPreview((prev) =>
								prev ? prev.filter((v) => v !== e.key) : prev,
							);
						}
					}}
				>
					<div
						style={{
							wordWrap: "normal",
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
							fontSize: "smaller",
						}}
					>
						{(preview && preview.length !== 0 ? preview : value)
							?.map((k) => keyNames[k] || k)
							.join("+")}
					</div>
					<Row
						justify="center"
						align="center"
						style={{
							flexShrink: 0,
							width,
							height: "24px",
							cursor: focused ? "pointer" : "inherit",
							backgroundColor: focused
								? theme.colors.button_secondary
								: theme.colors.button_secondary,
							borderRadius: "5px",
							overflow: "hidden",
							transition: `width .2s ease-out, background-color .2s ease-out`,
						}}
						onClick={(e) => {
							e.stopPropagation();

							if (focused)
								setImmediate(() => {
									onChange(undefined);
									blur(e.target as HTMLElement);
								});
							else setImmediate(focus);
						}}
					>
						<Flex
							ref={ref}
							justify="center"
							align="center"
							style={{
								width: focused ? "24px" : "auto",
								paddingInline: focused ? "0" : "10px",
							}}
						>
							{focused ? (
								close
							) : (
								<div>{locale.edit || "Edit"}</div>
							)}
						</Flex>
					</Row>
				</Row>
			</InputBorders>
		);
	},
);

declare namespace KeybindInput {
	export interface Props {
		locale?: {
			edit?: string | null;
		};
		tabIndex?: number;
	}
}

export default KeybindInput;
