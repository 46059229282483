import { css } from "styled-components";

const theme = {
	scrollbar: css`
		/* Only Chrome */
		&::-webkit-scrollbar {
			width: 7px;
			height: 7px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #c3c5c7;
			background-clip: padding-box;
			/* border: 1px solid #8ea5b5; */
		}
		&::-webkit-scrollbar-track {
			background-color: #f3f6f9;
		}
		&::-webkit-scrollbar-button {
			/* background-color: #f3f6f9; */
		}
		/* Only IE */
		@media screen and (-ms-high-contrast: active),
			(-ms-high-contrast: none) {
			scrollbar-face-color: #c3c5c7;
			scrollbar-shadow-color: #f3f6f9;
			scrollbar-highlight-color: #f3f6f9;
		}
		/* Only FireFox */
		@-moz-document url-prefix() {
			scrollbar-width: thin;
			scrollbar-color: #c3c5c7 #f3f6f9;
		}
	`,
	scrollbarWhite: css`
		/* Only Chrome */
		&::-webkit-scrollbar {
			width: 7px;
			height: 7px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #c3c5c7;
			background-clip: padding-box;
			/* border: 1px solid #8ea5b5; */
		}
		&::-webkit-scrollbar-track {
			background-color: #fff;
		}
		&::-webkit-scrollbar-button {
			/* background-color: #fff; */
		}
		/* Only IE */
		@media screen and (-ms-high-contrast: active),
			(-ms-high-contrast: none) {
			scrollbar-face-color: #c3c5c7;
			scrollbar-shadow-color: #fff;
			scrollbar-highlight-color: #fff;
		}
		/* Only FireFox */
		@-moz-document url-prefix() {
			scrollbar-width: thin;
			scrollbar-color: #c3c5c7 #fff;
		}
	`,
	font: {
		l: `Lato-Light, sans-serif`,
		n: `Lato, sans-serif`,
		m: `Lato-Medium, sans-serif`,
		b: `Lato-Bold, sans-serif`,
		bl: `Lato-Heavy, sans-serif`,
		def: `Lato, sans-serif`,
	},
	borderRadius: {
		s: "3px",
		m: "5px",
		l: "10px",
	},
	colors: {
		text: "#5C5D5D",
		white: "#FFFFFF",
		transparent: "#00000000",
		accent: "#03A9F4",
		accent_hover: "#03A1E8",
		button_secondary: "#EBEBEB",
		primary: "#21333F",
		disabled_text: "#9BA3A8",
		button_secondary_hover: "#E5E5E5",
		secondary: "#647079",
		color_border_basic: "#DEE0E2",
		negative: "#F83528",
		negative_hover: "#ED1707",
		text_placeholder: "#647079",
		text_hovered_placeholder: "#8F989E",
		background_color: "#F6F6F6",
		disabled_filled: "#E9E9E9",
		error: "#ED4C5C",
		button_transparent_pressed: "#E4E4E4",
		secondary_tab_text: "#5E6B73",

		success: "#4CAF50",
		failure: "#FF4B55",
		warning: "#FFC703",
		info: "#03A9F4",
	},
	longWord: `
			word-wrap: normal;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		`,
};

export default theme;
