import { useState, useEffect, useMemo } from "react";

export default function useMutations(element: HTMLElement | null, options: MutationObserverInit = {}) {
  const [mutations, setMutations] = useState<MutationRecord[]>([]);

  const observer = useMemo(() => new MutationObserver(setMutations), []);

  useEffect(
    () => () => {
      observer.disconnect();
    },
    []
  );

  useEffect(() => {
    if (!observer) return;

    observer.disconnect();

    if (!element) return;

    observer.observe(element, options);
  }, [
    element,
    options.subtree,
    options.childList,
    options.attributes,
    options.attributeFilter,
    options.attributeOldValue,
    options.characterData,
    options.characterDataOldValue,
  ]);

  return mutations;
}
