/* eslint-disable no-shadow */

import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import useContextedFunction, {
	ContextedFunction,
} from "./useContextedFunction";
import useInternal from "./useInternal";

export interface Size {
	width: number;
	height: number;
}

interface CallbackContext {
	element?: HTMLElement | null;
	size: Size;
	setSize: Dispatch<SetStateAction<Size>>;
}

const callback: ContextedFunction<CallbackContext> = function a() {
	if (!this.element) return;

	const { width, height } = this.element.getBoundingClientRect();

	if (this.size.width !== width || this.size.height !== height)
		this.setSize({ width, height });
};

export default function useSize(element?: HTMLElement | null) {
	const { width, height } = element?.getBoundingClientRect() ?? {
		width: 0,
		height: 0,
	};

	const [size, setSize] = useInternal({ width, height }, { deep: true });

	const callbackContext = {
		element,
		size,
		setSize,
	};

	const [contextedCallback, setCallbackContext] = useContextedFunction(
		callback,
		callbackContext,
	);

	setCallbackContext(callbackContext);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const observer = useMemo(() => new ResizeObserver(contextedCallback), []);

	useEffect(() => {
		window.addEventListener("resize", contextedCallback);

		return () => {
			window.removeEventListener("resize", contextedCallback);

			observer.disconnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		observer.disconnect();

		if (!element) {
			setSize({ width: 0, height: 0 });

			return;
		}

		const { width, height } = element.getBoundingClientRect();

		setSize({ width, height });

		observer.observe(element);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [element]);

	return size;
}
