import { extend } from "lodash";
import React, {
	CSSProperties,
	HTMLAttributes,
	PropsWithChildren,
	forwardRef,
	useImperativeHandle,
	useLayoutEffect,
	useMemo,
	useRef,
} from "react";
import Root from "./components/Root";
import { Size, processGaps, processSizes } from "../../utils/flex";
import useRender from "../../hooks/useRender";
import useMutations from "../../hooks/useMutations";
import { allEquals } from "../../utils/array";

const Flex = extend(
	forwardRef<HTMLDivElement | null, Flex.Props>(
		(
			{
				children,

				dir = "row",
				wrap = "wrap",
				align,
				justify,
				style,
				gaps = "",
				sizes = "",

				maxedWidth = false,
				maxedHeight = false,

				...props
			},
			ref,
		) => {
			const render = useRender();

			const rootRef = useRef<HTMLDivElement | null>(null);
			const root = rootRef.current;

			useMutations(root, {
				childList: true,
				subtree: true,
			});

			const sizesCount = root?.childNodes.length ?? 0;
			const gapsCount = Math.max(sizesCount - 1, 0);

			const processedSizes = useMemo(() => {
				if (typeof sizes === "string")
					return processSizes(sizes, sizesCount);

				return (
					Array(sizesCount)
						.fill(null)
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
						.map((_, index) => sizes(root?.children.item(index)!))
				);
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [sizes, sizesCount]);
			const processedGaps = useMemo(
				() => processGaps(gaps, gapsCount),
				[gaps, gapsCount],
			);
			const allProcessedGapsEquals = useMemo(
				() => allEquals(processedGaps),
				[processedGaps],
			);

			if (wrap === "wrap" && !allProcessedGapsEquals)
				console.warn(
					"You cannot use different gaps when 'wrap' property equals 'wrap'",
				);

			useLayoutEffect(() => {
				render(true);
			}, [render]);

			useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(
				ref,
				() => rootRef.current,
				[rootRef],
			);

			return (
				<Root
					{...props}
					ref={rootRef}
					dir={dir}
					wrap={wrap}
					align={align}
					justify={justify}
					style={style}
					sizes={processedSizes}
					sizesCount={sizesCount}
					gaps={processedGaps}
					gapsCount={gapsCount}
					maxedWidth={maxedWidth}
					maxedHeight={maxedHeight}
				>
					{children}
				</Root>
			);
		},
	),
	{
		classes: {
			root: Root.styledComponentId,
		},
	},
);

declare namespace Flex {
	export interface Props
		extends PropsWithChildren,
			HTMLAttributes<HTMLDivElement> {
		dir?: CSSProperties["flexDirection"];
		wrap?: CSSProperties["flexWrap"];
		align?: CSSProperties["alignItems"];
		justify?: CSSProperties["justifyContent"];
		style?: Omit<
			CSSProperties,
			| "flexDirection"
			| "CSSProperties"
			| "flexWrap"
			| "alignItems"
			| "justifyContent"
		>;
		gaps?: string;
		sizes?: string | ((child: Element) => Size);
		maxedWidth?: boolean;
		maxedHeight?: boolean;
	}
}

export default Flex;
