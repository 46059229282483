import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";
import close from "../../../../icons/close";

export interface Props extends HTMLAttributes<HTMLDivElement> {}

export const Root = styled.div`
  cursor: pointer;
`;

export const Close: FC<Props> = (props) => {
  return (
    <Root {...props}>
      {close}
    </Root>
  )
}
