import { RefObject } from "react";
import Position from "../../types/Position";
import SizeLiteral from "../../types/SizeLiteral";
import { ControllerBase } from "../../utils/react";

class Controller extends ControllerBase<Controller.Context> {
	public get index(): number {
		return this.context?.rootRef.current?.parentNode?.childNodes
			? Array.prototype.indexOf.call(
					this.context.rootRef.current.parentNode.childNodes,
					this.context.rootRef.current,
			  )
			: -1;
	}

	public getPosition(): Position {
		const { x, y } =
			this.context?.rootRef.current?.getBoundingClientRect() ?? {
				x: 0,
				y: 0,
			};

		return { x, y };
	}

	public getSize(): SizeLiteral {
		const { width, height } =
			this.context?.rootRef.current?.getBoundingClientRect() ?? {
				width: 0,
				height: 0,
			};

		return { width, height };
	}
}

declare namespace Controller {
	interface Context {
		rootRef: RefObject<HTMLElement | null>;
	}
}

export default Controller;
