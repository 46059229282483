import React, { forwardRef } from 'react';
import Flex from "../Flex";

const Column = forwardRef<HTMLDivElement, Column.Props>(({
  children,
  wrap = "nowrap",
  ...props
}, ref) => {
  return (
    <Flex
      ref={ref}

      dir="column"
      wrap={wrap}

      {...props}
    >
      {children}
    </Flex>
  )
})

declare namespace Column {
  type Props = Omit<Flex.Props, "dir">
}

export default Column
