import styled from "styled-components";
import theme from "../../../../../../styles/theme";

const Item = styled.div<Item.Props>`
	min-width: max-content;

	color: ${({ selected }) =>
		selected ? theme.colors.accent : theme.colors.secondary_tab_text};

	${({ selected }) =>
		selected ? "box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.15);" : ""}

	user-select: none;
	display: flex !important;
	align-items: center !important;
	gap: 3.5px;
	padding: 12px 14px 12px 11px !important;
	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
	box-sizing: border-box;
	border-top: ${({ selected }) =>
		selected
			? `2px solid ${theme.colors.accent}`
			: "2px solid transparent"};

	font-family: "Lato";
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 16px;

	display: flex;
	align-items: center;
	text-align: center;

	color: ${({ disabled }) => {
		if (disabled) {
			return theme.colors.disabled_text;
		}

		return "#647079";
	}};

	background: ${({ selected }) =>
		selected ? theme.colors.white : theme.colors.transparent} !important;

	&:hover {
		background: ${({ selected }) =>
			selected ? theme.colors.white : theme.colors.color_border_basic};

		color: ${({ selected, disabled }) => {
			if (disabled) {
				return theme.colors.disabled_text;
			}

			return selected ? theme.colors.accent : theme.colors.primary;
		}};
	}
`;

declare namespace Item {
	export interface Props {
		selected: boolean;
		disabled?: boolean;
	}
}

export default Item;
