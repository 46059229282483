/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-shadow */

import { extend } from "lodash";
import React, {
	PropsWithChildren,
	useCallback,
	useEffect,
	useMemo,
	useRef,
} from "react";
import useChanged from "../../hooks/useChanged";
import useRender from "../../hooks/useRender";
import useSize from "../../hooks/useSize";
import Position from "../../types/Position";
import StyleProps from "../../types/StyleProps";
import { withController } from "../../utils/react";
import InternalContainer from "./components/Container";
import Root from "./components/Root";
import InternalTracker from "./components/Tracker";
import InternalController from "./Controller";
import useRefSize from "../../hooks/useRefSize";

const Float = extend(
	withController<Float.Props, InternalController>(
		({
			children,

			style,
			className,

			controller,

			containerId,
			trackerId,

			offset,
		}) => {
			const rootRef = useRef<HTMLDivElement | null>(null);

			const idRef = useRef<string | null>(null);
			const id = idRef.current;

			const render = useRender();

			const container = useMemo(
				() => InternalContainer.get(containerId),
				[containerId],
			);
			const tracker = useMemo(
				() => InternalTracker.get(trackerId),
				[trackerId],
			);

			const { ref: rootRefForSize, size: rootSize } = useRefSize();

			// TODO: usePosition(root)

			const containerSize = container?.getSize() ?? {
				width: 0,
				height: 0,
			};
			const containerPosition = container?.getPosition() ?? {
				x: 0,
				y: 0,
			};
			const trackerSize = tracker?.getSize() ?? { width: 0, height: 0 };
			const trackerPosition = tracker?.getPosition() ?? { x: 0, y: 0 };

			const calculatedOffset = useMemo(
				() =>
					typeof offset === "function"
						? offset({ container, tracker, float: controller })
						: offset || { x: 0, y: 0 },
				// eslint-disable-next-line react-hooks/exhaustive-deps
				[
					container,
					containerSize.width,
					containerSize.height,
					containerPosition.x,
					containerPosition.y,
					tracker,
					trackerSize.width,
					trackerSize.height,
					trackerPosition.x,
					trackerPosition.y,
					controller,
					rootSize.width,
					rootSize.height,
				],
			);

			const visible = useMemo(
				() => rootSize.width !== 0 && rootSize.height !== 0,
				[rootSize.width, rootSize.height],
			);
			const rootX = useMemo(
				() =>
					trackerPosition.x -
					containerPosition.x +
					calculatedOffset.x,
				[trackerPosition.x, containerPosition.x, calculatedOffset.x],
			);
			const rootY = useMemo(
				() =>
					trackerPosition.y -
					containerPosition.y +
					calculatedOffset.y,
				[trackerPosition.y, containerPosition.y, calculatedOffset.y],
			);

			const rootRefCallback = useCallback(
				(element: HTMLDivElement) => {
					rootRef.current = element;
					rootRefForSize(element);
				},
				[rootRefForSize],
			);

			const content = useMemo(
				() => (
					<Root
						ref={rootRefCallback}
						style={style}
						className={className}
						visible={visible}
						x={rootX}
						y={rootY}
					>
						{children}
					</Root>
				),
				[
					rootRefCallback,
					style,
					className,
					visible,
					rootX,
					rootY,
					children,
				],
			);

			controller.setContext({
				rootRef,
			});

			useEffect(() => {
				const renderHandler = () => {
					render();
				};

				let id: string | null = null;

				if (container) {
					id = container?.add(content);

					container
						.on("size", renderHandler)
						.on("move", renderHandler);

					idRef.current = id;
				}

				if (tracker)
					tracker.on("size", renderHandler).on("move", renderHandler);

				return () => {
					if (container) {
						container.delete(id!);

						container
							.off("size", renderHandler)
							.off("move", renderHandler);
					}

					if (tracker)
						tracker
							.off("size", renderHandler)
							.off("move", renderHandler);
				};
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [container, tracker]);

			const deps = useMemo(
				() => [container, id, content, rootX, rootY],
				[container, id, content, rootX, rootY],
			);

			useChanged(() => {
				if (id) container?.update(id, content);
			}, deps);

			useEffect(() => {
				render();
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, []);

			return null;
		},
		InternalController,
	),
	{
		Container: InternalContainer,
		Tracker: InternalTracker,
	},
);

declare namespace Float {
	interface OffsetCallbackOptions {
		container?: Float.Container.Controller;
		tracker?: Float.Tracker.Controller;
		float?: Float.Controller;
	}

	type OffsetCallback = (options: OffsetCallbackOptions) => Position;

	type Controller = InternalController;

	interface Props extends PropsWithChildren, StyleProps {
		containerId: string;
		trackerId: string;

		offset?: Position | OffsetCallback;
	}

	interface InternalProps extends Props {
		controller: InternalController;
	}
}

declare namespace Float.Container {
	type Controller = InternalContainer.Controller;
	type Props = InternalContainer.Props;
}

declare namespace Float.Tracker {
	type Controller = InternalTracker.Controller;
	type Props = InternalTracker.Props;
}

export default Float;
