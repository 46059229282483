import React, { CSSProperties, useEffect, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import { ClickAwayListener } from "@mui/material";
import theme from "../../styles/theme";
import Overlayer from "../Overlayer";
import { SearchBar } from "./SearchBar";

const StyledAdvancedSelect = styled.div`
	max-height: 286px;
	display: flex;
	flex: 1 0 0;
	flex-direction: column;
	overflow: hidden;
	cursor: pointer;

	background-color: white;
	border: 1px solid ${theme.colors.color_border_basic};
	border-radius: 5px;

	&:hover {
		border-color: ${theme.colors.primary};
	}

	&[data-open="true"] {
		height: 286px;
	}
`;
const StyledOptionList = styled.div`
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: overlay;

	&::-webkit-scrollbar {
		width: 3px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #21333f33;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}
`;

const StyledShadowBox = styled.div`
	width: 100%;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
`;

export type DisplaySelected<T> = (selected: T[]) => JSX.Element;

export interface Option<T> {
	selected: boolean;
	value: T;
}

export interface Props<T> {
	// general props:
	isOpen?: boolean;
	setTipsVisible?: (status: boolean) => void;
	options?: T[];
	selectedOption?: T[];
	setQuery?: (query: string) => void;
	autoFocus?: boolean;
	query?: string;
	placeholder?: string;
	activeOption?: number;
	style?: CSSProperties;
	onKeyDown?: (e) => void;
	onFocus?: (e) => void;
	onlyShowTips?: boolean;

	// options related props:
	displaySelected: DisplaySelected<T>;
	optionRender: OptionRender<T>;
	onSelect?: (selectedOption: T[]) => void;
	id?: string;
}

export type SearchFilter<T> = (option: Option<T>, query: string) => boolean;

const StyledOptionWrapper = styled.div`
	user-select: none;
	cursor: pointer;
`;

export type OptionRender<T> = (
	option: Option<T>,
	toggleSelect: () => void,
) => React.ReactNode;
interface IField extends HTMLDivElement {
	scrollIntoViewIfNeeded?: any;
}
function List({ options, setOptions, activeOption, optionRender, onSelect }) {
	const fieldRef = React.useRef<IField | null>(null);
	useEffect(() => {
		if (fieldRef?.current) {
			fieldRef?.current?.scrollIntoViewIfNeeded(true);
		}
	}, [activeOption, options]);
	const handleSelect = (option) => {
		const selected = [option.value];
		onSelect(selected);
		setOptions(
			options.map((o) =>
				o.selected || o === option
					? {
							...o,
							selected: o === option,
					  }
					: o,
			),
		);
	};
	return (
		<StyledOptionList>
			{options.map((option) => (
				<StyledOptionWrapper
					ref={option.selected ? fieldRef : null}
					key={option.value.id}
				>
					{optionRender(option, () => handleSelect(option))}
				</StyledOptionWrapper>
			))}
		</StyledOptionList>
	);
}

export function InputWithTips<T extends React.Attributes>({
	// general props:
	isOpen = false,
	setTipsVisible = () => {},
	options: externalOptions = [],
	query = "",
	autoFocus = false,
	setQuery = () => {},
	placeholder,
	activeOption = 0,
	style = {},
	onKeyDown = () => {},
	onFocus = () => {},
	onlyShowTips = false,

	// option list related props:
	displaySelected,
	optionRender,
	onSelect = () => {},
	id = "",
}: Props<T>) {
	const extensionOption = (options, active) =>
		options.map((option, i) => ({
			selected: active === i,
			value: option,
		}));

	const [options, setOptions] = useState<Option<T>[]>(
		extensionOption(externalOptions, activeOption),
	);

	useEffect(() => {
		setOptions(extensionOption(externalOptions, activeOption));
	}, [externalOptions, query, activeOption]);

	const overlay = (
		<ClickAwayListener onClickAway={() => setTipsVisible(false)}>
			<StyledAdvancedSelect
				style={style}
				data-open={autoFocus || onlyShowTips || options.length}
			>
				<StyledShadowBox>
					<SearchBar
						query={query}
						autoFocus={autoFocus}
						onFocus={onFocus}
						placeholder={placeholder}
						onKeyDown={onKeyDown}
						onChange={(query) => {
							setQuery(query);
						}}
						id={id}
					/>
				</StyledShadowBox>
				<List
					options={options}
					setOptions={setOptions}
					optionRender={optionRender}
					onSelect={onSelect}
					activeOption={activeOption}
				/>
			</StyledAdvancedSelect>
		</ClickAwayListener>
	);

	return (
		<Overlayer overlay={isOpen && overlay}>
			<StyledAdvancedSelect
				data-open={autoFocus || onlyShowTips || options.length}
				onClick={() => setTipsVisible(true)}
			>
				{displaySelected(
					options.filter((o) => o.selected).map((o) => o.value),
				)}
			</StyledAdvancedSelect>
		</Overlayer>
	);
}
