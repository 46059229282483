import React, { useEffect } from "react";
import useInternal from "../../hooks/useInternal";
import Option from "../../types/Option";
import Column from "../Column";
import { RadioButton } from "../RadioButton";

export interface Props<T> {
	options?: Option<T>[];
	selectedOption?: Option<T>;
	disabled?: boolean;
	onSelect: (option?: Option<T>) => void;
}

export function RadioList<T>({
	options = [],
	selectedOption,
	disabled = false,
	onSelect = () => {},
}: Props<T>) {
	const [selected, setSelected] = useInternal<Option<T> | undefined>(
		selectedOption,
	);

	useEffect(() => {
		if (selected && !options.find((o) => o.value === selected.value))
			setSelected(undefined);
	}, [options]);

	return (
		<Column gaps="10px*">
			{options.map((option: Option<T>) => (
				<RadioButton
					key={option.key}
					selected={selectedOption === option}
					disabled={disabled}
					label={option.label}
					onClick={() => {
						if (disabled) return;

						onSelect(option);
						setSelected(option);
					}}
				/>
			))}
		</Column>
	);
}
