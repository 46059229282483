import styled from "styled-components"
import Select from "../../../../../.."
import theme from "../../../../../../../../styles/theme"

const Root = styled.div<Select.OptionComponentBase.Props>`
    display: flex;

    flex-direction: row;

    align-items: center;

    height: 32px;

    padding-inline: 12px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: ${theme.colors.secondary};

    background-color: ${({ selected }) => selected ? theme.colors.button_secondary : theme.colors.white};

    ${({ hovered }) => hovered ? "&," : ""}
    &:hover {
        color: ${theme.colors.primary};

        background-color: ${theme.colors.button_secondary_hover};
    }

    ${theme.longWord}

    user-select: none;
`

export default Root
