import _ from "lodash";
import { MutableRefObject } from "react";
import useRender from "../hooks/useRender";
import AnyFunction from "../types/AnyFunction";
import { setRefValue } from "./react";

export interface LocalRefUpdaterContext<RefType> {
	rootRef: MutableRefObject<RefType>;
	render: ReturnType<typeof useRender>;
}

export function refUpdater<RefType>() {
	return function (this: LocalRefUpdaterContext<RefType>, element: RefType) {
		setRefValue(this.rootRef, element);

		this.render();
	};
}

declare namespace refUpdater {
	type RefUpdaterContext<RefType> = LocalRefUpdaterContext<RefType>;
}

type Parameters<Func extends AnyFunction> = Func extends (
	this: any,
	...params: infer Params
) => any
	? Params
	: never;

type This<Func extends AnyFunction> = Func extends (
	this: infer T,
	...params: any[]
) => any
	? T
	: never;

export interface Debounced<Handler extends AnyFunction> {
	(this: This<Handler>, ...params: Parameters<Handler>):
		| ReturnType<Handler>
		| undefined;

	cancel(): void;
	flush(): ReturnType<Handler> | undefined;
	pending(): boolean;
}

export function debounce<Handler extends AnyFunction>(
	handler: Handler,
	time: number,
) {
	const state = { pending: false };

	const callback = function (...params) {
		const result = handler.call(this, ...params);

		state.pending = false;

		return result;
	} as Handler;

	const debounced = _.debounce(callback, time);

	const overridden = function (...params) {
		state.pending = true;
		return debounced.call(this, ...params);
	} as Debounced<Handler>;

	function cancel() {
		state.pending = false;

		debounced.cancel();
	}

	function pending() {
		return state.pending;
	}

	overridden.cancel = cancel;
	overridden.flush = debounced.flush;
	overridden.pending = pending;

	return overridden;
}

export default {
	refUpdater,
	debounce,
};
