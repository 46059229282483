import { extend } from "lodash";
import styled from "styled-components";
import Select from "../..";
import theme from "../../../../styles/theme";
import InternalItem from "./components/Item";

const OptionList = extend(
	styled.div<OptionList.Props>`
		max-height: 128px;

		${({ direction }) =>
			direction === "down"
				? `
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                    `
				: `
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                    `}

		overflow: hidden auto;

		${theme.scrollbar}
	`,
	{
		Item: InternalItem,
	},
);

declare namespace OptionList {
	interface Props {
		direction: Select.Direction;
	}

	namespace Item {
		type Props = InternalItem.Props;

		namespace Root {
			type Props = InternalItem.Root.Props;
		}
	}
}

export default OptionList;
