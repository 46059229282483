import React, { FC } from "react";
import Parent from "../..";
import { InputifyComponentProps } from "../../../../utils/react";
import Item from "./components/Item";
import Root from "./components/Root";
import Key from "../../../../types/Key";

const Monoblock: FC<Monoblock.Props> = ({
	style,
	className,

	value,

	options,

	onChange,
	onChangeSelectedOption,

	onEnter,
	onLeave,
	onFocus,
	onBlur,
}) => {
	return (
		<Root
			style={style}
			className={className}
			gaps="1px*"
			onMouseEnter={onEnter}
			onMouseLeave={onLeave}
			onFocus={onFocus}
			onBlur={onBlur}
		>
			{options.map((option) => (
				<Item
					key={option.key}
					selected={option.key === value}
					onClick={() => {
						onChange(option.key);
						onChangeSelectedOption?.(option);
					}}
				>
					{option.label}
				</Item>
			))}
		</Root>
	);
};

declare namespace Monoblock {
	export interface Props
		extends Omit<Parent.Props, "variant">,
			InputifyComponentProps<Key> {}
}

export default Monoblock;
