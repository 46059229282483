/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-shadow */

import { extend } from "lodash";
import React, {
	createContext,
	Dispatch,
	ReactElement,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import {
	InputifiedComponentProps,
	inputify,
	InputifyComponentProps,
} from "../../utils/react";
import Select from "../Select";
import Option from "./components/Option";
import Selected from "./components/Selected";
import OptionType from "../../types/Option";

const Context = createContext<TextSelect.Context.Value | null>(null);

const TextSelect = extend(
	inputify(
		// eslint-disable-next-line prettier/prettier
		<OptionValue, >({
			value = "",
			autoFocus,
			placeholder,
			options,
			open,
			focused,
			onSelect,
			onChange,
			onBlur,
			...props
		}: TextSelect.InternalProps<OptionValue>) => {
			const [hoveredOptionIndex, setHoveredOptionIndex] = useState(-1);

			const internalOnSelect = useCallback(
				(option: OptionType<OptionValue>) => {
					onChange(option.label);
					onSelect?.(option);
					onBlur();
				},
				[onChange, onSelect, onBlur],
			);

			const internalOpen = useMemo(
				() => open || (!!options?.length && focused),
				[open, options?.length, focused],
			);

			const onNextOption = useCallback(() => {
				setHoveredOptionIndex((value) =>
					Math.min(value + 1, options ? options.length - 1 : 0),
				);
			}, [options]);

			const onPrevOption = useCallback(() => {
				setHoveredOptionIndex((value) => Math.max(value - 1, 0));
			}, [setHoveredOptionIndex]);

			useEffect(() => {
				setHoveredOptionIndex(-1);
			}, [options]);

			return (
				<Context.Provider
					value={{
						value,

						hoveredOptionIndex,
						autoFocus,

						placeholder,
						options: options as OptionType<unknown>[],

						focused,
						open: internalOpen,

						onChange,
						onChangeHoveredOptionIndex: setHoveredOptionIndex,

						onSelect: internalOnSelect as Dispatch<
							OptionType<unknown>
						>,
						onNextOption,
						onPrevOption,

						onBlur,
					}}
				>
					<Select
						{...props}
						focused={focused}
						open={internalOpen}
						autoFocus={autoFocus}
						options={options}
						arrow={false}
						Selected={Selected}
						Option={Option}
						onSelect={internalOnSelect}
					/>
				</Context.Provider>
			);
		},
	) as <OptionValue>(
		props: TextSelect.Props<OptionValue>,
	) => ReactElement | null,
	{
		Context,
	},
);

declare namespace TextSelect {
	type Value = string;

	interface PropsBase<OptionValue>
		extends Omit<Select.PropsBase<OptionValue>, "Option" | "Selected"> {}

	type InternalProps<OptionValue> = PropsBase<OptionValue> &
		InputifyComponentProps<Value>;

	type Props<OptionValue> = PropsBase<OptionValue> &
		InputifiedComponentProps<Value>;
}

declare namespace TextSelect.Context {
	interface Value
		extends Required<
				Pick<
					TextSelect.InternalProps<unknown>,
					"options" | "focused" | "open" | "onChange" | "onSelect"
				>
			>,
			Pick<TextSelect.InternalProps<unknown>, "placeholder"> {
		value: TextSelect.Value;

		hoveredOptionIndex: number;
		autoFocus?: boolean;

		onChangeHoveredOptionIndex: Dispatch<number>;

		onNextOption: () => void;
		onPrevOption: () => void;

		onBlur: () => void;
	}
}

export default TextSelect;
