import React, { FC } from "react";
import styled from "styled-components";
import theme from "../../styles/theme";

const StyledInput = styled.input`
	min-height: 32px;
	width: 100%;
	padding-inline: 12px;
	font-family: ${theme.font.n};
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	border: none;
	outline: none;
	z-index: 1;

	&::placeholder {
		color: rgb(128, 134, 138);
		font-style: italic;
	}
`;

export interface Props {
	query?: string;
	placeholder?: string;
	autoFocus?: boolean;
	onFocus?: (e) => void;
	onKeyDown?: (e) => void;
	onChange: (query: string) => void;
	id?: string;
}

export const SearchBar: FC<Props> = ({
	query,
	autoFocus,
	onKeyDown,
	onFocus,
	placeholder = "Search...",
	onChange,
	id = "",
}) => (
	<StyledInput
		autoComplete="off"
		value={query}
		onFocus={onFocus}
		autoFocus={autoFocus}
		onKeyDown={onKeyDown}
		placeholder={placeholder}
		onChange={(e) => {
			onChange(e.target.value);
		}}
		id={id}
	/>
);
