import React, { ReactNode } from "react";

function Wrapper<ChildrenType extends unknown>({
	children,
	wrap,
}: Wrapper.Props<ChildrenType>) {
	return <>{wrap ? wrap(children) : children}</>;
}

namespace Wrapper {
	export interface Props<ChildrenType> {
		children: ChildrenType;
		wrap: (children: ChildrenType) => ReactNode | undefined;
	}
}

export default Wrapper
