import React from "react";

export default (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1908_12483)">
      <path d="M11.6146 7.16362C12.1769 7.16362 12.6328 6.70777 12.6328 6.14544C12.6328 5.58311 12.1769 5.12726 11.6146 5.12726C11.0523 5.12726 10.5964 5.58311 10.5964 6.14544C10.5964 6.70777 11.0523 7.16362 11.6146 7.16362Z" fill="#03A9F4" />
      <path d="M13.8182 16.7273H12.3637V8.79999H10.1818C9.98896 8.79999 9.80397 8.87661 9.66758 9.013C9.53119 9.14939 9.45457 9.33438 9.45457 9.52726C9.45457 9.72015 9.53119 9.90513 9.66758 10.0415C9.80397 10.1779 9.98896 10.2545 10.1818 10.2545H10.9091V16.7273H9.45457C9.26168 16.7273 9.0767 16.8039 8.94031 16.9403C8.80392 17.0767 8.72729 17.2617 8.72729 17.4545C8.72729 17.6474 8.80392 17.8324 8.94031 17.9688C9.0767 18.1052 9.26168 18.1818 9.45457 18.1818H13.8182C14.0111 18.1818 14.1961 18.1052 14.3325 17.9688C14.4689 17.8324 14.5455 17.6474 14.5455 17.4545C14.5455 17.2617 14.4689 17.0767 14.3325 16.9403C14.1961 16.8039 14.0111 16.7273 13.8182 16.7273Z" fill="#03A9F4" />
      <path d="M11.6364 23.2727C9.33491 23.2727 7.08514 22.5903 5.17155 21.3116C3.25796 20.033 1.7665 18.2157 0.88577 16.0894C0.00504181 13.9631 -0.225397 11.6235 0.223595 9.36622C0.672586 7.10899 1.78084 5.03559 3.40822 3.40822C5.03559 1.78084 7.10899 0.672586 9.36622 0.223595C11.6235 -0.225397 13.9631 0.00504181 16.0894 0.88577C18.2157 1.7665 20.033 3.25796 21.3116 5.17155C22.5903 7.08514 23.2727 9.33491 23.2727 11.6364C23.2727 14.7225 22.0468 17.6823 19.8645 19.8645C17.6823 22.0468 14.7225 23.2727 11.6364 23.2727ZM11.6364 1.45455C9.62259 1.45455 7.65404 2.0517 5.97965 3.1705C4.30526 4.28929 3.00023 5.87947 2.22959 7.73995C1.45896 9.60044 1.25732 11.6477 1.65019 13.6227C2.04306 15.5978 3.01278 17.412 4.43673 18.836C5.86069 20.2599 7.67491 21.2297 9.64999 21.6225C11.6251 22.0154 13.6723 21.8138 15.5328 21.0431C17.3933 20.2725 18.9834 18.9675 20.1022 17.2931C21.221 15.6187 21.8182 13.6501 21.8182 11.6364C21.8182 8.93598 20.7455 6.34619 18.836 4.43673C16.9265 2.52727 14.3368 1.45455 11.6364 1.45455Z" fill="#03A9F4" />
    </g>
    <defs>
      <clipPath id="clip0_1908_12483">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
