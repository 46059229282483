import styled from "styled-components"
import theme from "../../../../../../styles/theme"
import Row from "../../../../../Row"

const Root = styled(Row)`
    padding: 2px;

    border-radius: 5px;

    background-color: ${theme.colors.button_secondary};
`

export default Root
