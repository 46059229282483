import React, { FC, MutableRefObject, PropsWithChildren, useRef } from "react";
import useClickAwayListener from "../../hooks/useClickAwayListener";

export interface Props extends PropsWithChildren {
	onClickAway: () => void;
}

export const ClickAwayListener: FC<Props> = ({ onClickAway, children }) => {
	const ref: MutableRefObject<HTMLElement | null> = useRef(null);

	useClickAwayListener(ref, onClickAway);

	return (
		<div
			className="click-away-listener"
			ref={(div) => (ref.current = div)}
			onClick={(e) => e.stopPropagation()}
		>
			{children}
		</div>
	);
};
