/* eslint-disable func-names */

import Leaflet, { LatLngTuple } from "leaflet";
import { clone } from "lodash";
import { CallbackContext } from "../type";
import { latLngToCoordinates } from "../utils";
import { ContextedFunction } from "../../../../../hooks/useContextedFunction";

const onDrawVertex: ContextedFunction<
	CallbackContext,
	[Leaflet.LeafletEvent],
	void
> = function (event) {
	if (this.initializingRef.current) return;

	const drawEvent = event as Leaflet.DrawEvents.DrawVertex;

	const vertices: LatLngTuple[] = [];

	drawEvent.layers.eachLayer((layer) => {
		vertices.push(latLngToCoordinates((layer as any)._latlng));
	});

	this.setInternalPolygons((polygons) => {
		const newPolygons = clone(polygons);
		const newPolygonIndex = newPolygons.findIndex(
			(polygon) => this.selected === polygon.id,
		);

		if (newPolygonIndex === -1) return polygons;

		newPolygons[newPolygonIndex] = {
			...newPolygons[newPolygonIndex],

			vertices,
		};

		return newPolygons;
	});
};

export default onDrawVertex;
