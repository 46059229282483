import React from "react";
import styled from "styled-components";
import { RadioButtonIcon } from "../../icons/radio-button";
import Row from "../Row";
import { inputify } from "../../utils/react";
import { StyledLabel } from "../StyledLabel";
import Column from "../Column";

const StyledRadioButton = styled.div`
	width: max-content;
	display: flex;
	user-select: none;
`;

export interface Props {
	defaultSelected?: boolean;
	selected?: boolean;
	disabled?: boolean;
	label?: string;
	content?: React.ReactNode;
	contentPosition?: "left" | "right";
	onClick?: () => void;
}

export const RadioButton = inputify<Props, void>(
	({
		selected = false,
		defaultSelected = false,
		disabled,
		label,
		content,
		contentPosition = "right",
		onClick = () => {},
	}) => {
		const icon = (
			<div style={{ cursor: "pointer" }} onClick={onClick}>
				<RadioButtonIcon
					selected={selected || defaultSelected}
					disabled={disabled}
				/>
			</div>
		);

		const sideContent = (
			<Column gaps="8px">
				{label && (
					<StyledLabel
						style={{ cursor: "pointer" }}
						active={selected || defaultSelected}
						onClick={onClick}
					>
						{label}
					</StyledLabel>
				)}
				{content}
			</Column>
		);

		return (
			<StyledRadioButton>
				<Row gaps="8px*" justify="center">
					{contentPosition === "right" ? (
						<>
							{icon}
							{sideContent}
						</>
					) : (
						<>
							{sideContent}
							{icon}
						</>
					)}
				</Row>
			</StyledRadioButton>
		);
	},
);
