/* eslint-disable no-shadow */
import React, { useMemo } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import Icon from "../Icon";
import theme from "../../styles/theme";
import { InputifiedComponentProps, inputify } from "../../utils/react";
import Column from "../Column";
import Option from "../../types/Option";
import Row from "../Row";
import Key from "../../types/Key";

const StyledRow = styled(Row)`
	padding-inline: 8px;
	height: 32px;
	user-select: none;
	cursor: grab;

	&:hover {
		background-color: ${theme.colors.button_secondary_hover};
	}
`;

const OrderSelect = inputify<OrderSelect.OwnProps<unknown>, OrderSelect.Value>(
	({ value: keys, options, onChange, renderRow, ...props }) => {
		const lookup = useMemo(() => {
			const lookup: Record<Key, Option<unknown>> = {};

			options.forEach((option) => {
				lookup[option.key] = option;
			});

			return lookup;
		}, [options]);

		return (
			<DragDropContext
				onDragEnd={(result) => {
					if (!result.destination) return;
					if (!keys) return;

					const newValues = [...keys];
					const from = result.source.index;
					const to = result.destination.index;

					const [reorderedValue] = newValues.splice(from, 1);
					newValues.splice(to, 0, reorderedValue);

					onChange(newValues);
				}}
			>
				<Droppable droppableId="droppable">
					{(provided) => (
						<Column
							ref={provided.innerRef}
							{...provided.droppableProps}
							gaps="1px*"
							maxedWidth
							{...props}
						>
							{keys?.map((key, index) => {
								const option = lookup[key];

								return (
									<Draggable
										key={option.key}
										draggableId={option.key.toString()}
										index={index}
									>
										{(provided) => {
											const rootProps = {
												...provided.draggableProps,
												ref: provided.innerRef,
											};

											// eslint-disable-next-line prefer-destructuring
											const dragHandleProps =
												provided.dragHandleProps;

											return renderRow ? (
												renderRow(option, index, {
													rootProps,
													dragHandleProps,
												})
											) : (
												<StyledRow
													{...rootProps}
													{...dragHandleProps}
													gaps="5px*"
													align="center"
												>
													<Icon
														id="dragging"
														size="12px"
													/>
													{option.label}
												</StyledRow>
											);
										}}
									</Draggable>
								);
							})}

							{provided.placeholder}
						</Column>
					)}
				</Droppable>
			</DragDropContext>
		);
	},
) as <V>(props: OrderSelect.Props<V>) => React.ReactElement;

declare namespace OrderSelect {
	export type Value = string[];

	interface OwnProps<V> {
		options: Option<V>[];
		renderRow?: (
			option: Option<V>,
			index: number,
			providedProps: {
				rootProps: any;
				dragHandleProps: any;
			},
		) => React.ReactNode;
	}

	export type Props<V> = OwnProps<V> & InputifiedComponentProps<Value>;
}

export default OrderSelect;
