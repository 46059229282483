import styled from "styled-components";

export interface Props {
  color: string
}

export const Indicator = styled.div<Props>`
  align-self: stretch;

  width: 5px;

  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  background-color: ${({ color }) => color};
`;
