/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { clamp, floor } from "lodash";
import styled from "styled-components";
import theme from "../../styles/theme";
import { inputify } from "../../utils/react";
import useInternal from "../../hooks/useInternal";
import useHolder from "../../hooks/useHolder";
import Icon from "../Icon";

interface StyledProps {
	width: string;

	disabled: boolean;
}

const StyledStepper = styled.div<StyledProps>`
	box-sizing: border-box;
	width: ${(props) => props.width};
	height: 32px;
	display: grid;
	grid-template-columns: 1fr 18px;
	overflow: hidden;

	background-color: white;
	border: 1px solid ${theme.colors.color_border_basic};
	border-radius: 5px;

	.input {
		font-family: ${theme.font.l};
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;

		width: 100%;
		border: none;
		outline: none;
		padding: 0;
		text-align: center;

		&:hover {
			border-color: ${theme.colors.primary};
		}

		&::-webkit-inner-spin-button {
			display: none;
		}
	}

	.step-buttons {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr;
		justify-content: center;
		align-items: center;

		border-left: 1px solid;
		border-color: ${theme.colors.color_border_basic};

		.step-button {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0;
			background-color: transparent;
			border: none;
			cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

			& svg {
				fill: ${theme.colors.text_placeholder};
			}

			&:hover svg {
				fill: ${theme.colors.primary};
			}
		}
	}

	&[data-disabled="true"] {
		background: ${theme.colors.disabled_filled};
		border-color: ${theme.colors.color_border_basic};

		.input {
			color: ${theme.colors.disabled_text};
		}

		.step-buttons {
			border-color: ${theme.colors.color_border_basic};

			.step-button svg {
				fill: ${theme.colors.disabled_text};
			}
		}
	}

	&:has(.input:focus) {
		border-color: ${theme.colors.accent};

		.input,
		.step-buttons {
			border-color: ${theme.colors.accent};
		}
	}
`;

declare namespace Stepper {
	type Value = number | undefined;

	interface Props {
		id?: string;

		min?: number;
		max?: number;
		step?: number;
		decimalCount?: number;
		delimiter?: string;

		width?: string;
		autoFocus?: boolean;
		/** @deprecated use autoFocus instead */
		customAutoFocus?: boolean;

		getRef?: (ref: HTMLInputElement | null) => void;
		onSubmit?: () => void;
	}
}

const Stepper = inputify<Stepper.Props, Stepper.Value>(
	({
		id,

		value,

		min = -Infinity,
		max = Infinity,
		step = 1,
		decimalCount = 0,
		delimiter = ",",

		disabled,
		width = "59px",
		autoFocus = false,
		customAutoFocus = false,

		getRef,
		onChange,
		onSubmit,
	}) => {
		const ref = useRef<HTMLInputElement | null>(null);

		const processValue = useCallback(
			(value: number | undefined) =>
				value == null
					? undefined
					: clamp(floor(value, decimalCount), min, max),
			[decimalCount, max, min],
		);
		const processText = useCallback(
			(value: number | undefined) =>
				value != null
					? value.toFixed(decimalCount).replace(/\./, delimiter)
					: undefined,
			[decimalCount, delimiter],
		);

		const [inFocus, setInFocus] = useState(false);
		const [internalValue, setInternalValue] = useInternal(
			processValue(value),
		);
		const [text, setText] = useState<string | undefined>(
			processText(internalValue),
		);

		const [updateText, setUpdateText] = useState(false);
		useEffect(() => {
			if (!inFocus || updateText) {
				setText(processText(internalValue));
				setUpdateText(false);
			}
		}, [inFocus, internalValue, processText, updateText]);

		const setValue = useCallback(
			(value: string | number | undefined) => {
				if (typeof value === "string") {
					const parsedValue = value ? parseFloat(value) : undefined;

					if (parsedValue == null || !Number.isNaN(parsedValue))
						// eslint-disable-next-line no-param-reassign
						value = parsedValue;
				}

				if (typeof value === "number") {
					const processedValue = processValue(value);

					setInternalValue(processedValue);
					onChange(processedValue);
				}
			},
			[processValue, setInternalValue, onChange],
		);

		const increase = useCallback(() => {
			setValue((internalValue || 0) + step);
		}, [setValue, internalValue, step]);

		const decrease = useCallback(() => {
			setValue((internalValue || 0) - step);
		}, [setValue, internalValue, step]);

		const increaseHolder = useHolder(350, 50, increase);
		const decreaseHolder = useHolder(350, 50, decrease);

		const focus = useCallback(() => {
			if (ref.current) {
				setInFocus(true);
				ref.current.focus();
				ref.current.select();
			}
		}, []);

		const blur = useCallback(() => {
			if (ref.current) {
				setInFocus(false);
				ref.current.blur();
			}
		}, []);

		const shareRef = useCallback(
			(input: HTMLInputElement | null) => {
				ref.current = input;

				if (input) {
					// eslint-disable-next-line no-new
					// new AutoNumeric(input, {
					// 	decimalPlacesShownOnBlur:
					// 		AutoNumeric.options.decimalPlacesShownOnBlur.two,
					// 	digitGroupSeparator:
					// 		AutoNumeric.options.digitGroupSeparator.normalSpace,
					// });

					if (getRef) getRef(input);
				}
			},
			[getRef],
		);

		useEffect(() => {
			if (customAutoFocus) {
				focus();
			}
		}, [customAutoFocus, focus]);

		useEffect(() => {
			if (autoFocus) {
				process.nextTick(focus);
			}
		}, [autoFocus, focus]);

		return (
			<StyledStepper
				width={width}
				data-disabled={disabled}
				disabled={disabled}
				onClick={(event) => {
					// event.preventDefault();
					event.stopPropagation();
				}}
			>
				<input
					ref={shareRef}
					id={id}
					className="input"
					type="text"
					value={text}
					disabled={disabled}
					onChange={(e) => {
						const decimals =
							decimalCount > 0
								? `(\\D+(\\d{0,${Math.floor(decimalCount)}}))?`
								: "";
						const match = e.target.value.match(
							new RegExp(`(-?\\d+)?${decimals}`),
						);

						let newText: string | undefined;

						if (match) {
							const int =
								match[1] || (match[2] && !match[3] ? "0" : "");
							const decimals = match[2]
								? `.${match[3] || ""}`
								: "";

							newText = int + decimals;
						}

						setText(newText?.replace(/\./, delimiter));
						setValue(newText);
					}}
					onFocus={focus}
					onBlur={blur}
					onKeyDown={(e) => {
						switch (e.key) {
							case "ArrowUp":
								e.preventDefault();
								setUpdateText(true);
								increase();
								break;

							case "ArrowDown":
								e.preventDefault();
								setUpdateText(true);
								decrease();
								break;

							case "-":
								e.preventDefault();

								setText("-");
								setValue(undefined);

								break;

							case "Enter":
								onSubmit?.();
								setUpdateText(true);
								break;

							case "Escape":
								e.preventDefault();
								blur();
								break;

							default:
								break;
						}
					}}
				/>
				<div className="step-buttons">
					<button
						className="step-button"
						tabIndex={-1}
						disabled={disabled}
						{...increaseHolder.events}
					>
						<Icon
							id="stepper-arrow-up"
							size={12}
							colors={[theme.colors.primary]}
						/>
					</button>

					<button
						className="step-button"
						tabIndex={-1}
						disabled={disabled}
						{...decreaseHolder.events}
					>
						<Icon
							id="stepper-arrow-down"
							size={12}
							colors={[theme.colors.primary]}
						/>
					</button>
				</div>
			</StyledStepper>
		);
	},
);

export default Stepper;
