import theme from "../../styles/theme";

interface ColorSchema {
	content: string;
	background: string;
}

interface VariantType {
	normal: ColorSchema;
	hover: ColorSchema;
	pressed: ColorSchema;
	disabled: ColorSchema;
}

interface Variant {
	default: VariantType;
	transparent: VariantType;
}

const variants: Record<
	"primary" | "secondary" | "negative" | "white",
	Variant
> = {
	primary: {
		default: {
			normal: {
				content: theme.colors.white,
				background: theme.colors.accent,
			},

			hover: {
				content: theme.colors.white,
				background: theme.colors.accent_hover,
			},

			pressed: {
				content: theme.colors.white,
				background: theme.colors.accent_hover,
			},

			disabled: {
				content: theme.colors.disabled_text,
				background: theme.colors.disabled_filled,
			},
		},

		transparent: {
			normal: {
				content: theme.colors.accent,
				background: theme.colors.transparent,
			},

			hover: {
				content: theme.colors.accent_hover,
				background: theme.colors.transparent,
			},

			pressed: {
				content: theme.colors.accent_hover,
				background: theme.colors.button_transparent_pressed,
			},

			disabled: {
				content: theme.colors.disabled_text,
				background: theme.colors.transparent,
			},
		},
	},

	secondary: {
		default: {
			normal: {
				content: theme.colors.secondary,
				background: theme.colors.button_secondary,
			},

			hover: {
				content: theme.colors.primary,
				background: theme.colors.button_secondary_hover,
			},

			pressed: {
				content: theme.colors.primary,
				background: theme.colors.button_secondary_hover,
			},

			disabled: {
				content: theme.colors.disabled_text,
				background: theme.colors.disabled_filled,
			},
		},

		transparent: {
			normal: {
				content: theme.colors.secondary,
				background: theme.colors.transparent,
			},

			hover: {
				content: theme.colors.primary,
				background: theme.colors.transparent,
			},

			pressed: {
				content: theme.colors.primary,
				background: theme.colors.button_transparent_pressed,
			},

			disabled: {
				content: theme.colors.disabled_text,
				background: theme.colors.transparent,
			},
		},
	},

	negative: {
		default: {
			normal: {
				content: theme.colors.white,
				background: theme.colors.negative,
			},

			hover: {
				content: theme.colors.white,
				background: theme.colors.negative_hover,
			},

			pressed: {
				content: theme.colors.white,
				background: theme.colors.negative_hover,
			},

			disabled: {
				content: theme.colors.disabled_text,
				background: theme.colors.disabled_filled,
			},
		},

		transparent: {
			normal: {
				content: theme.colors.negative,
				background: theme.colors.transparent,
			},

			hover: {
				content: theme.colors.negative_hover,
				background: theme.colors.transparent,
			},

			pressed: {
				content: theme.colors.negative_hover,
				background: theme.colors.button_transparent_pressed,
			},

			disabled: {
				content: theme.colors.disabled_text,
				background: theme.colors.transparent,
			},
		},
	},

	white: {
		default: {
			normal: {
				content: theme.colors.secondary,
				background: theme.colors.white,
			},

			hover: {
				content: theme.colors.primary,
				background: theme.colors.button_secondary_hover,
			},

			pressed: {
				content: theme.colors.primary,
				background: theme.colors.button_secondary_hover,
			},

			disabled: {
				content: theme.colors.disabled_text,
				background: theme.colors.disabled_filled,
			},
		},

		transparent: {
			normal: {
				content: theme.colors.secondary,
				background: theme.colors.transparent,
			},

			hover: {
				content: theme.colors.primary,
				background: theme.colors.transparent,
			},

			pressed: {
				content: theme.colors.primary,
				background: theme.colors.button_transparent_pressed,
			},

			disabled: {
				content: theme.colors.disabled_text,
				background: theme.colors.transparent,
			},
		},
	},
};

export default variants;
