import { union } from "lodash"

export function mergeValues<Object1, Object2>(object1: Object1, object2: Object2) {
  type ResultObject = Object1 & Object2
  
  if (!object1 || !object2) {
    if (!object1) {
      if (!object2)
        return null

      return object2
    }

    return object1
  }

  return union(Object.keys(object1), Object.keys(object2))
    .reduce<ResultObject>((accumulator, propertyName) => {
      const prevEvents = object1[propertyName]
      const nextEvents = object2[propertyName]

      if (!prevEvents || !nextEvents) {
        if (!prevEvents) {
          if (!nextEvents)
            return accumulator

          accumulator[propertyName] = nextEvents

          return accumulator
        }

        accumulator[propertyName] = prevEvents

        return accumulator
      }

      accumulator[propertyName] = [
        ...(Array.isArray(prevEvents) ? prevEvents : [prevEvents]),
        ...(Array.isArray(nextEvents) ? nextEvents : [nextEvents]),
      ]

      return accumulator
    }, {} as ResultObject)
}