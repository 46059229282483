import styled from "styled-components";
import theme from "../../../../../../styles/theme";

const Item = styled.div<Item.Props>`
	min-width: max-content;

	color: ${({ selected }) =>
		selected ? theme.colors.primary : theme.colors.secondary};

	${({ selected }) =>
		selected ? "box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.15);" : ""}

	border-radius: 5px 5px 0 0;

	user-select: none;
	display: flex !important;
	align-items: center !important;
	gap: 3.5px;
	padding: 12px 14px 12px 11px !important;
	cursor: pointer;
	box-sizing: border-box;

	font-family: Lato;
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: 16px;

	display: flex;
	align-items: center;
	text-align: center;

	background: ${({ selected }) =>
		selected ? theme.colors.white : theme.colors.transparent} !important;
`;

declare namespace Item {
	export interface Props {
		selected: boolean;
	}
}

export default Item;
