import { EventEmitter } from 'events'
import Position from '../../../../types/Position';
import SizeLiteral from '../../../../types/SizeLiteral';
import { ControllerBase } from "../../../../utils/react";
import { RefObject } from 'react';

class Controller extends ControllerBase<Controller.Context> {
  private eventEmitter = new EventEmitter()
  
  public getPosition(): Position {
    const { x, y } = this.context?.rootRef.current?.getBoundingClientRect() ?? { x: 0, y: 0 };
    
    return { x, y }
  }

  public getSize(): SizeLiteral {
    const { width, height } = this.context?.rootRef.current?.getBoundingClientRect() ?? { width: 0, height: 0 };
    
    return { width, height }
  }

  public on(name: "size", listener: (size: SizeLiteral) => void): this
  public on(name: "move", listener: (position: Position) => void): this
  public on(name: "size" | "move", listener: ((size: SizeLiteral) => void) | ((position: Position) => void)): this {
    this.eventEmitter.on(name, listener)

    return this
  }

  public off(name: "size", listener: (size: SizeLiteral) => void): this
  public off(name: "move", listener: (position: Position) => void): this
  public off(name: "size" | "move", listener: ((size: SizeLiteral) => void) | ((position: Position) => void)): this {
    this.eventEmitter.off(name, listener)

    return this
  }

  public emit(name: "size", size: SizeLiteral): boolean
  public emit(name: "move", position: Position): boolean
  public emit(name: "size" | "move", value: SizeLiteral | Position): boolean {
    return this.eventEmitter.emit(name, value)
  }
}

declare namespace Controller {
  interface Context {
    rootRef: RefObject<HTMLElement | null>
  }
}

export default Controller
