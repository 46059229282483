import React, { FC, ReactElement, useContext } from "react";
import Grid from "../..";
import useCursorPosition from "../../../../hooks/useCursorPosition";
import { calculateItemResize, startResizing, stopResizing } from "../../utils";
import { ItemContext, RenderItemContext } from "../Item";
import { ResizeItemLayout, ResizeItemLayoutContext } from "./components/ResizeItemLayout";

export interface ResizableCoreGridContextState {

}

export interface Props {
  children?: ReactElement

  active: boolean
}

export const ResizableCore: FC<Props> = ({
  children,

  active,
}) => {
  const cursorPosition = useCursorPosition()

  const grid = useContext(Grid.Context)
  const item = useContext(ItemContext)

  let itemRect: Grid.LayoutItemRect | { } = { }

  if (grid && item && item.resize) {
    itemRect = calculateItemResize({
      cursorPosition,
      cursorOffset: {
        x: item.resize.cursorOffsetX,
        y: item.resize.cursorOffsetY,
      },

      grid,
      item,

      type: item.resize.type,
    })
  }

  return grid && item && active
    ? (
      <ResizeItemLayoutContext.Provider
        value={{
          onResizeStart(cursorX, cursorY, type) {
            grid.setLayout(startResizing({
              grid,
              item,

              type,

              cursorX,
              cursorY,
            }))
          },

          onResizeStop(cursorX, cursorY) {
            grid.setLayout(stopResizing({
              grid,
              item,

              cursorX,
              cursorY,
            }))
          },
        }}
      >
        <RenderItemContext.Provider value={{
          ...itemRect,
          
          ItemLayout: ResizeItemLayout,
        }}>
          {children}
        </RenderItemContext.Provider>
      </ResizeItemLayoutContext.Provider>
    )
    : children ?? null
}
