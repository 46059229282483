import styled from "styled-components";

const Root = styled.div`
	position: relative;

	width: 100%;
	height: 100%;

	pointer-events: none !important;

	z-index: 1000;

	& > * {
		pointer-events: all;
	}
`;

export default Root;
