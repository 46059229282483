import { useEffect, useMemo } from "react";
import Position from "../types/Position";
import useInternal from "./useInternal";

class Updater {
    callback?: () => void
    timeout: NodeJS.Timeout | null
    running: boolean = false

    constructor(callback?: () => void) {
        this.callback = callback;
        this.timeout = null;
    }

    run() {
        if (this.running) this.stop();

        this.running = true;

        const callback = () => {
            this.callback?.();

            // if (this.running) this.timeout = setTimeout(callback, 10);
        };

        this.timeout = setTimeout(callback, 10);
    }

    stop() {
        if (this.timeout)
            clearTimeout(this.timeout);

        this.running = false;
    }
}

function getPosition(element: HTMLElement | null): Position {
    const { x, y } = element?.getBoundingClientRect() ?? { x: 0, y: 0 };
    
    return { x, y }
}

export default function usePosition(element: HTMLElement | null): Position {
    const [position, setPosition] = useInternal<Position>(getPosition(element), { deep: true });
    const updater = useMemo(() => new Updater(), []);

    useEffect(() => () => updater.stop(), []);

    function callback() {
        if (!element) return;

        const { x, y } = getPosition(element);

        if (position.x !== x || position.y !== y) {
            setPosition({ x, y })
        }
    }

    updater.callback = callback;

    updater.stop();

    if (element) updater.run();

    return { x: position.x, y: position.y };
}
