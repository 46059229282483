import styled from "styled-components"
import theme from "../../styles/theme"

interface LabelProps {
	active: boolean
	fontWidth?: number
}

export const StyledLabel = styled.div<LabelProps>`
	color: ${props => props.active
		? theme.colors.primary
		: theme.colors.secondary
	};
	font-family: ${theme.font.n};
	font-style: normal;
	font-weight: ${props => props.fontWidth ?? 400};
	font-size: 13px;
`
