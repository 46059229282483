import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { debounce, throttle } from "lodash";
import useInternal from "./useInternal";
import Nullable from "../types/Nullable";

export default function useUncontrolledValue<V>(
	value: V,
	onChange: Nullable<(newValue: V) => void>,
	options?: {
		debounce?: number;
		throttle?: number;
	},
) {
	const [internalValue, setInternalValue] = useInternal(value);

	const modifiedOnChange = useMemo(() => {
		if (!onChange) return () => {};

		if (options?.debounce) return debounce(onChange, options.debounce);

		if (options?.throttle) return throttle(onChange, options.throttle);

		return onChange;
	}, [onChange, options?.debounce, options?.throttle]);

	const onInternalChange = useCallback<Dispatch<SetStateAction<V>>>(
		(valueOrCallback: SetStateAction<V>) => {
			setInternalValue((prev) => {
				const newValue =
					typeof valueOrCallback === "function"
						? (valueOrCallback as (prevState: V) => V)(prev)
						: valueOrCallback;

				modifiedOnChange(newValue);
				return newValue;
			});
		},
		[modifiedOnChange, setInternalValue],
	);

	return [internalValue, onInternalChange] as const;
}
