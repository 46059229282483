import { clone } from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

export interface Position {
  x: number
  y: number
}

interface CursorPositionHandlerOptions {
  offset?: Position

  set: Dispatch<SetStateAction<Position>>
}

const cursorPosition: Position = {
  x: 0,
  y: 0,
}

const setCursorPositionHandlers: CursorPositionHandlerOptions[] = [ ]

function handler(event: MouseEvent) {
  if (cursorPosition.x === event.clientX && cursorPosition.y === event.clientY) {
    return
  }

  cursorPosition.x = event.clientX
  cursorPosition.y = event.clientY

  setCursorPositionHandlers.forEach(setCursorPositionHandler => {
    const localCursorPosition = clone(cursorPosition)

    if (setCursorPositionHandler.offset) {
      localCursorPosition.x -= setCursorPositionHandler.offset.x
      localCursorPosition.y -= setCursorPositionHandler.offset.y
    }
    
    setCursorPositionHandler.set(localCursorPosition)
  })
}

document.addEventListener('mousedown', handler)
document.addEventListener('mousemove', handler)
document.addEventListener('mouseup', handler)

export default function useCursorPosition(offset?: Position) {
  const [ internalCursorPosition, setInternalCursorPosition ] = useState<Position>({ ...cursorPosition })

  useEffect(() => {
    const setCursorPositionHandler = {
      offset,
      
      set: setInternalCursorPosition,
    }

    if (!setCursorPositionHandlers.includes(setCursorPositionHandler))
      setCursorPositionHandlers.push(setCursorPositionHandler)

    return () => {
      const index = setCursorPositionHandlers.indexOf(setCursorPositionHandler)

      if (index !== -1)
        setCursorPositionHandlers.splice(index, 1)
    }
  }, [])

  return internalCursorPosition;
}
