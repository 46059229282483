import styled, { CSSProperties } from "styled-components";
import { Size } from "../../../../utils/flex";
import { componentPropToCSSProp } from "../../../../utils/style";
import { allEquals } from "../../../../utils/array";

function isRowDirection(dir: Root.Props["dir"]) {
	return dir === "row" || dir === "row-reverse";
}

function styleSize(size: Size, dir: Root.Props["dir"]) {
	return `
        flex: ${
			size.type === "fr"
				? `${size.value} 0 0%`
				: `0 ${size.strict ? 0 : 1} ${size.value}`
		};

        ${
			size.strict
				? `min-${isRowDirection(dir) ? "width" : "height"}: ${
						size.value
				  };`
				: ""
		}
    `;
}

const Root = styled.div<Root.Props>`
	display: flex;

	${({ maxedWidth }) => (maxedWidth ? "width: 100%;" : "")}
	${({ maxedHeight }) => (maxedHeight ? "height: 100%;" : "")}

    ${componentPropToCSSProp({
		dir: "flex-direction",
		wrap: "flex-wrap",
		align: "align-items",
		justify: "justify-content",
	})}

    ${({ gaps, gapsCount }) =>
		gaps.length && allEquals(gaps) && gaps.length === gapsCount
			? `gap: ${gaps[0]};`
			: ""}

    & > * {
		${({ dir, wrap, gaps, gapsCount }) =>
			wrap === "wrap" || (allEquals(gaps) && gaps.length === gapsCount)
				? ""
				: gaps
						.map(
							(gap, index) => `
                &:nth-child(${index + 1}) {
                    margin-${dir === "row" ? "right" : "bottom"}: ${gap};
                }
                `,
						)
						.join("\n")}

		${({ dir, sizes, sizesCount }) =>
			sizes.length && allEquals(sizes) && sizes.length === sizesCount
				? styleSize(sizes[0], dir)
				: sizes
						.map(
							(size, index) => `
                &:nth-child(${index + 1}) {
                    ${styleSize(size, dir)}
                }
                `,
						)
						.join("\n")}
	}
`;

declare namespace Root {
	export interface Props {
		dir?: CSSProperties["flexDirection"];
		wrap?: CSSProperties["flexWrap"];
		align?: CSSProperties["alignItems"];
		justify?: CSSProperties["justifyContent"];
		sizes: Size[];
		sizesCount: number;
		gaps: string[];
		gapsCount: number;

		maxedWidth: boolean;
		maxedHeight: boolean;
	}
}

export default Root;
