import React, { createContext, ReactElement, useMemo, useRef, useState } from 'react';
import useChanged from '../../hooks/useChanged';
import useSize from '../../hooks/useSize';
import { DefaultItemLayout } from './components/DefaultItemLayout';
import { Root } from "./components/Root";
import { calculateGrid, getLayoutItems, mapChildrenByKey, processLayout } from './utils';
import { GridContextOptions, GridContextState, GridOptions, PrivateLayoutItemConfig } from './types';
import { ItemController } from './components/ItemController';
import { Container } from './components/Container';
import { InputifiedComponentProps, inputify } from '../../utils/react';
import { extend } from 'lodash';
import {
  ItemLayout as InternalItemLayout,
  Layout as InternalLayout,
  StartDraggingOptions as InternalStartDraggingOptions,
  LayoutItemRect as InternalLayoutItemRect,
  DragAreaContextOptions as InternalDragAreaContextOptions,
  LayoutItemContextOptions as InternalLayoutItemContextOptions,
  GroupAreaContextOptions as InternalGroupAreaContextOptions
} from "./types"

import * as Item from "./components/Item"

const Context = createContext<GridContextOptions | null>(null)

const Grid = extend(
  inputify<Grid.PropsBase, Grid.Value>(({
    children,
  
    value,
  
    columnSize = 1,
    rowSize = 1,
  
    columns,
    rows,
  
    gap = 0,
  
    ItemLayout = DefaultItemLayout,
    
    onChange,
  }) => {
    const processedLayout = useMemo(() => processLayout(value ?? []), [value])
  
    const [rootElement, setRootElement] = useState<HTMLDivElement | null>(null)
  
    const rootSize = useSize(rootElement)
  
    const stateRef = useRef<GridContextState>({
      currentStartDraggingOptions: null,
      hoveredItems: []
    })
  
    const grid = calculateGrid({
      columnSize,
      rowSize,
      columns,
      rows,
      gap,
    }, rootSize)
  
    const mappedChildren = useMemo<Record<string, ReactElement>>(
      () => mapChildrenByKey(children),
      [children]
    )
  
    const layoutItems = useChanged<
      InternalLayout,
      Record<string, PrivateLayoutItemConfig>
    >(
      (_, prevLayoutItems) => getLayoutItems(prevLayoutItems, processedLayout),
      processedLayout
    )!
  
    return (
      <Context.Provider value={{
        ...rootSize,
        
        layout: processedLayout,
        layoutItems,
  
        state: stateRef.current,
  
        setLayout: onChange,
  
        ...grid,
      }}>
        <Root ref={setRootElement}>
          <Container>
            {processedLayout.map((item, itemIndex) => (
              <ItemController
                key={item.id}
  
                item={item}
                itemIndex={itemIndex}
  
                ItemLayout={ItemLayout}
              >
                {mappedChildren}
              </ItemController>
            ))}
          </Container>
        </Root>
      </Context.Provider>
    )
  }),
  {
    Context,
    Item,
  }
)

declare namespace Grid {
  type Value = Layout

  interface PropsBase extends Partial<GridOptions> {
    children: ReactElement[]

    ItemLayout?: InternalItemLayout
  }

  type Props = PropsBase & InputifiedComponentProps<Value>

  type ItemLayout = InternalItemLayout
  type Layout = InternalLayout
  type StartDraggingOptions = InternalStartDraggingOptions
  type LayoutItemRect = InternalLayoutItemRect
  type DragAreaContextOptions = InternalDragAreaContextOptions
  type LayoutItemContextOptions = InternalLayoutItemContextOptions
  type GroupAreaContextOptions = InternalGroupAreaContextOptions
}

export default Grid
