import React from "react";
import Icon from "../components/Icon";

Icon.register("check-mark", {
	viewBox: [0, 0, 16, 16],
	node: (
		<path
			fill="var(--icon-color-1)"
			d="M7.43928 11.0103C7.04828 11.4013 6.41528 11.4013 6.02528 11.0103L4.48928 9.47531C4.09928 9.08431 4.09928 8.45131 4.48928 8.06031C4.88028 7.67031 5.51328 7.67031 5.90328 8.06031L6.73228 8.88931L10.0963 5.52531C10.4863 5.13431 11.1193 5.13431 11.5103 5.52531C11.9013 5.91531 11.9013 6.54831 11.5103 6.93931L7.43928 11.0103Z"
		/>
	),
});

Icon.register("left-arrow", {
	viewBox: [0, 0, 18, 18],
	node: (
		<path
			fill="var(--icon-color-1)"
			d="M11.5575 12.4425L8.1225 9L11.5575 5.5575L10.5 4.5L6 9L10.5 13.5L11.5575 12.4425Z"
		/>
	),
});

Icon.register("right-arrow", {
	viewBox: [0, 0, 18, 18],
	node: (
		<path
			fill="#707683"
			d="M6.4425 5.5575L9.8775 9L6.4425 12.4425L7.5 13.5L12 9L7.5 4.5L6.4425 5.5575Z"
		/>
	),
});

Icon.register("down-arrow", {
	viewBox: [0, 0, 8, 6],
	node: (
		<path
			fill="var(--icon-color-1)"
			d="M7.06 0.726748L4 3.78008L0.94 0.726748L-1.74846e-07 1.66675L4 5.66675L8 1.66675L7.06 0.726748Z"
		/>
	),
});

Icon.register("stepper-arrow-up", {
	viewBox: [0, 0, 13, 13],
	node: (
		<>
			<path
				d="M2.75 8.75L6.5 4.25L10.25 8.75L2.75 8.75Z"
				fill="var(--icon-color-1)"
			/>
		</>
	),
});

Icon.register("stepper-arrow-down", {
	viewBox: [0, 0, 13, 13],
	node: (
		<>
			<path
				d="M10.25 4.25L6.5 8.75L2.75 4.25L10.25 4.25Z"
				fill="var(--icon-color-1)"
			/>
		</>
	),
});

Icon.register("dragging", {
	viewBox: [0, 0, 12, 12],
	node: (
		<>
			<path
				fill="var(--icon-color-1)"
				d="M3.96401 2.78568C4.55574 2.78568 5.03544 2.30598 5.03544 1.71425C5.03544 1.12252 4.55574 0.642822 3.96401 0.642822C3.37227 0.642822 2.89258 1.12252 2.89258 1.71425C2.89258 2.30598 3.37227 2.78568 3.96401 2.78568Z"
			/>
			<path
				fill="var(--icon-color-1)"
				d="M3.96401 7.07138C4.55574 7.07138 5.03544 6.59169 5.03544 5.99996C5.03544 5.40822 4.55574 4.92853 3.96401 4.92853C3.37227 4.92853 2.89258 5.40822 2.89258 5.99996C2.89258 6.59169 3.37227 7.07138 3.96401 7.07138Z"
			/>
			<path
				fill="var(--icon-color-1)"
				d="M3.96401 11.3571C4.55574 11.3571 5.03544 10.8774 5.03544 10.2857C5.03544 9.69394 4.55574 9.21425 3.96401 9.21425C3.37227 9.21425 2.89258 9.69394 2.89258 10.2857C2.89258 10.8774 3.37227 11.3571 3.96401 11.3571Z"
			/>
			<path
				fill="var(--icon-color-1)"
				d="M8.24989 2.78571C8.84163 2.78571 9.32132 2.30601 9.32132 1.71428C9.32132 1.12255 8.84163 0.642853 8.24989 0.642853C7.65816 0.642853 7.17847 1.12255 7.17847 1.71428C7.17847 2.30601 7.65816 2.78571 8.24989 2.78571Z"
			/>
			<path
				fill="var(--icon-color-1)"
				d="M8.24989 7.07141C8.84163 7.07141 9.32132 6.59172 9.32132 5.99999C9.32132 5.40825 8.84163 4.92856 8.24989 4.92856C7.65816 4.92856 7.17847 5.40825 7.17847 5.99999C7.17847 6.59172 7.65816 7.07141 8.24989 7.07141Z"
			/>
			<path
				fill="var(--icon-color-1)"
				d="M8.24989 11.3571C8.84163 11.3571 9.32132 10.8774 9.32132 10.2857C9.32132 9.69397 8.84163 9.21428 8.24989 9.21428C7.65816 9.21428 7.17847 9.69397 7.17847 10.2857C7.17847 10.8774 7.65816 11.3571 8.24989 11.3571Z"
			/>
		</>
	),
});

Icon.register("clock", {
	viewBox: [0, 0, 16, 16],
	node: (
		<path
			fill="var(--icon-color-1)"
			d="M7.99241 0.380951C3.7867 0.380951 0.380981 3.79428 0.380981 8C0.380981 12.2057 3.7867 15.619 7.99241 15.619C12.2057 15.619 15.6191 12.2057 15.6191 8C15.6191 3.79428 12.2057 0.380951 7.99241 0.380951ZM10.5067 11.5886L7.23812 8.31238V4.19047H8.76193V7.68762L11.5886 10.5143L10.5067 11.5886Z"
		/>
	),
});

Icon.register("calendar", {
	viewBox: [0, 0, 16, 16],
	node: (
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14 13.3333V4.00001C14 3.26668 13.4 2.66668 12.6667 2.66668H12V1.33334H10.6667V2.66668H5.33333V1.33334H4V2.66668H3.33333C2.59333 2.66668 2.00667 3.26668 2.00667 4.00001L2 13.3333C2 14.0667 2.59333 14.6667 3.33333 14.6667H12.6667C13.4 14.6667 14 14.0667 14 13.3333ZM6.00003 7.33334H4.6667V8.66668H6.00003V7.33334ZM3.3333 5.33334H12.6666V4.00001H3.3333V5.33334ZM12.6666 6.66667V13.3333H3.3333V6.66667H12.6666ZM10 8.66668H11.3333V7.33334H10V8.66668ZM8.66664 8.66668H7.3333V7.33334H8.66664V8.66668Z"
			fill="var(--icon-color-1)"
			// fill-opacity="0.7"
		/>
	),
});
