/* eslint-disable no-shadow */

import {
	Dispatch,
	RefCallback,
	SetStateAction,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import useContextedFunction, {
	ContextedFunction,
} from "./useContextedFunction";

export interface Size {
	width: number;
	height: number;
}

export interface UseRefSizeResult {
	ref: RefCallback<HTMLElement | null>;
	size: Size;
}

interface OnResizeContext {
	element?: HTMLElement | null;
	size: Size;
	setSize: Dispatch<SetStateAction<Size>>;
}

const onResize: ContextedFunction<OnResizeContext> = function a() {
	if (!this.element) return;

	const { width, height } = this.element.getBoundingClientRect();

	if (this.size.width !== width || this.size.height !== height)
		this.setSize({ width, height });
};

export default function useRefSize() {
	const [size, setSize] = useState<Size>({ width: 0, height: 0 });

	const sizeRef = useRef(size);

	sizeRef.current = size;

	const callbackContext: OnResizeContext = {
		element: null,
		size,
		setSize,
	};

	const [onContextedResize, setOnResizeContext] = useContextedFunction(
		onResize,
		callbackContext,
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const observerRef = useRef(new ResizeObserver(onContextedResize));
	const onContextedResizeRef = useRef(onContextedResize);
	const setOnResizeContextRef = useRef(setOnResizeContext);

	const ref = useCallback((element: HTMLElement | null) => {
		observerRef.current.disconnect();

		setOnResizeContextRef.current({
			element,
			size: sizeRef.current,
			setSize,
		});

		onContextedResizeRef.current();

		if (element) observerRef.current.observe(element);
	}, []);

	useEffect(() => {
		window.addEventListener("resize", onContextedResizeRef.current);

		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			window.removeEventListener("resize", onContextedResizeRef.current);

			// eslint-disable-next-line react-hooks/exhaustive-deps
			observerRef.current.disconnect();
		};
	}, []);

	return { ref, size };
}
