import { useEffect } from "react";
import useChanged from "./useChanged";
import useContextedFunction, { ContextedFunction } from "./useContextedFunction";

type ElementTypeBase = Document | Window | HTMLElement
type EventType<ElementType extends ElementTypeBase = ElementTypeBase> = (
  (ElementType extends Window ? WindowEventMap : never) |
  (ElementType extends Document ? DocumentEventMap : never) |
  (ElementType extends HTMLElement ? HTMLElementEventMap : never) |
  (ElementType extends Window | Document | HTMLElement ? never : Record<string, unknown>)
)
type NameTypeBase<ElementType extends ElementTypeBase = ElementTypeBase> =
  Exclude<keyof EventType<ElementType>, number | symbol>

type Callback<
  ElementType extends ElementTypeBase = ElementTypeBase,
  NameType extends NameTypeBase<ElementType> = NameTypeBase<ElementType>
> = (
  this: ElementType,
  event: EventType<ElementType>[NameType]
) => any

interface UnmountHandlerContext<
  ElementType extends ElementTypeBase = ElementTypeBase,
  NameType extends NameTypeBase<ElementType> = NameTypeBase<ElementType>
> {
  element: ElementType | null,
  name: NameType,
  callback: Callback<ElementType, NameType> | null | undefined
}

interface MountHandlerContext<
  ElementType extends ElementTypeBase = ElementTypeBase,
  NameType extends NameTypeBase<ElementType> = NameTypeBase<ElementType>
> extends UnmountHandlerContext<ElementType, NameType> {
  unmountHandler: () => void
}

const unmountHandler: ContextedFunction<UnmountHandlerContext> = function () {
  if (this.callback)
    this.element?.removeEventListener(this.name, this.callback)
}

const mountHandler: ContextedFunction<MountHandlerContext> = function () {
  if (this.callback)
    this.element?.addEventListener(this.name, this.callback)

  return this.unmountHandler
}

export default function useEvent<
  ElementType extends ElementTypeBase = ElementTypeBase,
  NameType extends NameTypeBase<ElementType> = NameTypeBase<ElementType>
>(
  element: ElementType | null,
  name: NameType,
  callback: Callback<ElementType, NameType> | null | undefined,
) {
  useChanged<[typeof element, typeof callback], void>((prevValue) => {
    if (!prevValue)
      return

    const [prevElement, prevCallback] = prevValue

    if (prevCallback)
      prevElement?.removeEventListener(name, prevCallback as unknown as EventListenerOrEventListenerObject)

    if (callback)
      element?.addEventListener(name, callback as unknown as EventListenerOrEventListenerObject)
  }, [element, callback])

  const unmountHandlerContext: UnmountHandlerContext = {
    element,
    name: name as UnmountHandlerContext["name"],
    callback: callback as UnmountHandlerContext["callback"],
  }

  const [contextedUnmountHandler, setUnmountHandlerContext] =
    useContextedFunction(unmountHandler, unmountHandlerContext)

  setUnmountHandlerContext(unmountHandlerContext)

  const mountHandlerContext: MountHandlerContext = {
    ...unmountHandlerContext,
    unmountHandler: contextedUnmountHandler,
  }

  const [contextedMountHandler, setMountHandlerContext] =
    useContextedFunction(mountHandler, mountHandlerContext)

  setMountHandlerContext(mountHandlerContext)

  useEffect(contextedMountHandler, [])
}
