/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import { extend } from "lodash";
import React, {
	forwardRef,
	PropsWithChildren,
	useCallback,
	useLayoutEffect,
	useMemo,
	useRef,
} from "react";
import { TileLayer } from "react-leaflet";
import { LatLngExpression, Map as LeafletMap } from "leaflet";
import { on } from "events";
import StyleProps from "../../types/StyleProps";
import { ZoomController } from "./components/ZoomController";
import { CenterController } from "./components/CenterController";
import MapBase from "./components/MapBase";
import Root from "./components/Root";
import InternalPolygonEditor from "./components/PolygonEditor";
import { setRefValue } from "../../utils/react";
import useChanged from "../../hooks/useChanged";
import useRefSize from "../../hooks/useRefSize";

const Map = extend(
	forwardRef<LeafletMap, Map.Props>(
		(
			{
				children,

				style,
				className,

				language,

				tileURL,

				center,
				onChangeCenter,

				zoomControl = true,
				maxZoom,
				minZoom,
				zoom,
				onChangeZoom,

				onInit,
				onReady,
			},
			ref,
		) => {
			const defaultLanguage = useMemo(() => "uk", []);

			language = language ?? defaultLanguage;

			const defaultTileURL = useMemo(
				() =>
					`https://tms2.visicom.ua/2.0.0/planet3/base/{z}/{x}/{y}.png?lang=${language}`,
				[language],
			);

			tileURL = tileURL ?? defaultTileURL;

			const isMapReadyRef = useRef(false);
			const isOnReadyCalledRef = useRef(false);
			const { ref: rootRef, size: rootSize } = useRefSize();
			const mapRef = useRef<LeafletMap | null>(null);

			const checkMapReady = useCallback(() => {
				if (
					!isMapReadyRef.current ||
					!mapRef.current ||
					isOnReadyCalledRef.current
				)
					return;

				onReady?.();

				isOnReadyCalledRef.current = true;
			}, [onReady]);

			const checkMapReadyRef = useRef(checkMapReady);
			checkMapReadyRef.current = checkMapReady;

			useChanged(
				() => {
					if (!mapRef.current) return;

					mapRef.current.invalidateSize();
				},
				rootSize,
				true,
			);

			const mapRefSetter = useCallback(
				(map: LeafletMap | null) => {
					mapRef.current = map;

					setRefValue(ref, map);

					if (mapRef.current) {
						onInit?.call(null, mapRef.current);

						mapRef.current.invalidateSize();
					}

					checkMapReadyRef.current();
				},
				[onInit, ref],
			);

			const mapBaseWhenReady = useCallback(() => {
				isMapReadyRef.current = true;

				checkMapReadyRef.current();
			}, []);

			return (
				<Root ref={rootRef}>
					<MapBase
						ref={mapRefSetter}
						style={style}
						className={className}
						center={center}
						doubleClickZoom={false}
						zoom={zoom}
						zoomControl={false}
						minZoom={minZoom}
						maxZoom={maxZoom}
						whenReady={mapBaseWhenReady}
					>
						<CenterController onChangeCenter={onChangeCenter} />

						<ZoomController
							showButtons={zoomControl}
							onChangeZoom={onChangeZoom}
						/>

						<TileLayer
							url={tileURL}
							subdomains="123"
							maxZoom={maxZoom}
							minZoom={minZoom}
							tms
						/>
						{children}
					</MapBase>
				</Root>
			);
		},
	),
	{
		PolygonEditor: InternalPolygonEditor,
	},
);

declare namespace Map {
	interface Props extends PropsWithChildren, StyleProps {
		language?: string;

		tileURL?: string;

		center: LatLngExpression;
		onChangeCenter?: (coordinates: LatLngExpression) => void;

		zoomControl?: boolean;
		maxZoom?: number;
		minZoom?: number;
		zoom?: number;
		onChangeZoom?: (zoom: number) => void;

		onInit?: (map: LeafletMap) => void;
		onReady?: () => void;
	}

	namespace PolygonEditor {
		type Value = InternalPolygonEditor.Value;
		type Props = InternalPolygonEditor.Props;
		type Controller = InternalPolygonEditor.Controller;

		namespace Controller {
			type Context = InternalPolygonEditor.Controller.Context;
		}
	}
}

export default Map;
