import styled from "styled-components";

const FillHeight = styled.div`
	position: relative;

	height: 100%;

	overflow: hidden;

	& > div[role="grid"] {
		position: absolute;

		top: 0;
		left: 0;

		width: 100% !important;
		height: 100%;
	}
`;

export default FillHeight;
