import { LatLngExpression, LeafletEvent } from "leaflet";
import { useMapEvent } from "react-leaflet";
import { FC } from "react";
import useContextedFunction from "../../../../hooks/useContextedFunction";
import React from "react";

interface EventHandlerContext extends Props {
}

function eventHandler(this: EventHandlerContext, event: LeafletEvent) {
  this.onChangeCenter?.call(null, (event.target.getCenter()))
}

export interface Props {
  onChangeCenter?: (coordinates: LatLngExpression) => void
}

export const CenterController: FC<Props> = ({
  onChangeCenter,
}) => {
  const [contextedEventHandler, setEventHandlerContext] = useContextedFunction(
    eventHandler,
    { onChangeCenter },
  )

  setEventHandlerContext({ onChangeCenter })

  useMapEvent('dragend', contextedEventHandler)
  useMapEvent('moveend', contextedEventHandler)

  return (
    <></>
  )
}
