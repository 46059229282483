import React, { useContext } from "react";
import { FC, ReactElement } from "react";
import Grid from "../..";
import { GridGroupAreaContext } from "../../../GridGroupArea";
import { PrivateLayoutItem } from "../../types";
import { ItemContext } from "../Item";

export interface GroupAreaHoverCoreGridContextState {
  hoveredItems: PrivateLayoutItem["id"][]
}

export interface Props {
  children?: ReactElement
}

export const GroupAreaHoverCore: FC<Props> = ({
  children,
}) => {
  const grid = useContext(Grid.Context)
  const item = useContext(ItemContext)

  return grid && item && (
    <GridGroupAreaContext.Provider value={{
      onGroupAreaEnter: () => {
        if (!grid.state.hoveredItems.includes(item.id))
          grid.state.hoveredItems.push(item.id)
      },

      onGroupAreaLeave: () => {
        if (grid.state.hoveredItems.includes(item.id))
          grid.state.hoveredItems.splice(grid.state.hoveredItems.indexOf(item.id), 1)
      },
    }}>
      {children}
    </GridGroupAreaContext.Provider>
  )
}
