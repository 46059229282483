import { css } from "@storybook/theming";
import theme from "./theme";

export const typography = {
	type: {
		primary: theme.font.def,
	},
	weight: {
		regular: "400",
		bold: "600",
		extrabold: "700",
	},
	size: {
		s1: 10,
		s2: 12,
		s3: 13,
		m1: 14,
		m2: 16,
		m3: 18,
		l1: 20,
		l2: 24,
		l3: 30,
	},
} as const;

// Typography styles
export const headers = {
	h1: css({
		fontSize: typography.size.l3,
		fontWeight: typography.weight.bold,
		lineHeight: "36px",
	}),
	h2: css({
		fontSize: typography.size.l2,
		fontWeight: typography.weight.bold,
		lineHeight: "36px",
	}),
	h3: css({
		fontSize: typography.size.l1,
		fontWeight: typography.weight.bold,
		lineHeight: "36px",
	}),
	h4: css({
		fontSize: typography.size.m3,
		fontWeight: typography.weight.bold,
		lineHeight: "36px",
	}),
	h5: css({
		fontSize: typography.size.m2,
		fontWeight: typography.weight.bold,
		lineHeight: "36px",
	}),
};

export const text = {
	BodyExtrabold: css({
		fontSize: typography.size.m1,
		fontWeight: typography.weight.extrabold,
		lineHeight: "21px",
	}),
	BodyBold: css({
		fontSize: typography.size.m1,
		fontWeight: typography.weight.bold,
		lineHeight: "21px",
	}),
	Body: css({
		fontSize: typography.size.m1,
		fontWeight: typography.weight.regular,
		lineHeight: "21px",
	}),
	SmallBold: css({
		fontSize: typography.size.s3,
		fontWeight: typography.weight.bold,
		lineHeight: "21px",
	}),
	Small: css({
		fontSize: typography.size.s3,
		fontWeight: typography.weight.regular,
		lineHeight: "21px",
	}),
	CaptionBold: css({
		fontSize: typography.size.s2,
		fontWeight: typography.weight.bold,
		lineHeight: "21px",
	}),
	Caption: css({
		fontSize: typography.size.s2,
		fontWeight: typography.weight.regular,
		lineHeight: "21px",
	}),
	TinyBold: css({
		fontSize: typography.size.s1,
		fontWeight: typography.weight.bold,
		lineHeight: "14px",
	}),
	Tiny: css({
		fontSize: typography.size.s1,
		fontWeight: typography.weight.regular,
		lineHeight: "14px",
	}),
};
