import { EventEmitter } from "events";
import { v4 as uuid } from "uuid";
import { ReactNode, RefObject } from "react";
import useRender from "../../../../hooks/useRender";
import { ControllerBase } from "../../../../utils/react";
import Position from "../../../../types/Position";
import SizeLiteral from "../../../../types/SizeLiteral";

class Controller extends ControllerBase<Controller.Context> {
	floats: Record<string, ReactNode> = {};

	order: string[] = [];

	lastUpdateId = "";

	private eventEmitter = new EventEmitter();

	private render() {
		this.context?.render(true);
	}

	public getPosition(): Position {
		const { x, y } =
			this.context?.rootRef.current?.getBoundingClientRect() ?? {
				x: 0,
				y: 0,
			};

		return { x, y };
	}

	public getSize(): SizeLiteral {
		const { width, height } =
			this.context?.rootRef.current?.getBoundingClientRect() ?? {
				width: 0,
				height: 0,
			};

		return { width, height };
	}

	public add(node: ReactNode): string {
		const id = uuid();

		this.floats[id] = node;
		this.order.push(id);

		this.render();

		return id;
	}

	public update(id: string, node: ReactNode) {
		this.floats[id] = node;

		this.lastUpdateId = uuid();

		this.render();
	}

	public delete(id: string) {
		delete this.floats[id];

		this.render();
	}

	public on(name: "size", listener: (size: SizeLiteral) => void): this;

	public on(name: "move", listener: (position: Position) => void): this;

	public on(
		name: "size" | "move",
		listener:
			| ((size: SizeLiteral) => void)
			| ((position: Position) => void),
	): this {
		this.eventEmitter.on(name, listener);

		return this;
	}

	public off(name: "size", listener: (size: SizeLiteral) => void): this;

	public off(name: "move", listener: (position: Position) => void): this;

	public off(
		name: "size" | "move",
		listener:
			| ((size: SizeLiteral) => void)
			| ((position: Position) => void),
	): this {
		this.eventEmitter.off(name, listener);

		return this;
	}

	public emit(name: "size", size: SizeLiteral): boolean;

	public emit(name: "move", position: Position): boolean;

	public emit(name: "size" | "move", value: SizeLiteral | Position): boolean {
		return this.eventEmitter.emit(name, value);
	}
}

declare namespace Controller {
	interface Context {
		render: ReturnType<typeof useRender>;
		rootRef: RefObject<HTMLElement | null>;
	}
}

export default Controller;
