import styled from "styled-components";
import theme from "../../../../styles/theme";

const Root = styled.div<Root.Props>`
	width: max-content;
	display: flex;
	box-sizing: border-box;

	width: 16px;
	height: 16px;

	${({ active }) =>
		active
			? `background: ${theme.colors.accent};`
			: `
			background: ${theme.colors.white};
			border: 1px solid ${theme.colors.disabled_text};
		`}

	${({ disabled, active }) => {
		if (!disabled) return "";

		return disabled && active
			? `filter: grayscale(1) brightness(1.2);`
			: `
			filter: grayscale(1) brightness(1.2);
			background:${theme.colors.disabled_text};
			`;
	}}

	border-radius: 3px;
	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
	user-select: none;

	&:focus {
		border: none;
		outline: 1px solid ${theme.colors.primary};
	}
`;

declare namespace Root {
	export interface Props {
		active: boolean;
		disabled: boolean;
	}
}

export default Root;
