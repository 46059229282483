
export function intersperse<T, S>(array: T[], delimiter: S): (T | S)[] {
	if (array.length <= 1)
		return Array.from(array)

	const newArray = Array<T | S>(array.length * 2 - 1)
	const iter = array.entries()
	newArray[0] = iter.next().value[1]

	let entry: IteratorResult<[number, T], any>

	while (!(entry = iter.next()).done) {
		const i = entry.value[0] * 2
		newArray[i] = entry.value[1]
		newArray[i - 1] = delimiter
	}

	return newArray
}