import React, { FC } from "react";

const Transparent: FC<Transparent.Props> = ({}) => {
	return <></>;
};

declare namespace Transparent {
	export interface Props {}
}

export default Transparent;
