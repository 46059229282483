/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-shadow */

import { clone, extend } from "lodash";
import React, {
	createContext,
	Key,
	ReactElement,
	useCallback,
	useMemo,
} from "react";
import {
	InputifiedComponentProps,
	inputify,
	InputifyComponentProps,
} from "../../utils/react";
import Select from "../Select";
import Option from "./components/Option";
import Selected from "./components/Selected";
import OptionType from "../../types/Option";

const Context = createContext<MultiSelect.Context.Value | null>(null);

const MultiSelect = extend(
	inputify(
		// eslint-disable-next-line prettier/prettier
		<OptionValue, >({
			value,

			all = false,
			options,
			placeholder,

			onChange,

			onSelect,
			onUnselect,

			...props
		}: MultiSelect.InternalProps<OptionValue>) => {
			const areAllOptionsSelected = useMemo(
				() =>
					options?.every((option) => value?.includes(option.key)) ??
					false,
				[options, value],
			);

			const processedOptions = useMemo<
				OptionType<OptionValue | "all">[] | undefined
			>(
				() =>
					all
						? [
								{
									key: "all",
									label: "Все",
									value: "all",
								},
								...(options ?? []),
						  ]
						: options,
				[all, options],
			);

			const selectOnSelect = useCallback(
				(option: OptionType<OptionValue | "all">) => {
					if (option.value === "all") {
						if (areAllOptionsSelected) onChange([]);
						else
							onChange(
								options?.map((option) => option.key) ?? [],
							);

						return;
					}

					const newValue = clone(value) ?? [];

					if (newValue?.includes(option.key)) {
						onUnselect?.(option as OptionType<OptionValue>);

						newValue.splice(newValue.indexOf(option.key), 1);
					} else {
						onSelect?.(option as OptionType<OptionValue>);

						newValue.push(option.key);
					}

					if (options)
						newValue.sort(
							(selected1, selected2) =>
								options.findIndex(
									(option) => option.key === selected1,
								) -
								options.findIndex(
									(option) => option.key === selected2,
								),
						);

					onChange(newValue);
				},
				[
					value,
					areAllOptionsSelected,
					options,
					onChange,
					onSelect,
					onUnselect,
				],
			);

			return (
				<Context.Provider
					value={{
						value,
						all,
						areAllOptionsSelected,
						placeholder,
						options,
						onClick: selectOnSelect,
					}}
				>
					<Select
						{...props}
						options={processedOptions}
						closeOnSelect={false}
						Selected={Selected}
						Option={Option}
						onSelect={selectOnSelect}
					/>
				</Context.Provider>
			);
		},
	) as <OptionValue>(
		props: MultiSelect.Props<OptionValue>,
	) => ReactElement | null,
	{
		Context,
	},
);

declare namespace MultiSelect {
	type Value = Key[];

	interface PropsBase<OptionValue>
		extends Omit<Select.PropsBase<OptionValue>, "Option" | "Selected"> {
		all?: boolean;

		onSelect?: (option: OptionType<OptionValue>) => void;
		onUnselect?: (option: OptionType<OptionValue>) => void;
	}

	type InternalProps<OptionValue> = PropsBase<OptionValue> &
		InputifyComponentProps<Value>;

	type Props<OptionValue> = PropsBase<OptionValue> &
		InputifiedComponentProps<Value>;
}

declare namespace MultiSelect.Context {
	interface Value {
		value?: MultiSelect.Value;

		all: boolean;
		areAllOptionsSelected: boolean;
		placeholder?: string;
		options: MultiSelect.Props<unknown>["options"];

		onClick: (option: OptionType<any>) => void;
	}
}

export default MultiSelect;
