import React from "react"

export interface Props {
	selected: boolean
	disabled?: boolean
}

export const RadioButtonIcon = ({
	selected,
	disabled,
}: Props) => disabled
		? (
			selected
				? ( // Active Disabled
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<mask id="mask0_4583_382" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
							<rect width="16" height="16" rx="8" fill="white" />
						</mask>
						<g mask="url(#mask0_4583_382)">
							<rect width="16" height="16" fill="url(#paint0_linear_4583_382)" />
							<rect width="16" height="16" fill="#E9E9E9" />
						</g>
						<rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#DEE0E2" />
						<mask id="mask1_4583_382" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="5" y="5" width="6" height="6">
							<circle cx="8" cy="8" r="3" fill="white" />
						</mask>
						<g mask="url(#mask1_4583_382)">
							<rect width="16" height="16" fill="#9BA3A8" />
						</g>
						<defs>
							<linearGradient id="paint0_linear_4583_382" x1="16" y1="16" x2="16" y2="0" gradientUnits="userSpaceOnUse">
								<stop stopColor="#F2F4F7" />
								<stop offset="1" stopColor="white" />
							</linearGradient>
						</defs>
					</svg>
				)
				: ( // Inactive Disabled
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<mask id="mask0_4583_149" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
							<rect width="16" height="16" rx="8" fill="white" />
						</mask>
						<g mask="url(#mask0_4583_149)">
							<rect width="16" height="16" fill="url(#paint0_linear_4583_149)" />
							<rect width="16" height="16" fill="#E9E9E9" />
						</g>
						<rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#DEE0E2" />
						<defs>
							<linearGradient id="paint0_linear_4583_149" x1="16" y1="16" x2="16" y2="0" gradientUnits="userSpaceOnUse">
								<stop stopColor="#F2F4F7" />
								<stop offset="1" stopColor="white" />
							</linearGradient>
						</defs>
					</svg>
				)
		)
		: (
			selected
				? ( // Selected
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<mask id="mask0_4583_126" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
							<rect width="16" height="16" rx="8" fill="white" />
						</mask>
						<g mask="url(#mask0_4583_126)">
							<rect width="16" height="16" fill="#03A9F4" />
						</g>
						<rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="black" strokeOpacity="0.1" />
						<mask id="mask1_4583_126" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="5" y="5" width="6" height="6">
							<circle cx="8" cy="8" r="3" fill="white" />
						</mask>
						<g mask="url(#mask1_4583_126)">
							<rect width="16" height="16" fill="white" />
						</g>
					</svg>
				)
				: ( // Inactive
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<mask id="mask0_4583_160" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
							<rect width="16" height="16" rx="8" fill="white" />
						</mask>
						<g mask="url(#mask0_4583_160)">
							<rect width="16" height="16" fill="url(#paint0_linear_4583_160)" />
						</g>
						<rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#9BA3A8" />
						<defs>
							<linearGradient id="paint0_linear_4583_160" x1="16" y1="16" x2="16" y2="0" gradientUnits="userSpaceOnUse">
								<stop stopColor="#F2F4F7" />
								<stop offset="1" stopColor="white" />
							</linearGradient>
						</defs>
					</svg>
				)
		)