import { defaults, isEqual } from "lodash";
import { useRef } from "react";

function usePrevValue<T>(value: T, options?: usePrevValue.Options): T | undefined {
  options = defaults(options ?? { }, usePrevValue.defaultOptions)
  
  const prevValueRef = useRef<T | undefined>(undefined)
  const currentValueRef = useRef<T | undefined>(undefined)

  if (!options.checkEquals || !isEqual(currentValueRef.current, value)) {
    prevValueRef.current = currentValueRef.current
    currentValueRef.current = value
  }

  return prevValueRef.current
}

namespace usePrevValue {
  export const defaultOptions: Options = {
    checkEquals: true,
  }
  
  export interface Options {
    checkEquals?: boolean
  }
}

export default usePrevValue
