import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { styled } from "@mui/system";
import { DateCalendar, DateView } from "@mui/x-date-pickers";
import { Button, Typography } from "@mui/material";
import Column from "../Column";
import theme from "../../styles/theme";
import { inputify } from "../../utils/react";
import Row from "../Row";

const StyledRoot = styled(Column)`
	width: fit-content;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px 2px;
	border-radius: 5px;
	overflow: hidden;
`;

const StyledDate = styled(Typography)`
	background-color: ${theme.colors.primary};
	color: white;
	font-size: 32px;
	font-weight: 400;
	padding: 16px 24px;

	.year {
		font-size: 16px;
	}
`;

const StyledDateCalendar = styled(DateCalendar)`
	& .Mui-selected {
		background-color: ${theme.colors.accent} !important;
	}
` as typeof DateCalendar;

const Calendar = inputify<Calendar.Props, Calendar.Value>(
	({
		value = new Date(),
		onChange,
		onSubmit,
		onCancel,
		minDate,
		maxDate,
		locale,
	}) => {
		const [date, setDate] = useState(moment(value));
		const [view, setView] = useState<DateView>("day");

		const minMomentDate = useMemo(
			() => (minDate ? moment(minDate) : undefined),
			[minDate],
		);
		const maxMomentDate = useMemo(
			() => (maxDate ? moment(maxDate) : undefined),
			[maxDate],
		);

		useEffect(() => {
			setDate(moment(value));
		}, [value]);

		useEffect(() => {
			if (locale?.moment) date.locale(locale.moment);
		}, [date, locale?.moment]);

		const today = useCallback(() => {
			const today = moment(value);
			const now = moment(new Date());

			today.date(now.date());
			today.month(now.month());
			today.year(now.year());

			setDate(today);
			onChange(today.toDate());
		}, [value, setDate]);

		const confirm = useCallback(() => {
			onSubmit?.(date.toDate());
		}, [onSubmit, date]);

		const cancel = useCallback(() => {
			onCancel?.();
		}, [onCancel]);

		return (
			<StyledRoot align="stretch">
				<StyledDate>
					<div className="year">{date.year()}</div>

					{date.toDate().toLocaleDateString("ru-RU", {
						weekday: "short",
						day: "numeric",
						month: "short",
					})}
				</StyledDate>

				<StyledDateCalendar
					value={date}
					onChange={(newValue) => {
						if (newValue) {
							setDate(newValue);
							onChange(newValue.toDate());
						}
					}}
					views={["year", "month", "day"]}
					view={view}
					onViewChange={setView}
					showDaysOutsideCurrentMonth
					maxDate={maxMomentDate}
					minDate={minMomentDate}
				/>

				<Row
					justify="space-between"
					align="center"
					style={{
						paddingInline: "16px",
						paddingBlock: "8px",
					}}
				>
					<Button color="inherit" variant="text" onClick={today}>
						{locale?.todayButton ?? "today"}
					</Button>

					<Row>
						<Button color="inherit" variant="text" onClick={cancel}>
							{locale?.cancelButton ?? "cancel"}
						</Button>
						<Button
							color="inherit"
							variant="text"
							onClick={confirm}
						>
							{locale?.submitButton ?? "ok"}
						</Button>
					</Row>
				</Row>
			</StyledRoot>
		);
	},
);

namespace Calendar {
	export type Value = Date;

	export interface Props {
		minDate?: Value;
		maxDate?: Value;
		onSubmit?: (value: Value) => void;
		onCancel?: () => void;
		locale?: {
			moment?: string;
			submitButton?: string;
			cancelButton?: string;
			todayButton?: string;
		};
	}
}

export default Calendar;
