import React from "react";

export const files = {
    byId: {
        filtermenu: {
            width: 18,
            height: 18,
            viewBox: [0, 0, 18, 18],
            data: <path d="M10 6H8V8H10V6ZM14 6H12V8H14V6ZM17 12.5C15.75 12.5 14.55 12.3 13.43 11.93C13.08 11.82 12.69 11.9 12.41 12.17L10.21 14.37C7.38 12.93 5.06 10.62 3.62 7.79L5.82 5.58C6.1 5.31 6.18 4.92 6.07 4.57C5.7 3.45 5.5 2.25 5.5 1C5.5 0.45 5.05 0 4.5 0H1C0.45 0 0 0.45 0 1C0 10.39 7.61 18 17 18C17.55 18 18 17.55 18 17V13.5C18 12.95 17.55 12.5 17 12.5ZM16 6V8H18V6H16Z" fill="current" fill-opacity="0.8" />,
        },
        drag_icon: {
            width: 12,
            height: 12,
            viewBox: [0, 0, 12, 12],
            data: <><path xmlns="http://www.w3.org/2000/svg" fill="#D6D6D6" d="M3.96401 2.78568C4.55574 2.78568 5.03544 2.30598 5.03544 1.71425C5.03544 1.12252 4.55574 0.642822 3.96401 0.642822C3.37227 0.642822 2.89258 1.12252 2.89258 1.71425C2.89258 2.30598 3.37227 2.78568 3.96401 2.78568Z" /><path xmlns="http://www.w3.org/2000/svg" fill="#D6D6D6" d="M3.96401 7.07138C4.55574 7.07138 5.03544 6.59169 5.03544 5.99996C5.03544 5.40822 4.55574 4.92853 3.96401 4.92853C3.37227 4.92853 2.89258 5.40822 2.89258 5.99996C2.89258 6.59169 3.37227 7.07138 3.96401 7.07138Z" /><path xmlns="http://www.w3.org/2000/svg" fill="#D6D6D6" d="M3.96401 11.3571C4.55574 11.3571 5.03544 10.8774 5.03544 10.2857C5.03544 9.69394 4.55574 9.21425 3.96401 9.21425C3.37227 9.21425 2.89258 9.69394 2.89258 10.2857C2.89258 10.8774 3.37227 11.3571 3.96401 11.3571Z" /><path xmlns="http://www.w3.org/2000/svg" fill="#D6D6D6" d="M8.24989 2.78571C8.84163 2.78571 9.32132 2.30601 9.32132 1.71428C9.32132 1.12255 8.84163 0.642853 8.24989 0.642853C7.65816 0.642853 7.17847 1.12255 7.17847 1.71428C7.17847 2.30601 7.65816 2.78571 8.24989 2.78571Z" /><path xmlns="http://www.w3.org/2000/svg" fill="#D6D6D6" d="M8.24989 7.07141C8.84163 7.07141 9.32132 6.59172 9.32132 5.99999C9.32132 5.40825 8.84163 4.92856 8.24989 4.92856C7.65816 4.92856 7.17847 5.40825 7.17847 5.99999C7.17847 6.59172 7.65816 7.07141 8.24989 7.07141Z" /><path xmlns="http://www.w3.org/2000/svg" fill="#D6D6D6" d="M8.24989 11.3571C8.84163 11.3571 9.32132 10.8774 9.32132 10.2857C9.32132 9.69397 8.84163 9.21428 8.24989 9.21428C7.65816 9.21428 7.17847 9.69397 7.17847 10.2857C7.17847 10.8774 7.65816 11.3571 8.24989 11.3571Z" /></>
        },
        cancelbuttonicon: {
            width: 13,
            height: 13,
            viewBox: [0, 0, 13, 13],
            data: <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" d="M12.5 1.41423L11.3 0.214233L6.5 5.01423L1.7 0.214233L0.5 1.41423L5.3 6.21423L0.5 11.0142L1.7 12.2142L6.5 7.41423L11.3 12.2142L12.5 11.0142L7.7 6.21423L12.5 1.41423Z" clip-rule="evenodd" />,
        },
        dragIcon: {
            width: 12,
            height: 12,
            viewBox: [0, 0, 12, 12],
            data: <><path xmlns="http://www.w3.org/2000/svg" fill="#D6D6D6" d="M3.96401 2.78568C4.55574 2.78568 5.03544 2.30598 5.03544 1.71425C5.03544 1.12252 4.55574 0.642822 3.96401 0.642822C3.37227 0.642822 2.89258 1.12252 2.89258 1.71425C2.89258 2.30598 3.37227 2.78568 3.96401 2.78568Z" /><path xmlns="http://www.w3.org/2000/svg" fill="#D6D6D6" d="M3.96401 7.07138C4.55574 7.07138 5.03544 6.59169 5.03544 5.99996C5.03544 5.40822 4.55574 4.92853 3.96401 4.92853C3.37227 4.92853 2.89258 5.40822 2.89258 5.99996C2.89258 6.59169 3.37227 7.07138 3.96401 7.07138Z" /><path xmlns="http://www.w3.org/2000/svg" fill="#D6D6D6" d="M3.96401 11.3571C4.55574 11.3571 5.03544 10.8774 5.03544 10.2857C5.03544 9.69394 4.55574 9.21425 3.96401 9.21425C3.37227 9.21425 2.89258 9.69394 2.89258 10.2857C2.89258 10.8774 3.37227 11.3571 3.96401 11.3571Z" /><path xmlns="http://www.w3.org/2000/svg" fill="#D6D6D6" d="M8.24989 2.78571C8.84163 2.78571 9.32132 2.30601 9.32132 1.71428C9.32132 1.12255 8.84163 0.642853 8.24989 0.642853C7.65816 0.642853 7.17847 1.12255 7.17847 1.71428C7.17847 2.30601 7.65816 2.78571 8.24989 2.78571Z" /><path xmlns="http://www.w3.org/2000/svg" fill="#D6D6D6" d="M8.24989 7.07141C8.84163 7.07141 9.32132 6.59172 9.32132 5.99999C9.32132 5.40825 8.84163 4.92856 8.24989 4.92856C7.65816 4.92856 7.17847 5.40825 7.17847 5.99999C7.17847 6.59172 7.65816 7.07141 8.24989 7.07141Z" /><path xmlns="http://www.w3.org/2000/svg" fill="#D6D6D6" d="M8.24989 11.3571C8.84163 11.3571 9.32132 10.8774 9.32132 10.2857C9.32132 9.69397 8.84163 9.21428 8.24989 9.21428C7.65816 9.21428 7.17847 9.69397 7.17847 10.2857C7.17847 10.8774 7.65816 11.3571 8.24989 11.3571Z" /></>,
        },
        plusButton: {
            width: 16,
            height: 16,
            viewBox: [0, 0, 16, 16],
            data: <path xmlns="http://www.w3.org/2000/svg" d="M14 7.14286H8.85714V2H7.14286V7.14286H2V8.85714H7.14286V14H8.85714V8.85714H14V7.14286Z" fill="white" />,
        }

    },

}