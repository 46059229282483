import { clamp, clone } from "lodash";
import { v4 as uuid } from "uuid";
import { ReactElement } from "react";
import { Size } from "../../hooks/useSize";
import { popElements } from "../../utils/array";
import { CalculateLayoutItemRectGridOptions, FlowLayoutItem, GridLayoutItem, GridLayoutItemWithRect1, GridLayoutItemWithRect2, Layout, LayoutItem, LayoutItemRect, StartDraggingOptions, Position, StopDraggingOptions, PrivateLayoutItem, PrivateFlowLayoutItem, PrivateGridLayoutItemWithRect2, PrivateLayout, CalculatedGridOptions, PrivateLayoutItemConfig, StartResizingOptions, CalculateItemResizeOptions, StopResizingOptions, GridContextOptions, GridOptions, GridLayoutItemRect, GridLayoutItemRect1, GridLayoutItemRect2 } from "./types";
import { isRectanglesIntersect } from "../../utils/geometry";

export function processLayout(layout: Layout): PrivateLayout {
  return layout.map(({ indexes, moveable, groupable, resizable, ...item }) => ({
    id: uuid(),

    indexes: indexes.map(String),

    moveable: moveable ?? true,
    groupable: groupable ?? true,
    resizable: resizable ?? true,

    minColumns: 1,
    minRows: 1,

    ...item,
  }))
}

export function getLayoutItems(calculatedLayoutItems: Record<string, PrivateLayoutItemConfig> | undefined, layout: PrivateLayout) {
  return layout.reduce<Record<string, PrivateLayoutItemConfig>>((accumulator, item) => {
    item.indexes.forEach(index => {
      accumulator[index] = calculatedLayoutItems && calculatedLayoutItems[index]
        ? calculatedLayoutItems[index]
        : {
          minColumns: item.minColumns,
          minRows: item.minRows,

          maxColumns: item.maxColumns,
          maxRows: item.maxRows,

          minWidth: item.minWidth,
          minHeight: item.minHeight,

          maxWidth: item.maxWidth,
          maxHeight: item.maxHeight,

          moveable: item.moveable,
          resizable: item.resizable,
          groupable: item.groupable,
        }
    })

    return accumulator
  }, {})
}

export function mapChildrenByKey(children: ReactElement[]) {
  return children.reduce((accumulator, child) => {
    accumulator[child.key!] = child

    return accumulator
  }, {})
}

export function filterItemChildren(children: Record<string, ReactElement>, indexes: string[]) {
  return indexes
    .reduce<Record<string, ReactElement>>((accumulator, index) => {
      accumulator[index] = children[index]

      return accumulator
    }, {})
}

export function calculateGrid(grid: CalculatedGridOptions, size: Size) {
  const result = clone(grid)

  result.gap = Math.max(result.gap, 0)

  if (typeof result.columns === "number") {
    result.columns = Math.max(result.columns, 1)
    result.columnSize = (size.width - result.gap * (result.columns - 1)) / result.columns
  }

  result.columns = Math.floor((size.width + result.gap) / (result.columnSize + result.gap))

  if (typeof result.rows === "number") {
    result.rows = Math.max(result.rows, 1)
    result.rowSize = (size.height - result.gap * (result.rows - 1)) / result.rows
  }

  result.rows = Math.floor((size.height + result.gap) / (result.rowSize + result.gap))

  return result as Required<CalculatedGridOptions>
}

export function isGridLayoutItemRect1(gridLayoutItemRect: GridLayoutItemRect): gridLayoutItemRect is GridLayoutItemRect1 {
  return "columnStart" in gridLayoutItemRect
}

export function isGridLayoutItemRect2(gridLayoutItemRect: GridLayoutItemRect): gridLayoutItemRect is GridLayoutItemRect2 {
  return "column" in gridLayoutItemRect
}

export function isGridLayoutItemWithRect1(layoutItem: LayoutItem): layoutItem is GridLayoutItemWithRect1 {
  return isGridLayoutItem(layoutItem) && isGridLayoutItemRect1(layoutItem)
}

export function isGridLayoutItemWithRect2(layoutItem: LayoutItem): layoutItem is GridLayoutItemWithRect2 {
  return isGridLayoutItem(layoutItem) && isGridLayoutItemRect2(layoutItem)
}

export function isGridLayoutItem(layoutItem: LayoutItem): layoutItem is GridLayoutItem {
  return layoutItem.type === "grid"
}

export function isFlowLayoutItem(layoutItem: LayoutItem): layoutItem is FlowLayoutItem {
  return layoutItem.type === "flow"
}

export function createLayoutItem(type: "flow", layoutItems: Record<string, PrivateLayoutItemConfig>, indexes: string[]): PrivateFlowLayoutItem
export function createLayoutItem(type: "grid", layoutItems: Record<string, PrivateLayoutItemConfig>, indexes: string[]): PrivateGridLayoutItemWithRect2
export function createLayoutItem(type: PrivateLayoutItem["type"], layoutItems: Record<string, PrivateLayoutItemConfig>, indexes: string[]): PrivateFlowLayoutItem | PrivateGridLayoutItemWithRect2
export function createLayoutItem(type: PrivateLayoutItem["type"], layoutItems: Record<string, PrivateLayoutItemConfig>, indexes: string[]): PrivateFlowLayoutItem | PrivateGridLayoutItemWithRect2 {
  const settings = indexes.reduce<PrivateLayoutItemConfig>((accumulator, index) => {
    const layoutItem = layoutItems[index]

    if (typeof layoutItem.minColumns === "number")
      accumulator.minColumns = accumulator.minColumns
        ? Math.max(accumulator.minColumns, layoutItem.minColumns)
        : layoutItem.minColumns

    if (typeof layoutItem.minRows === "number")
      accumulator.minRows = accumulator.minRows
        ? Math.max(accumulator.minRows, layoutItem.minRows)
        : layoutItem.minRows

    if (typeof layoutItem.maxColumns === "number")
      accumulator.maxColumns = accumulator.maxColumns
        ? Math.min(accumulator.maxColumns, layoutItem.maxColumns)
        : layoutItem.maxColumns

    if (typeof layoutItem.maxRows === "number")
      accumulator.maxRows = accumulator.maxRows
        ? Math.min(accumulator.maxRows, layoutItem.maxRows)
        : layoutItem.maxRows

    if (typeof layoutItem.minWidth === "number")
      accumulator.minWidth = accumulator.minWidth
        ? Math.max(accumulator.minWidth, layoutItem.minWidth)
        : layoutItem.minWidth

    if (typeof layoutItem.minHeight === "number")
      accumulator.minHeight = accumulator.minHeight
        ? Math.max(accumulator.minHeight, layoutItem.minHeight)
        : layoutItem.minHeight

    if (typeof layoutItem.maxWidth === "number")
      accumulator.maxWidth = accumulator.maxWidth
        ? Math.min(accumulator.maxWidth, layoutItem.maxWidth)
        : layoutItem.maxWidth

    if (typeof layoutItem.maxHeight === "number")
      accumulator.maxHeight = accumulator.maxHeight
        ? Math.min(accumulator.maxHeight, layoutItem.maxHeight)
        : layoutItem.maxHeight

    accumulator.moveable = accumulator.moveable && layoutItem.moveable
    accumulator.groupable = accumulator.groupable && layoutItem.groupable
    accumulator.resizable = accumulator.resizable && layoutItem.resizable

    return accumulator
  }, {
    moveable: true,
    groupable: true,
    resizable: true,
  })

  if (type === "flow") {
    return {
      id: uuid(),

      type,

      indexes,

      x: 0,
      y: 0,

      width: settings.minWidth ?? 0,
      height: settings.minHeight ?? 0,

      ...settings,
    }
  }

  return {
    id: uuid(),

    type,

    indexes,

    column: 0,
    row: 0,

    columns: settings.minColumns ?? 0,
    rows: settings.minRows ?? 0,

    ...settings,
  }
}

export function calculateLayoutItemRect(item: GridLayoutItem, options: CalculateLayoutItemRectGridOptions): LayoutItemRect
export function calculateLayoutItemRect(item: FlowLayoutItem): LayoutItemRect
export function calculateLayoutItemRect(item: LayoutItem, options?: CalculateLayoutItemRectGridOptions): LayoutItemRect
export function calculateLayoutItemRect(item: LayoutItem, options?: CalculateLayoutItemRectGridOptions): LayoutItemRect {
  const rect: Partial<LayoutItemRect> = {}

  if (isGridLayoutItemWithRect1(item)) {
    options = options as CalculateLayoutItemRectGridOptions

    rect.x = item.columnStart * (options.columnSize + options.gap)
    rect.y = item.rowStart * (options.rowSize + options.gap)

    rect.width = Math.max((item.columnEnd - item.columnStart + 1) * (options.columnSize + options.gap) - options.gap, 0)
    rect.height = Math.max((item.rowEnd - item.rowStart + 1) * (options.rowSize + options.gap) - options.gap, 0)
  } else if (isGridLayoutItemWithRect2(item)) {
    options = options as CalculateLayoutItemRectGridOptions

    rect.x = item.column * (options.columnSize + options.gap)
    rect.y = item.row * (options.rowSize + options.gap)

    rect.width = Math.max(item.columns * (options.columnSize + options.gap) - options.gap, 0)
    rect.height = Math.max(item.rows * (options.rowSize + options.gap) - options.gap, 0)
  } else if (isFlowLayoutItem(item)) {
    rect.x = item.x
    rect.y = item.y

    rect.width = item.width
    rect.height = item.height
  } else {
    rect.x = 0
    rect.y = 0

    rect.width = 0
    rect.height = 0
  }

  return rect as LayoutItemRect
}

export function calculateGridLayoutItemSize(item: GridLayoutItem) {
  if (isGridLayoutItemWithRect2(item)) {
    return {
      columns: item.columns,
      rows: item.rows,
    }
  }

  return {
    columns: item.columnEnd - item.columnStart + 1,
    rows: item.rowEnd - item.rowStart + 1,
  }
}

export function calculateGridLayoutItemRect1(item: GridLayoutItemRect) {
  if (isGridLayoutItemRect2(item)) {
    return {
      column: item.column,
      row: item.row,
      columns: item.columns,
      rows: item.rows,
    }
  }

  return {
    column: item.columnStart,
    row: item.rowStart,
    columns: item.columnEnd - item.columnStart + 1,
    rows: item.rowEnd - item.rowStart + 1,
  }
}

export function findItemById(layout: PrivateLayout, id: PrivateLayoutItem["id"]) {
  return layout.find((item) => item.id === id)
}

export function findItemIndexById(layout: PrivateLayout, id: PrivateLayoutItem["id"]) {
  return layout.findIndex((item) => item.id === id)
}

export function startDragging({
  itemId,

  indexes,

  grid,

  cursorX,
  cursorY,
}: StartDraggingOptions) {
  const itemIndex = findItemIndexById(grid.layout, itemId)
  const item = grid.layout[itemIndex]

  const dragItem = createLayoutItem(item.type, grid.layoutItems, indexes)
  const itemRect = calculateLayoutItemRect(item, grid)

  dragItem.drag = {
    cursorOffsetX: itemRect.x - cursorX,
    cursorOffsetY: itemRect.y - cursorY,

    from: item.id,
  }

  const leftItemIndexes = popElements(item.indexes, indexes)

  if (isFlowLayoutItem(dragItem)) {
    dragItem.width = itemRect.width
    dragItem.height = itemRect.height
  } else if (leftItemIndexes.length === 0)
    Object.assign(dragItem, calculateGridLayoutItemSize(item as GridLayoutItem))

  const newLayout = clone(grid.layout)


  if (leftItemIndexes.length === 0) {
    newLayout
      .splice(itemIndex, 1)

    dragItem.id = item.id
  }
  else
    newLayout[itemIndex] = {
      ...item,

      indexes: leftItemIndexes,
    }

  newLayout.push(dragItem)

  return newLayout
}

export function groupItems(layout: PrivateLayout, layoutItems: Record<string, PrivateLayoutItemConfig>, destinationItemId: string, sourceItemId: string) {
  const destinationItemIndex = findItemIndexById(layout, destinationItemId)
  const destinationItem = layout[destinationItemIndex]

  const sourceItemIndex = findItemIndexById(layout, sourceItemId)
  const sourceItem = layout[sourceItemIndex]

  const newLayout = clone(layout)

  const groupedItem = createLayoutItem(
    destinationItem.type,
    layoutItems,
    [
      ...destinationItem.indexes,
      ...sourceItem.indexes,
    ]
  )

  groupedItem.id = destinationItem.id

  if (isFlowLayoutItem(groupedItem)) {
    const flowDestinationItem = destinationItem as PrivateFlowLayoutItem

    groupedItem.x = flowDestinationItem.x
    groupedItem.y = flowDestinationItem.y
    groupedItem.width = flowDestinationItem.width
    groupedItem.height = flowDestinationItem.height
  } else if (isGridLayoutItemWithRect1(destinationItem)) {
    groupedItem.column = destinationItem.columnStart
    groupedItem.row = destinationItem.rowStart
    groupedItem.columns = destinationItem.columnEnd - destinationItem.columnStart + 1
    groupedItem.rows = destinationItem.rowEnd - destinationItem.rowStart + 1
  } else if (isGridLayoutItemWithRect2(destinationItem)) {
    groupedItem.column = destinationItem.column
    groupedItem.row = destinationItem.row
    groupedItem.columns = destinationItem.columns
    groupedItem.rows = destinationItem.rows
  }

  newLayout[destinationItemIndex] = groupedItem

  newLayout.splice(sourceItemIndex, 1)

  return newLayout
}

export function isGridRectCollidesWithOtherItems(layout: PrivateLayout, girdLayoutItemRect: GridLayoutItemRect) {
  const girdLayoutItemRect1 = calculateGridLayoutItemRect1(girdLayoutItemRect)
  const girdLayoutItemRectForCheck = {
    x: girdLayoutItemRect1.column,
    y: girdLayoutItemRect1.row,
    width: girdLayoutItemRect1.columns,
    height: girdLayoutItemRect1.rows,
  }

  return !!layout.find((item) => {
    if (item.drag || !isGridLayoutItem(item))
      return false

    const itemGridRect = calculateGridLayoutItemRect1(item)

    if (
      isRectanglesIntersect(
        {
          x: itemGridRect.column,
          y: itemGridRect.row,
          width: itemGridRect.columns,
          height: itemGridRect.rows,
        },
        girdLayoutItemRectForCheck
      )
    )
      return true

    return false
  })
}

export function getClosestPlaceItemPosition(layout: PrivateLayout, itemId: string, itemPosition: Position, grid: GridOptions) {
  const itemIndex = findItemIndexById(layout, itemId)
  const item = layout[itemIndex]

  if (!isGridLayoutItem(item))
    return null

  const gridItemSize = calculateGridLayoutItemSize(item)

  let closestPosition: Pick<GridLayoutItemRect2, "column" | "row"> | null = null
  let squaredClosestPositionDistance = Number.POSITIVE_INFINITY

  for (let column = 0; column <= grid.columns - gridItemSize.columns; column++)
    for (let row = 0; row <= grid.rows - gridItemSize.rows; row++) {
      const currentPosition = calculateCellPosition(column, row, grid)

      if (
        isGridRectCollidesWithOtherItems(
          layout,
          {
            column,
            row,
            ...gridItemSize,
          }
        )
      )
        continue

      const currentPositionOffset = {
        x: itemPosition.x - currentPosition.x,
        y: itemPosition.y - currentPosition.y,
      }
      const squaredCurrentPositionDistance = currentPositionOffset.x * currentPositionOffset.x + currentPositionOffset.y * currentPositionOffset.y

      if (squaredCurrentPositionDistance < squaredClosestPositionDistance) {
        squaredClosestPositionDistance = squaredCurrentPositionDistance
        closestPosition = { column, row }
      }
    }

  return closestPosition
}

export function placeItem(layout: PrivateLayout, itemId: string, itemPosition: Position, grid: GridOptions) {
  const itemIndex = findItemIndexById(layout, itemId)

  const newLayout = clone(layout)

  newLayout[itemIndex] = { ...layout[itemIndex] }

  const item = newLayout[itemIndex]

  if (isGridLayoutItem(item)) {
    const closestGridItemPlacePosition = getClosestPlaceItemPosition(
      layout,
      itemId,
      itemPosition,
      grid,
    )

    if (!closestGridItemPlacePosition)
      return layout

    if (isGridLayoutItemWithRect1(item)) {
      const itemColumnDelta = item.columnEnd - item.columnStart
      const itemRowDelta = item.rowEnd - item.rowStart

      item.columnStart = closestGridItemPlacePosition.column
      item.rowStart = closestGridItemPlacePosition.row

      item.columnEnd = item.columnStart + itemColumnDelta
      item.rowEnd = item.rowStart + itemRowDelta
    } else {
      item.column = closestGridItemPlacePosition.column
      item.row = closestGridItemPlacePosition.row
    }
  } else {
    item.x = itemPosition.x
    item.y = itemPosition.y
  }

  delete item.drag

  return newLayout
}

export function stopDragging({
  grid,

  itemId,
  itemPosition,

  hoveredItemId,
}: StopDraggingOptions) {
  return typeof hoveredItemId === "string"
    ? groupItems(grid.layout, grid.layoutItems, hoveredItemId, itemId)
    : placeItem(grid.layout, itemId, itemPosition, grid)
}

export function startResizing({
  grid,
  item,

  type,

  cursorX,
  cursorY,
}: StartResizingOptions) {
  const itemIndex = findItemIndexById(grid.layout, item.id)
  const itemRect = calculateLayoutItemRect(item, grid)

  const newLayout = clone(grid.layout)

  newLayout[itemIndex] = clone(item)

  newLayout[itemIndex].resize = {
    cursorOffsetX: itemRect.x - cursorX,
    cursorOffsetY: itemRect.y - cursorY,

    type,
  }

  return newLayout
}

export function cellsToSize(cells: number, cellSize: number, gap: number) {
  return cells * (cellSize + gap) - gap
}

export function sizeToCells(size: number, cellSize: number, gap: number) {
  return Math.ceil((size - cellSize / 2) / (cellSize + gap))
}

export function calculateCellPosition(column: number, row: number, grid: CalculateLayoutItemRectGridOptions) {
  return {
    x: cellsToSize(column, grid.columnSize, grid.gap),
    y: cellsToSize(row, grid.columnSize, grid.gap),
  }
}

export function calculateItemLimits(item: PrivateLayoutItem, grid: GridContextOptions) {
  let minWidth = 0
  let minHeight = 0
  let maxWidth = grid.width
  let maxHeight = grid.height

  let minColumns = 1
  let minRows = 1
  let maxColumns = grid.columns
  let maxRows = grid.rows

  if (isGridLayoutItem(item)) {
    minColumns = item.minColumns ?? 1
    minRows = item.minRows ?? 1
    maxColumns = item.maxColumns ?? maxColumns
    maxRows = item.maxRows ?? maxRows

    minWidth = cellsToSize(minColumns, grid.columnSize, grid.gap)
    minHeight = cellsToSize(minRows, grid.rowSize, grid.gap)

    if (item.maxColumns)
      maxWidth = cellsToSize(maxColumns, grid.columnSize, grid.gap)

    if (item.maxRows)
      maxHeight = cellsToSize(maxRows, grid.rowSize, grid.gap)
  } else if (isFlowLayoutItem(item)) {
    minWidth = item.minWidth ?? 0
    minHeight = item.minHeight ?? 0
    maxWidth = item.maxWidth ?? grid.width
    maxHeight = item.maxHeight ?? grid.height

    minColumns = sizeToCells(minWidth, grid.columnSize, grid.gap)
    minRows = sizeToCells(minHeight, grid.rowSize, grid.gap)
    maxColumns = sizeToCells(maxWidth, grid.columnSize, grid.gap)
    maxRows = sizeToCells(maxHeight, grid.rowSize, grid.gap)
  }

  return {
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,

    minColumns,
    minRows,
    maxColumns,
    maxRows,
  }
}

export function calculateItemResize({
  cursorPosition,
  cursorOffset,

  grid,
  item,

  type,
}: CalculateItemResizeOptions): LayoutItemRect {
  const itemRect = calculateLayoutItemRect(item, grid)
  const itemRectEndX = itemRect.x + itemRect.width
  const itemRectEndY = itemRect.y + itemRect.height

  const itemLimits = calculateItemLimits(item, grid)

  if (type === "top-left") {
    itemRect.x = cursorPosition.x + cursorOffset.x
    itemRect.y = cursorPosition.y + cursorOffset.y
    itemRect.width = itemRectEndX - itemRect.x
    itemRect.height = itemRectEndY - itemRect.y

    itemRect.width = clamp(itemRect.width, itemLimits.minWidth, itemLimits.maxWidth)
    itemRect.height = clamp(itemRect.height, itemLimits.minHeight, itemLimits.maxHeight)
    itemRect.x = itemRectEndX - itemRect.width
    itemRect.y = itemRectEndY - itemRect.height
  } else if (type === "top") {
    itemRect.y = cursorPosition.y + cursorOffset.y
    itemRect.height = itemRectEndY - itemRect.y

    itemRect.height = clamp(itemRect.height, itemLimits.minHeight, itemLimits.maxHeight)
    itemRect.y = itemRectEndY - itemRect.height
  } else if (type === "top-right") {
    itemRect.y = cursorPosition.y + cursorOffset.y
    itemRect.width = itemRect.width + cursorPosition.x + cursorOffset.x - itemRect.x
    itemRect.height = itemRectEndY - itemRect.y

    itemRect.width = clamp(itemRect.width, itemLimits.minWidth, itemLimits.maxWidth)
    itemRect.height = clamp(itemRect.height, itemLimits.minHeight, itemLimits.maxHeight)
    itemRect.y = itemRectEndY - itemRect.height
  } else if (type === "right") {
    itemRect.width = itemRect.width + cursorPosition.x + cursorOffset.x - itemRect.x

    itemRect.width = clamp(itemRect.width, itemLimits.minWidth, itemLimits.maxWidth)
  } else if (type === "bottom-right") {
    itemRect.width = itemRect.width + cursorPosition.x + cursorOffset.x - itemRect.x
    itemRect.height = itemRect.height + cursorPosition.y + cursorOffset.y - itemRect.y

    itemRect.width = clamp(itemRect.width, itemLimits.minWidth, itemLimits.maxWidth)
    itemRect.height = clamp(itemRect.height, itemLimits.minHeight, itemLimits.maxHeight)
  } else if (type === "bottom") {
    itemRect.height = itemRect.height + cursorPosition.y + cursorOffset.y - itemRect.y

    itemRect.height = clamp(itemRect.height, itemLimits.minHeight, itemLimits.maxHeight)
  } else if (type === "bottom-left") {
    itemRect.x = cursorPosition.x + cursorOffset.x
    itemRect.width = itemRectEndX - itemRect.x
    itemRect.height = itemRect.height + cursorPosition.y + cursorOffset.y - itemRect.y

    itemRect.width = clamp(itemRect.width, itemLimits.minWidth, itemLimits.maxWidth)
    itemRect.height = clamp(itemRect.height, itemLimits.minHeight, itemLimits.maxHeight)
    itemRect.x = itemRectEndX - itemRect.width
  } else if (type === "left") {
    itemRect.x = cursorPosition.x + cursorOffset.x
    itemRect.width = itemRectEndX - itemRect.x

    itemRect.width = clamp(itemRect.width, itemLimits.minWidth, itemLimits.maxWidth)
    itemRect.x = itemRectEndX - itemRect.width
  }

  return itemRect
}

export function stopResizing({
  grid,
  item,

  cursorX,
  cursorY,
}: StopResizingOptions) {
  if (!item.resize)
    return grid.layout

  const itemIndex = findItemIndexById(grid.layout, item.id)
  const itemRect = calculateItemResize({
    cursorPosition: {
      x: cursorX,
      y: cursorY,
    },
    cursorOffset: {
      x: item.resize.cursorOffsetX,
      y: item.resize.cursorOffsetY,
    },

    grid,
    item,

    type: item.resize.type,
  })

  const newLayout = clone(grid.layout)
  const newItem = clone(item)

  newLayout[itemIndex] = newItem

  delete newItem.resize

  if (isGridLayoutItem(newItem)) {
    const toItemRectEndX = itemRect.x + itemRect.width
    const toItemRectEndY = itemRect.y + itemRect.height

    const columnStart = Math.ceil((itemRect.x - grid.columnSize / 2) / (grid.columnSize + grid.gap))
    const rowStart = Math.ceil((itemRect.y - grid.rowSize / 2) / (grid.rowSize + grid.gap))
    const columnEnd = Math.ceil((toItemRectEndX - grid.columnSize / 2) / (grid.columnSize + grid.gap)) - 1
    const rowEnd = Math.ceil((toItemRectEndY - grid.rowSize / 2) / (grid.rowSize + grid.gap)) - 1

    if (isGridLayoutItemWithRect1(newItem)) {
      newItem.columnStart = columnStart
      newItem.rowStart = rowStart
      newItem.columnEnd = columnEnd
      newItem.rowEnd = rowEnd
    } else {
      newItem.column = columnStart
      newItem.row = rowStart
      newItem.columns = columnEnd - columnStart + 1
      newItem.rows = rowEnd - rowStart + 1
    }
  } else if (isFlowLayoutItem(newItem)) {
    Object.assign(newItem, itemRect)
  }

  return newLayout
}
