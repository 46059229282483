import React, { CSSProperties, forwardRef, Ref, RefAttributes } from 'react';
import styled from 'styled-components';
import theme from '../../styles/theme';
import StyleProps from '../../types/StyleProps';
import { InputifiedControlProps, inputify } from '../../utils/react';
import Flex from '../Flex';
import { InputBorders, ControlProps as InputBordersControlProps } from '../InputBorders';
import Root from './components/Root';

const MultilineTextBox = inputify<MultilineTextBox.Props, string>(forwardRef(({
	style,
	className,
	
	value = "",

	placeholder,
	tabIndex,

	hovered,
	focused,
	error,
	disabled,
	hasBorders,
	hasPaddings,
	nested,

	onChange,
	onEnter,
	onLeave,
	onFocus,
	onBlur,
}, ref) => {
	return (
		<InputBorders
			ref={ref}

			hovered={hovered}
			focused={focused}
			error={error}
			disabled={disabled}
			hasBorders={hasBorders}
			hasPaddings={hasPaddings}
			nested={nested}

			style={style}
			className={className}

			onEnter={onEnter}
			onLeave={onLeave}
		>
				<Root
					value={value}
					tabIndex={tabIndex}
					hovered={hovered}
					focused={focused}
					error={error}
					disabled={disabled}
					onChange={(event) => onChange(event.target.value)}
					placeholder={placeholder}
					onFocus={onFocus}
					onBlur={onBlur}
				/>
		</InputBorders>
	)
}))

declare namespace MultilineTextBox {
	interface Props extends InputBordersControlProps, StyleProps<typeof InputBorders>, RefAttributes<HTMLDivElement> {
		placeholder?: string
		tabIndex?: number
	}
}

export default MultilineTextBox
