import React, { FC } from 'react';
import styled from "styled-components";
import { IconSizes, IconSize } from './iconSizes'

import theme from '../../styles/theme'

const DEFAULT_AVATAR_SIZE = "small"

interface StyledAvatarProps {
	size: IconSize
}

const StyledAvatar = styled.div<StyledAvatarProps>`
    height: ${props => IconSizes[props.size]};
    width: ${props => IconSizes[props.size]};
    align-items: center;
    background-color: ${theme.colors.accent};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
    display: flex;
    flex: none;
    justify-content: center;
    line-height: 0;
    margin: 0;
    position: relative;
    user-select: none;
`

export interface AvatarProps {
	imgSrc?: string
	size?: IconSize
}

export const Avatar: FC<AvatarProps> = ({
	imgSrc,
	size = DEFAULT_AVATAR_SIZE,
	...props
}) => (
	<StyledAvatar
		size={size}
		role="img"
		style={{ backgroundImage: imgSrc && `url(${imgSrc})` }}
		{...props}
	/>
)

Avatar.displayName = 'Avatar';
