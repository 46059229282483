import React, { FC, useRef, useCallback, useState, useEffect } from "react"
import styled from "styled-components"
import theme from "../../styles/theme"
import CircleIcon from "../../icons/circle-chevron-down"
import useInternal from "../../hooks/useInternal"
import useHeightAnimation from "../../hooks/useHeightAnimation"

const duration = 200
const animation = `${duration}ms ease-out`

interface StyledAccordionProps {
	open: boolean
	height: string
}

const StyledAccordion = styled.div<StyledAccordionProps>`
	height: ${props => props.height};
	overflow: hidden;
	display: grid;
	grid-template-areas:
        "icon head"
        ". body";
    grid-template-columns: 30px 1fr;
    grid-auto-rows: 50px 1fr;

	transition: height ${animation};
	border: 1px solid ${theme.colors.disabled_filled};

	& + & {
		border-top: none;
	}

	.circle-icon {
		grid-area: icon;
		display: flex;
		justify-content: start;
		align-items: center;
		padding: 0;
		border: none;
		outline: none;
		background-color: transparent;
		cursor: pointer;

		& svg {
			rotate: ${props => props.open ? '0deg' : '-90deg'};
			transition: rotate ${animation};

			& path {
				transition: stroke ${animation};
			}
		}
	}

	.head {
		grid-area: head;
		display: flex;
		justify-content: start;
		align-items: center;
		padding: 0;
	}

	.body {
		height: ${props => props.open ? 'auto' : '0px'};
		overflow: visible;
		grid-area: body;
	}
`

export interface Props {
	value?: boolean
	onChange?: (value: boolean) => void

	style?: React.CSSProperties
	head: React.ReactNode
	children: React.ReactNode
}

export const Accordion: FC<Props> = ({
	value = false,
	onChange,

	style,
	head,
	children: body,
}) => {
	const [open, setOpen] = useInternal(value)
	const [internalOpen, setInternalOpen] = useState(value)
	const [height, setHeight] = useState('auto')

	const headRef = useRef<HTMLDivElement>(null)
	const bodyRef = useRef<HTMLDivElement>(null)

	const toggle = useCallback(() => {
		setOpen(prev => {
			onChange?.(!prev);
			return !prev;
		});
	}, [onChange]);

	const calcHeight = useCallback(() => {
		const headHeight = headRef.current?.clientHeight ?? 0;
		const bodyHeight = bodyRef.current?.clientHeight ?? 0;
		return (headHeight + bodyHeight) + 'px';
	}, []);

	useHeightAnimation({
		ref: bodyRef,
		duration,
		calcHeight,
		onSetHeight: setHeight,
	});

	useEffect(() => {
		setHeight(calcHeight());
		requestAnimationFrame(() => setInternalOpen(open));
	}, [calcHeight, open]);

	return (
		<StyledAccordion
			style={style}
			open={internalOpen}
			height={height}
		>
			<button
				className="circle-icon"
				onClick={toggle}
			>
				<CircleIcon color={internalOpen ? theme.colors.accent : theme.colors.secondary} />
			</button>
			<div
				className="head"
				ref={headRef}
			>
				{head}
			</div>
			<div
				className="body"
				ref={bodyRef}
			>
				{body}
			</div>
		</StyledAccordion>
	)
}