/* eslint-disable no-shadow */

import { extend } from "lodash";
import React, { PropsWithChildren, useLayoutEffect } from "react";
import useChanged from "../../../../hooks/useChanged";
import usePosition from "../../../../hooks/usePosition";
import useRender from "../../../../hooks/useRender";
import useSize from "../../../../hooks/useSize";
import { withController } from "../../../../utils/react";
import Root from "./components/Root";
import InternalController from "./Controller";
import useReactiveRef from "../../../../hooks/useReactiveRef";
import Overlayer from "../../../Overlayer";
import StyleProps from "../../../../types/StyleProps";

const containers: Record<string, InternalController> = {};

const Container = extend(
	withController<Container.Props, InternalController>(
		({
			children,

			id,

			style,
			className,

			controller,
		}) => {
			const render = useRender();

			const [rootRef, rootRefSetter] =
				useReactiveRef<HTMLDivElement | null>(null);
			const root = rootRef.current;

			const size = useSize(root);
			const position = usePosition(root);

			useChanged(
				() => {
					controller.emit("size", size);
				},
				size,
				true,
			);

			useChanged(
				() => {
					controller.emit("move", position);
				},
				position,
				true,
			);

			controller.setContext({
				render,
				rootRef,
			});

			Container.set(id, controller);

			useLayoutEffect(() => {
				render(true);
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [controller.lastUpdateId]);

			const result = (
				<Overlayer
					style={style}
					className={className}
					overlay={
						<Root ref={rootRefSetter}>
							{controller.order.map((id) =>
								React.cloneElement(
									<>{controller.floats[id]}</>,
									{ key: id },
								),
							)}
						</Root>
					}
				>
					{children}
				</Overlayer>
			);

			return result;
		},
		InternalController,
	),
	{
		Controller: InternalController,

		set(id: string, container: InternalController) {
			containers[id] = container;
		},

		get(id: string): InternalController | undefined {
			return containers[id];
		},
	},
);

declare namespace Container {
	type Controller = InternalController;

	export interface Props extends PropsWithChildren, StyleProps {
		id: string;
	}
}

export default Container;
