import React, { FC, Key } from "react";
import { noop } from "lodash";
import Parent from "../..";
import { InputifyComponentProps } from "../../../../utils/react";
import Item from "./components/Item";
import Root from "./components/Root";

const Panels: FC<Panels.Props> = ({
	style,
	className,

	value,

	options,

	onChange,
	onChangeSelectedOption,

	onEnter,
	onLeave,
	onFocus,
	onBlur,
}) => (
	<Root
		style={style}
		className={className}
		gaps="1px*"
		onMouseEnter={onEnter}
		onMouseLeave={onLeave}
		onFocus={onFocus}
		onBlur={onBlur}
	>
		{options.map((option) => (
			<Item
				key={option.key}
				disabled={option.disabled}
				selected={option.key === value}
				onClick={
					option.disabled
						? noop
						: () => {
								onChange(option.key);
								onChangeSelectedOption?.(option);
						  }
				}
			>
				{option.label}
			</Item>
		))}
	</Root>
);

declare namespace Panels {
	export interface Props
		extends Omit<Parent.Props, "variant">,
			InputifyComponentProps<Key> {}
}

export default Panels;
