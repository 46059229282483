import React, {
	CSSProperties,
	Dispatch,
	forwardRef,
	Ref,
	RefAttributes,
	useCallback,
	useEffect,
	useRef,
} from "react";
import styled from "styled-components";
import theme from "../../styles/theme";
import StyleProps from "../../types/StyleProps";
import {
	InputifiedControlProps,
	inputify,
	setRefValue,
} from "../../utils/react";
import Flex from "../Flex";
import {
	InputBorders,
	ControlProps as InputBordersControlProps,
} from "../InputBorders";

interface RootProps extends Required<InputifiedControlProps> {
	width: string;
	height: string;
	textAlign: CSSProperties["textAlign"];
}

const Root = styled.input<RootProps>`
	all: unset;

	width: ${({ width }) => width};
	height: ${({ height }) => height};
	text-align: ${({ textAlign }) => textAlign};

	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;

	color: ${theme.colors.primary};
	caret-color: ${theme.colors.accent};

	&::placeholder {
		color: ${theme.colors.disabled_text};
	}

	${({ hovered }) => (hovered ? "&," : "")}
	&:hover,
	&:has(:hover),
	${({ focused }) => (focused ? "&," : "")}
	&:focus,
	&:has(:focus) {
		&::placeholder {
			${({ error, disabled }) =>
				error || disabled
					? ""
					: `color: ${theme.colors.text_hovered_placeholder};`}
		}
	}
`;

export interface Props
	extends InputBordersControlProps,
		StyleProps<typeof InputBorders> {
	placeholder?: string;
	width?: string;
	height?: string;
	type?: string;
	name?: string;
	tabIndex?: number;
	textAlign?: CSSProperties["textAlign"];
	inputRef?: Ref<HTMLInputElement>;
	autoFocus?: boolean;
	autoComplete?:
		| "off"
		| "on"
		| "true"
		| "false"
		| "new-password"
		| "one-time-code"
		| "chrome-off";
	maxLength?: number;
	minLength?: number;
	min?: string | number;
	max?: string | number;
	id?: string;

	onPaste?: Dispatch<React.ClipboardEvent<HTMLInputElement>>;
}

export const TextBox = inputify<Props & RefAttributes<HTMLDivElement>, string>(
	forwardRef(
		(
			{
				value = "",
				placeholder,

				hovered,
				focused,
				error,
				disabled,
				hasBorders,
				hasPaddings,
				nested,

				autoFocus,
				autoComplete,
				maxLength,
				minLength,
				max,
				min,
				tabIndex,
				type,
				name,

				onChange,
				onPaste,
				onEnter,
				onLeave,
				onFocus,
				onBlur,

				style,
				className,

				width = "100%",
				height = "100%",
				textAlign = "start",
				id,
				inputRef,
			},
			ref,
		) => {
			const rootRef = useRef<HTMLInputElement | null>(null);

			const rootRefSetter = useCallback(
				(node: HTMLInputElement) => {
					if (inputRef) setRefValue(inputRef, node);

					rootRef.current = node;
				},
				[inputRef],
			);

			const focus = useCallback(() => rootRef.current?.focus(), []);

			useEffect(() => {
				if (autoFocus) {
					process.nextTick(focus);
				}
			}, [autoFocus, focus]);

			return (
				<InputBorders
					ref={ref}
					hovered={hovered}
					focused={focused}
					error={error}
					disabled={disabled}
					hasBorders={hasBorders}
					hasPaddings={hasPaddings}
					nested={nested}
					style={style}
					className={className}
					onEnter={onEnter}
					onLeave={onLeave}
				>
					<Flex align="center">
						<Root
							ref={rootRefSetter}
							id={id}
							type={type}
							name={name}
							tabIndex={tabIndex}
							autoFocus={autoFocus}
							autoComplete={autoComplete}
							maxLength={maxLength}
							minLength={minLength}
							min={min}
							max={max}
							width={width}
							height={height}
							textAlign={textAlign}
							hovered={hovered}
							focused={focused}
							error={error}
							disabled={disabled}
							value={value}
							onChange={(event) => onChange(event.target.value)}
							onPaste={onPaste}
							placeholder={placeholder}
							onFocus={onFocus}
							onBlur={onBlur}
						/>
					</Flex>
				</InputBorders>
			);
		},
	),
);

export const classes = {
	input: Root.styledComponentId,
};
