import Rect from "../types/Rect"

export function inRect(
  x: number,
  y: number,
  rectX: number,
  rectY: number,
  rectWidth: number,
  rectHeight: number,
) {
  return (
    x > rectX && x < rectX + rectWidth &&
    y > rectY && y < rectY + rectHeight
  )
}

export function isRectanglesIntersect(rect1: Rect, rect2: Rect) {
  let rect1EndX = rect1.x + rect1.width
  let rect1EndY = rect1.y + rect1.height
  let rect2EndX = rect2.x + rect2.width
  let rect2EndY = rect2.y + rect2.height

  return (
    rect1EndX > rect2.x &&
    rect1.x < rect2EndX &&
    rect1.y < rect2EndY &&
    rect1EndY > rect2.y
  )
}