import React, { FC } from "react"
import { Type } from "../.."
import Flex from "../../../Flex"
import { Root } from "./components/Root"
import success from "../../../../icons/success"
import failure from "../../../../icons/failure"
import warning from "../../../../icons/warning"
import info from "../../../../icons/info"

export interface Props {
  type: Type
  color: string
}

const icons = {
  success,
  failure,
  warning,
  info,
}

export const Icon: FC<Props> = ({ type, color }) => {
  return (
    <Root color={color}>
      <Flex
        align="center"
        justify="center"
      >
        {icons[type]}
      </Flex>
    </Root>
  )
}
