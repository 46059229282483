import { GeoJsonObject } from "geojson";
import { LatLng, LatLngTuple } from "leaflet";
import { Polygon, PolygonList } from "./type";

export function geoJSONForPolygon(polygon: Polygon) {
	return {
		type: "Feature",
		properties: {},
		geometry: {
			type: "Polygon",
			coordinates: [polygon.vertices],
		},
	} as GeoJsonObject;
}

export function geoJSONForPolygons(polygons: PolygonList) {
	return {
		type: "FeatureCollection",
		features: polygons.map(geoJSONForPolygon),
	} as GeoJsonObject;
}

export function latLngToCoordinates(latLng: LatLng): LatLngTuple {
	return [latLng.lat, latLng.lng];
}

export function coordinatesToLatLng(coordinates: LatLngTuple) {
	return new LatLng(coordinates[0], coordinates[1]);
}

export default {
	geoJSONForPolygon,
	geoJSONForPolygons,
	latLngToCoordinates,
};
