/* eslint-disable react/require-default-props */

import { LeafletEvent } from "leaflet";
import React, { FC } from "react";
import { useMapEvent, ZoomControl } from "react-leaflet";
import useContextedFunction from "../../../../hooks/useContextedFunction";

interface EventHandlerContext extends Props {}

function eventHandler(this: EventHandlerContext, event: LeafletEvent) {
	this.onChangeZoom?.call(null, event.target.getZoom());
}

export interface Props {
	showButtons?: boolean;

	onChangeZoom?: (zoom: number) => void;
}

export const ZoomController: FC<Props> = ({ showButtons, onChangeZoom }) => {
	const [contextedEventHandler, setEventHandlerContext] =
		useContextedFunction(eventHandler, { onChangeZoom });

	setEventHandlerContext({ onChangeZoom });

	useMapEvent("zoom", contextedEventHandler);

	return showButtons ? <ZoomControl position="bottomright" /> : null;
};
