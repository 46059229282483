import React, { useMemo } from "react";
import { DataType } from "csstype";
import { RegisteredIcon } from "@ioc:uikit/icon";
import Size from "../../types/Size";
import SizeLiteral from "../../types/SizeLiteral";
import { unifySize } from "../../utils/types";
import LocalProvider from "./components/Provider";
import Root from "./components/Root";
import StyleProps from "../../types/StyleProps";
import useCachedValue from "../../hooks/useCachedValue";

function Icon({
	style,
	className,

	id,

	width,
	height,
	size,

	colors,
}: Icon.Props) {
	const internalColors = useCachedValue(colors ?? []);

	const unifiedSize = useMemo(
		() => unifySize(size ?? { width, height }),
		[size],
	);
	const viewBox = useMemo(
		() => LocalProvider.get(id)?.viewBox.join(" ") ?? "",
		[id],
	);

	return LocalProvider.has(id) ? (
		<Root
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			viewBox={viewBox}
			width={unifiedSize.width}
			height={unifiedSize.height}
			colors={internalColors}
			style={style}
			className={className}
			tabIndex={-1}
		>
			<use xlinkHref={`#${id}`} />
		</Root>
	) : null;
}

namespace Icon {
	export const Provider = LocalProvider;

	export interface Props extends Partial<SizeLiteral>, StyleProps {
		id: RegisteredIcon;

		colors?: DataType.Color[];

		size: Size;
	}

	export const { register } = LocalProvider;
}

declare namespace Icon.Provider {
	type Data = LocalProvider.Data;
	type Props = LocalProvider.Props;
}

export default Icon;
