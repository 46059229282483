import Color from "color";
import styled from "styled-components";

export interface Props {
  color: string
}

export const Root = styled.div<Props>`
  width: 54px;
  height: 54px;

  border-radius: 50%;

  background-color: ${({ color }) => Color(color).alpha(0.1).hexa()};

  & > * {
    width: 100%;
    height: 100%;
  }
`;
