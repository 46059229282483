import { useState } from "react";

export default function useNextTick<T extends () => unknown>() {
  const [callbacks, setCallbacks] = useState<T[]>([]);

  const callbacksLength = callbacks.length;

  for (let i = 0; i < callbacksLength; i += 1) callbacks.shift()?.call(null);

  if (callbacksLength !== 0) setCallbacks([]);

  return (callback: T) => {
    setCallbacks([...callbacks, callback]);
  };
}
