import React, { FC } from "react";
import styled from "styled-components";
import theme from "../../styles/theme";
import { intersperse } from "../../utils/intersperse";

const StyledTwoLineList = styled.div`
	color: ${theme.colors.primary};
	font-family: ${theme.font.n};
	font-style: normal;
	display: flex;
	flex-direction: column;
	gap: 16px;

	.item {
		display: flex;
		flex-direction: column;
		gap: 4px;

		.header {
			font-weight: 700;
			font-size: 16px;
			line-height: 19px;
		}

		.footer {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 10px;
			color: ${theme.colors.secondary};
			font-weight: 400;
			font-size: 13px;
			line-height: 16px;
		}
	}
`;

const StyledDot = styled.div`
	width: 5px;
	height: 5px;
	flex-shrink: 0;
	border-radius: 50%;
	background: currentColor;
	opacity: 0.36;
`;

export interface Props {
	items?: {
		header: string;
		footer: string | string[];
	}[];
}

export const TwoLineList: FC<Props> = ({ items = [] }) => (
	<StyledTwoLineList>
		{items.map(({ header, footer }, i) => (
			<div className="item" key={i}>
				<div className="header">{header}</div>
				<div className="footer">
					{typeof footer === "string"
						? footer
						: intersperse(footer, <StyledDot />)}
				</div>
			</div>
		))}
	</StyledTwoLineList>
);
