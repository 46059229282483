import { useRef } from "react";
import { compare } from "../utils/conditions";

export default function useChanged<ValueType, ResultType>(
	callback: (
		prevValue: ValueType | undefined,
		prevResult: ResultType | undefined,
	) => ResultType,
	value: ValueType,
	deep = false,
): ResultType | undefined {
	const prevValueRef = useRef<ValueType | undefined>(undefined);
	const prevResultRef = useRef<ResultType | undefined>(undefined);

	if (compare(value, prevValueRef.current, deep))
		return prevResultRef.current;

	prevResultRef.current = callback(
		prevValueRef.current,
		prevResultRef.current,
	);
	prevValueRef.current = value;

	return prevResultRef.current;
}
