import { ReactElement, cloneElement, forwardRef, useMemo, useContext, useRef, useEffect } from 'react';
import useCursorPosition from '../../hooks/useCursorPosition';
import useRender from '../../hooks/useRender';
import { inRect } from '../../utils/geometry';
import { mergeValues } from '../../utils/object';
import { createStackableContext, setRefValue } from '../../utils/react';
import Grid from '../Grid';
import { ItemContext } from '../Grid/components/Item';

export interface Props {
  children: ReactElement
}

export const GridGroupAreaContext = createStackableContext<Grid.GroupAreaContextOptions | null>(null, mergeValues)

export const GridGroupArea = forwardRef<HTMLElement, Props>(({
  children,
}, ref) => {
  const render = useRender()

  const item = useContext(ItemContext)
  const events = useContext(GridGroupAreaContext)

  const enteredRef = useRef<boolean>(false)

  const elementRef = useRef<HTMLElement | null>(null)
  const element = elementRef.current

  const cursorPosition = useCursorPosition()

  if (events && element && cursorPosition.x && cursorPosition.y) {
    const elementRect = element.getBoundingClientRect()

    const elementInRect = inRect(
      cursorPosition.x,
      cursorPosition.y,
      elementRect.x,
      elementRect.y,
      elementRect.width,
      elementRect.height,
    )

    if (events.onGroupAreaEnter && elementInRect && !enteredRef.current) {
      enteredRef.current = true

      const onGroupAreaEnterEvents = Array.isArray(events.onGroupAreaEnter) ? events.onGroupAreaEnter : [events.onGroupAreaEnter]

      onGroupAreaEnterEvents.forEach((onGroupAreaEnter) => {
        onGroupAreaEnter()
      })
    } else if (events.onGroupAreaLeave && !elementInRect && enteredRef.current && !item?.drag) {
      enteredRef.current = false

      const onGroupAreaLeaveEvents = Array.isArray(events.onGroupAreaLeave) ? events.onGroupAreaLeave : [events.onGroupAreaLeave]

      onGroupAreaLeaveEvents.forEach((onGroupAreaLeave) => {
        onGroupAreaLeave()
      })
    }
  }

  // TODO: Make it via contexted function
  useEffect(() => () => {
    if (!events || !events.onGroupAreaLeave || !enteredRef.current)
      return

    const onGroupAreaLeaveEvents = Array.isArray(events.onGroupAreaLeave) ? events.onGroupAreaLeave : [events.onGroupAreaLeave]

    onGroupAreaLeaveEvents.forEach((onGroupAreaLeave) => {
      onGroupAreaLeave()
    })
  }, [])

  return useMemo(
    () => cloneElement(children, {
      ref: (element: HTMLElement) => {
        setRefValue(ref, element)
        setRefValue(elementRef, element)

        render()
      }
    }),
    [children]
  )
})
