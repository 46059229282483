import React, { FC } from 'react';
import styled from "styled-components";
import theme from "../../styles/theme";
import { SVGgeneral } from "../SvgComponents/index"

export const StyledButton = styled.button`
    padding: 0;
    border: none;
	border-radius: 5px;
	border: 1px solid rgba(33, 51, 63, 0.1) !important;

    display: block;
    justify-content: center;
    align-items: center;
	gap: 10px;
    cursor: pointer;

    background-color: ${theme.colors.button_secondary};
    position: relative;
    box-sizing: inherit;

    .borderBlock {
        position: absolute;
        top: 0px;
        right: 45px;
        background-color: white;
        border-radius: 3px 0px 0px 3px;
        transform: rotate(-180deg);
        width: 3px; 
        height: 40px;
        z-index: 5;
    }

    &:hover {
        .borderBlock {
            background-color: ${theme.colors.accent};
        }
    }

	&.primary {
        height: 32px;
        fill: #21333FCC;
        width: 32px;
		color: #21333F1A;
		background-color: ${theme.colors.button_secondary};

        &:hover {
            fill: ${theme.colors.accent}
		}
	}
    
    &.primary-active {
       height: 32px;
        fill: #21333FCC;
        width: 32px;
		color: #21333F1A;
		background-color: ${theme.colors.button_secondary};
        fill: ${theme.colors.accent}
    }

    &.disabled {
        height: 30px;
        width: 30px;
        color: #9BA3A866;
		background-color: white;
        border: 1px solid;
        border-color: #9BA3A866;
        fill: #9BA3A866;
    }

    &.primary-circle {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        fill: #21333FCC;
        &:hover {
            fill: ${theme.colors.accent}
		}
    }

    &.disabled-circle {
        height: 38px;
        width: 38px;
        color: #9BA3A866;
		background-color: white;
        border: 1px solid;
        border-radius: 50%;
        border-color: #9BA3A866;
        fill: #9BA3A866;
    }

    
`
const ItemBadge = styled.div`
    position: absolute;
    top: -4px;
    right: -3px;
    font-size: .7em;
    background-color: ${theme.colors.negative};
    border: 2px solid;
    color: white;
    width: 16px; 
    height: 16px;
    text-align: center;
    line-height: 12px;
    border-radius: 5px;
    z-index: 5;

    .content {
        font-size: 11px;
    }
`

export type Style = "primary"

export interface Props {
    id?: string,
    style?: Style,
    borderBlock?: boolean,
    numberBudge?: boolean,
    disabled?: boolean
    circle?: boolean
    number?: number,
    title?: string,
    active?: boolean,
    icon?: any,
    onClick?: () => void
}



export const Budge: FC<Props> = ({
    id,
    title = "",
    style = "primary",
    active = false,
    borderBlock = false,
    numberBudge = false,
    disabled = false,
    circle = true,
    number = 0,
    icon,
    onClick,
}) => {
    const classes = (disabled ? 'disabled' : style) + (circle ? "-circle" : '') + (active ? "-active" : "");
    return (
        <StyledButton
            type="button"
            title={title}
            className={classes}
            onClick={() => {
                if (!disabled && onClick)
                    onClick()
            }}
        >
            <SVGgeneral data={icon} id={id} className={classes} />
            {numberBudge && <ItemBadge>
                <div className={'content'}>{number}</div>
            </ItemBadge>}
            {borderBlock && <div className={'borderBlock'}></div>}
        </StyledButton>
    )
}