import styled from "styled-components"
import { InputifiedControlProps } from "../../../../utils/react"
import theme from "../../../../styles/theme"

const Root = styled.textarea<Root.Props>`
    all: unset;

    width: 100%;
    height: 100%;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: ${theme.colors.primary};
    caret-color: ${theme.colors.accent};

    ${theme.scrollbar}

    &::placeholder {
        color: ${theme.colors.disabled_text};
    }

    ${({ hovered }) => hovered ? "&," : ""}
    &:hover,
    &:has(:hover),
    ${({ focused }) => focused ? "&," : ""}
    &:focus,
    &:has(:focus) {
        &::placeholder {
            ${({ error, disabled }) => error || disabled ? "" : `color: ${theme.colors.text_hovered_placeholder};`}
        }
    }
`

declare namespace Root {
    type Props = Required<InputifiedControlProps>
}

export default Root
