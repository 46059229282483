import React, {
	MouseEvent,
	RefAttributes,
	forwardRef,
	useCallback,
} from "react";
import { inputify } from "../../utils/react";
import Row from "../Row";
import { StyledLabel } from "../StyledLabel";
import Root from "./components/Root";
import Icon from "../Icon";
import theme from "../../styles/theme";

const CheckBox = inputify<
	CheckBox.Props & RefAttributes<HTMLDivElement>,
	boolean
>(
	forwardRef(
		(
			{
				value = false,
				disabled,
				onChange,
				onEnter,
				onLeave,
				onFocus,
				onBlur,
				label,
				labelPosition = "right",
			},
			ref,
		) => {
			const onClick = useCallback(
				(event: MouseEvent<HTMLDivElement>) => {
					event.preventDefault();
					event.stopPropagation();

					disabled ? undefined : onChange(!value);
				},
				[onChange, value, disabled],
			);

			const onKeyDown = useCallback(
				(event: React.KeyboardEvent) => {
					if (event.code === "Space") {
						if (disabled) return;

						event.preventDefault();
						event.stopPropagation();

						onChange(!value);
					}
				},
				[disabled, onChange, value],
			);

			const checkbox = (
				<Root
					ref={ref}
					active={value}
					disabled={disabled}
					tabIndex={disabled ? undefined : 0}
					onKeyDown={onKeyDown}
					onMouseEnter={onEnter}
					onMouseLeave={onLeave}
					onFocus={onFocus}
					onBlur={onBlur}
					onClick={onClick}
				>
					{value ? (
						<Icon
							id="check-mark"
							size={16}
							colors={[theme.colors.white]}
						/>
					) : (
						<></>
					)}
				</Root>
			);

			const styledLabel = label ? (
				<StyledLabel active={value}>{label}</StyledLabel>
			) : null;

			return (
				<Row
					style={{
						width: "max-content",
					}}
					gaps="8px*"
					justify="center"
					align="center"
				>
					{labelPosition === "right" ? (
						<>
							{checkbox}
							{styledLabel}
						</>
					) : (
						<>
							{styledLabel}
							{checkbox}
						</>
					)}
				</Row>
			);
		},
	),
);

declare namespace CheckBox {
	export interface Props {
		label?: string;
		labelPosition?: "left" | "right";
	}
}

export default CheckBox;
